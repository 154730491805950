import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import get from 'lodash/get';
import unset from 'lodash/unset';
import includes from 'lodash/includes';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Validator from 'libs/Validator';
import Form from 'components/Form';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import Button from 'components/Form/Button';
import FormContainerAbstract from 'components/FormContainerAbstract';
import App from 'modules/App';
import messages from '../../../messages';
import * as constants from '../../../constants';
import validatorRules from './validatorRules.json';
import styles from './AddBLSRegionForm.pcss';


class AddBLSRegionForm extends FormContainerAbstract {

  static propTypes = {
    ...FormContainerAbstract.propTypes,
    onSubmit: PropTypes.func,

    // Explicit props
    existingRegions: PropTypes.arrayOf(PropTypes.string),
    // Explicit actions
    onClose        : PropTypes.func,
    // Implicit props
    isInProgress   : PropTypes.bool,

  };


  onValidate(rules) {
    const values = get(this.props.formValues, 'values', {});
    const { errors } = Validator.run(values, rules);

    if (errors && !includes(this.props.existingRegions, values.name)) {
      unset(errors, 'name.alreadyExist');
      if (errors.name && !Object.keys(errors.name).length) {
        unset(errors, 'name');
      }
    }
    return {
      validatedValues: values,
      errors         : !errors || Object.keys(errors).length ? errors : null,
    };
  }

  constructor(props) {
    super(props);
    this.validatorRules = { ...validatorRules };
  }


  renderHeader() {
    return (
      <div>
        <p className="mb-6"><FormattedMessage {...messages.infos.addNewBLSRegion} /></p>
      </div>
    );
  }


  renderActions() {
    return (
      <div className="row">
        <div className="col-6">
          <Button
            type="reset"
            styleModifier="primary"
            labelMessage={App.messages.buttons.cancel}
            className="btn--block"
            onClick={this.props.onClose}
            isInProgress={this.props.isInProgress}
          />
        </div>
        <div className="col-6">
          <Button
            type="submit"
            styleModifier="primary"
            labelMessage={messages.buttons.addBLSRegion}
            className="btn--block btn--filled"
            isDisabled={this.isDisabled}
            isInProgress={this.props.isInProgress}
          />
        </div>
      </div>
    );
  }


  renderForm() {
    return (
      <Form
        className={styles.addBLSRegionForm}
        onSubmit={() => this.onSubmit()}
      >
        <div className="row">
          <FormGroup
            id="name"
            className="col-12"
            labelMessage={messages.labels.regionName}
            formValues={this.props.formValues}
          >
            <Input
              placeholder={messages.placeholders.regionName}
              onChange={(input) => this.onSetValue(input)}
            />
          </FormGroup>
        </div>
        { this.renderActions() }
      </Form>
    );
  }


  render() {
    return (
      <div>
        {this.renderHeader()}
        {this.renderForm()}
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  formValues: App.selectors.formSelector(constants.ADD_BLS_REGION_FORM)(state),

});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.ADD_BLS_REGION_FORM;
  return {
    onSetFormValue  : (input) => dispatch(App.actions.setFormValue({ formName, input })),
    onSetFormValues : (values) => dispatch(App.actions.setFormValues({ formName, values })),
    onFormErrors    : (errors) => dispatch(App.actions.setFormErrors({ formName, errors })),
    onFormProcessing: () => dispatch(App.actions.startFormProcessing(formName)),
    onClearForm     : () => dispatch(App.actions.clearForm(formName)),
  };
};


const ConnectedAddBLSRegionForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddBLSRegionForm);


export default withStyles(styles)(ConnectedAddBLSRegionForm);
