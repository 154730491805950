import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import Table from 'components/Table';
import messages from '../../messages';
import styles from './CommitsTable.pcss';


class CommitsTable extends React.PureComponent {

  static propTypes = {
    // Explicit props
    // Implicit props
    publishCommits                      : PropTypes.any,
    isFetchPublishVersionCommitsProgress: PropTypes.bool,
    // Implicit actions
  };


  constructor(props) {
    super(props);
    this.state = {
      page: 0,
    };
  }


  get schema() {
    return (
      [
        {
          key         : 'commitId',
          labelMessage: messages.labels.id,
          renderer    : (snapShot) => <b>{snapShot.commitId}</b>,
        },
        {
          key         : 'title',
          labelMessage: messages.labels.title,
          renderer    : (snapShot) => <p>{snapShot.title}</p>,
        },
        {
          key         : 'createdBy',
          labelMessage: messages.labels.userName,
          renderer    : (snapShot) => <p>{snapShot.createdBy}</p>,
        },
        {
          key         : 'commitCreated',
          labelMessage: messages.labels.dateTime,
          renderer    : (snapShot) => <p>{snapShot.commitCreated}</p>,
        },
      ]
    );
  }


  renderContent() {
    return (
      <div className={styles.commitsTable}>
        <Table
          idKey="commitId"
          schema={this.schema}
          entities={this.props.publishCommits || []}
          page={this.state.page}
          perPage={10}
          isPerPageOff
        />
      </div>

    );
  }


  render() {
    if (this.props.isFetchPublishVersionCommitsProgress) return null;
    return (
      <div>
        {this.renderContent()}
      </div>
    );
  }

}

export default withStyles(styles)(CommitsTable);
