import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import LoaderIcon from 'svg/loader.svg';


class Loader extends React.PureComponent {

    static propTypes = {
      isInProgress: PropTypes.bool.isRequired,
    };

    constructor(props) {
      super(props);
      this.isInitInProgress = props.isInProgress;
    }

    render() {

      if (!this.props.isInProgress) {
        return null;
      }
      return (
        <motion.div
          initial={{ x: this.isInitInProgress ? 0 : '100%' }}
          animate={{ x: 0 }}
          exit={{ x: '100%' }}
          transition={{ ease: 'easeOut', duration: 0.15 }}
          className="loader__Wrapper"
        >
          <LoaderIcon className="rotatingLoader" />
        </motion.div>
      );
    }

}


export default Loader;
