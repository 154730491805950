exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _33sxv{5%{opacity:1}50%{opacity:.35}95%{opacity:1}}@keyframes _33sxv{5%{opacity:1}50%{opacity:.35}95%{opacity:1}}._3rKWH{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;padding:0;gap:8px;height:24px;-ms-flex:none;flex:none;-ms-flex-order:4;order:4;-ms-flex-positive:1;flex-grow:1}._3268T{width:8px;height:8px;border-radius:16px}._2phRZ{background:#127e68}._3IUWu{background:#da1818}", ""]);

// exports
exports.locals = {
	"flag": "_3rKWH",
	"flag__image": "_3268T",
	"flag__image--success": "_2phRZ",
	"flag__image--error": "_3IUWu",
	"fading": "_33sxv"
};