import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import range from 'lodash/range';
import Chevron from 'svg/chevron-right.svg';
import styles from './Pages.pcss';
import Page from './Page';


class Pages extends React.PureComponent {

  static propTypes = {
    // Explicit props
    totalPages: PropTypes.number,
    activePage: PropTypes.number.isRequired,
    slotsNo   : PropTypes.number,
    // Explicit actions
    onChange  : PropTypes.func,
  };


  static defaultProps = {
    slotsNo: 2,
  };


  render() {
    const { activePage, totalPages, slotsNo, onChange } = this.props;
    let start = activePage - Math.ceil(slotsNo / 2) < 0 ? 0 : activePage - Math.ceil(slotsNo / 2);
    let end = activePage + Math.ceil(slotsNo / 2) > totalPages ? totalPages : activePage + Math.ceil(slotsNo / 2);
    if (start === 0) {
      const delta = Math.abs(activePage - Math.ceil(slotsNo / 2));
      end = end + delta > totalPages ? totalPages : end + delta;
    }
    if (end === totalPages) {
      const delta = Math.abs(totalPages - activePage - Math.ceil(slotsNo / 2));
      start = start - delta < 0 ? 0 : start - delta;
    }

    return (
      <div className={styles.pages}>
        <div className="wrapper">

          <button
            type="button"
            className="movePage movePage--previous"
            onClick={() => onChange(activePage - 1)}
            disabled={!(totalPages > 1 && activePage > 0)}
          >
            <Chevron />
          </button>
          <div className={styles.inner}>
            {
            range(start, end).map((page) => (
              <Page
                key={page}
                active={page === activePage}
                onClick={() => onChange(page)}
              >
                {page + 1}
              </Page>
            ))
          }
          </div>
          <button
            type="button"
            className="movePage movePage--next"
            onClick={() => onChange(activePage + 1)}
            disabled={!(totalPages > 1 && activePage < totalPages - 1)}
          >
            <Chevron />
          </button>
        </div>
      </div>
    );
  }

}

export default withStyles(styles)(Pages);
