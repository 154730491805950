exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1U7EQ{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;gap:32px}.vFtAJ{margin-bottom:3.2rem;padding-bottom:3.2rem;border-bottom:1px solid #e0e8f2}.VDrfG{display:-ms-flexbox;display:flex;gap:24px;width:50%;-ms-flex-wrap:wrap;flex-wrap:wrap}._20i8S{cursor:pointer;display:block;width:20px;width:2rem;height:20px;height:2rem}._20i8S>g{fill:#da1818}._2Y--r{display:block;width:24px;height:24px;padding-right:8px}.table__wrapper{padding:0}.table__cell{padding:1.6rem 0;margin-right:3rem;width:365px;width:36.5rem}tbody{-webkit-column-count:2;-moz-column-count:2;column-count:2;-webkit-column-gap:64px;-moz-column-gap:64px;column-gap:64px;display:block}tbody tr{display:-ms-flexbox;display:flex}tbody .table__row:last-child{border-bottom:1px solid #e0e8f2}.table__cell--englishName{display:-ms-flexbox;display:flex}.table__cell--englishName,.table__cell--region{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.table__cell--region{-ms-flex-pack:end;justify-content:end;margin-right:0;margin-left:auto}._3M1WN{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}._3M1WN>button{background:rgba(0,0,0,0);margin-left:-1.6rem;display:inline-block;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}._3M1WN>div{display:-ms-flexbox;display:flex;gap:144px;gap:14.4rem}form{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:start;justify-content:flex-start;-ms-flex-positive:100;flex-grow:100}form>h5:first-of-type{margin-top:0}form div.row{-ms-flex-align:start;align-items:flex-start}form div.row div.form-group{margin:0}._2Qxte{margin-bottom:8px}._2Qxte,._2Qxte div{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between}._2Qxte div{width:calc(50% - 32px)}._1lkfO{width:320px}", ""]);

// exports
exports.locals = {
	"ConfigureBLSRegions": "_1U7EQ",
	"regions": "vFtAJ",
	"tags": "VDrfG",
	"deleteIcon": "_20i8S",
	"addButton__icon": "_2Y--r",
	"ConfigureBLSRegions__header": "_3M1WN",
	"table__headers": "_2Qxte",
	"assignCheckedCountriesSelect": "_1lkfO"
};