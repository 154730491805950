import React from 'react';
import { connect } from 'react-redux';
import PropTypes, { bool } from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import { AnimatePresence, motion } from 'framer-motion';
import debounce from 'lodash/debounce';
import filter from 'lodash/filter';
import includes from 'lodash/includes';
import map from 'lodash/map';
import unionBy from 'lodash/unionBy';
import isObject from 'lodash/isObject';
import isFunction from 'lodash/isFunction';
import Alert from 'components/Alert';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import * as shapes from '../../shapes';
import { transformSystemAlerts } from './helpers';


class AlertsBus extends React.PureComponent {

  static getDerivedStateFromProps(props, state) {
    const {
      alerts, alertsTimeThreshold,
      systemAlerts, systemAlertsSettings,
      dismissedAlerts,
      openModalId, route, activeClinicMembership, localizationResources,
      isGlobal,
    } = props;

    if (state.route !== route && state.route) {
      let filteredAlerts;
      if (state.activeClinicMembership !== activeClinicMembership) {
        filteredAlerts = [];
      } else {
        filteredAlerts = filter(state.alerts, (alert) => alert.attributes && alert.attributes.isPinned);
      }
      return {
        alerts: filteredAlerts,
        route,
        activeClinicMembership,
      };
    }

    const getAlertName = (alert) => (isObject(alert.message)
      ? `${alert.message.id}_${JSON.stringify(alert.messageValues)}`
      : alert.message);

    if (alerts.length || alertsTimeThreshold > state.alertsTimeThreshold) {
      const localAlerts = filter(alerts, (alert) => {
        const alertName = getAlertName(alert);
        return !includes(dismissedAlerts, alertName)
          && (
            !openModalId
            || (openModalId && ((isGlobal && alert.isGlobal) || (!isGlobal && !alert.isGlobal)))
          );
      });
      let unionAlerts = unionBy([...state.alerts].reverse(), localAlerts, (alert) => getAlertName(alert));
      unionAlerts = filter(unionAlerts, (alert) => alert.timestamp >= alertsTimeThreshold).reverse();
      return {
        alerts: unionAlerts,
        alertsTimeThreshold,
      };
    }

    if (isGlobal && systemAlerts.length) {
      let unionSystemAlerts = [];
      unionSystemAlerts = unionBy([...state.systemAlerts].reverse(), systemAlerts, (systemAlert) => (
        `${systemAlert.alertId}_${systemAlert.createTimestamp}`
      ));

      if (unionSystemAlerts.length <= state.systemAlerts.length) {
        return null;
      }

      unionSystemAlerts = transformSystemAlerts(unionSystemAlerts, systemAlertsSettings, localizationResources)
        .filter((systemAlert) => systemAlert && systemAlert.message).reverse();

      return {
        systemAlerts: unionSystemAlerts,
      };
    }
    return null;
  }

  static propTypes = {
    // Explicit props
    className             : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isGlobal              : PropTypes.bool,
    // Implicit props
    alerts                : PropTypes.arrayOf(PropTypes.object),
    alertsTimeThreshold   : PropTypes.number,
    shouldDismissAllAlerts: bool,
    systemAlerts          : PropTypes.arrayOf(PropTypes.object),
    systemAlertsSettings  : PropTypes.arrayOf(PropTypes.object),
    route                 : shapes.route,
    localizationResources : PropTypes.object,
    // Implicit actions
    onDisplayAlerts       : PropTypes.func,
    onAllAlertsDismissed  : PropTypes.func,
    onDismissAlert        : PropTypes.func,
    onDismissSystemAlert  : PropTypes.func,
    dispatch              : PropTypes.func,
  };


  static defaultProps = {
    isGlobal: false,
  };


  constructor(props) {
    super(props);
    let systemAlerts = [];
    if (props.isGlobal) {
      systemAlerts = transformSystemAlerts(props.systemAlerts, props.systemAlertsSettings, props.localizationResources)
        .filter((systemAlert) => systemAlert && systemAlert.message).reverse();
    }

    this.state = {
      alerts                : [...props.alerts].reverse(),
      systemAlerts,
      alertsTimeThreshold   : +moment.utc().locale('en').format('X'),
      activeClinicMembership: null,
      route                 : props.route,
      isCollapsed           : false,
    };
    this.onDisplayAlerts = debounce(() => this.onDisplay(), 150);
  }


  componentDidMount() {
    this.onDisplayAlerts();
  }


  componentDidUpdate(prevProps, prevState) {
    if (process.env.BROWSER) {
      if (
        (this.state.alerts !== prevState.alerts && this.state.alerts.length)
          || (this.state.systemAlerts !== prevState.systemAlerts && this.state.systemAlerts.length)
      ) {
        this.onDisplayAlerts();
        window.scrollTo(0, 0);
      }
      if (!prevProps.shouldDismissAllAlerts && this.props.shouldDismissAllAlerts) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({
          alerts      : [],
          systemAlerts: [],
        });
        this.props.onAllAlertsDismissed();
      }
    }
  }


  onDisplay() {
    this.props.onDisplayAlerts();
  }


  onClose(alert) {
    const { id, alertConfigurationId } = alert;
    if (alertConfigurationId) {
      this.setState((state) => ({ systemAlerts: state.systemAlerts.filter((a) => a.id !== id) }));
      this.props.onDismissSystemAlert(alert);
    } else {
      this.setState((prevState) => ({ alerts: prevState.alerts.filter((a) => a.id !== id) }));
      if (alert.attributes && alert.attributes.isPinned) {
        this.props.onDismissAlert(alert);
      }
    }
  }


  onToggleCollapse() {
    this.setState((state) => ({ isCollapsed: !state.isCollapsed }));
  }


  onAction(action) {
    if (isFunction(action)) {
      action();
    } else if (isObject(action)) {
      this.props.dispatch(action);
    }
  }


  renderAlerts() {
    const { alerts, systemAlerts, isCollapsed } = this.state;
    const allAlerts = [...alerts, ...systemAlerts];
    return (
      <AnimatePresence>
        {
          map(allAlerts, (alert, idx) => (
            <motion.div
              key={`alert-${isObject(alert.message) ? alert.message.id : alert.message}-${alert.id}`}
              initial={{ opacity: 0, marginTop: isCollapsed ? '-55px' : '-55px' }}
              animate={{ opacity: 1, marginTop: idx && isCollapsed ? '-52px' : 0 }}
              exit={{ opacity: 0, marginTop: '-55px' }}
              transition={{ ease: 'linear', duration: 0.3 }}
              style={{ position: 'relative', zIndex: allAlerts.length - idx }}
            >
              <Alert
                idx={idx}
                isCollapsed={isCollapsed}
                {...alert}
                actions={alert.actions.map((action) => ({
                  ...action,
                  action: () => this.onAction(action.action),
                }))}
                onClose={() => this.onClose(alert)}
                onToggleCollapse={() => this.onToggleCollapse()}
              />
            </motion.div>
          )
          )
        }
      </AnimatePresence>
    );
  }


  render() {
    const { isCollapsed } = this.state;
    return (
      <div className={cn('alertsBus', this.props.className, { 'alertsBus--collapsed': isCollapsed })}>
        { this.renderAlerts() }
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  alerts                : selectors.alerts(state),
  alertsTimeThreshold   : selectors.alertsTimeThreshold(state),
  systemAlerts          : selectors.systemAlerts(state),
  systemAlertsSettings  : selectors.systemAlertsSettings(state),
  dismissedAlerts       : selectors.dismissedAlerts(state),
  shouldDismissAllAlerts: selectors.shouldDismissAllAlerts(state),
  openModalId           : selectors.modal(state),
  route                 : selectors.route(state),
  localizationResources : selectors.localizationResources(state),
});

const mapDispatchToProps = (dispatch) => ({
  onDisplayAlerts     : () => dispatch(actions.displayAlerts()),
  onDismissAlert      : (alert) => dispatch(actions.dismissAlert(alert)),
  onAllAlertsDismissed: () => dispatch(actions.dismissAllAlertsSuccess()),
  onDismissSystemAlert: (alert) => dispatch(actions.dismissSystemAlert(alert)),
  dispatch,
});


const ConnectedAlertsBus = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AlertsBus);


export default ConnectedAlertsBus;
