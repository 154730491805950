import UniversalRouter from 'universal-router';
import find from 'lodash/find';
import Account from 'modules/Account';
import routes from './routes';


export default new UniversalRouter(routes, {
  resolveRoute(context, params) {
    if (typeof context.route.load === 'function') {
      if (process.env.BROWSER) {
        const { authScopes } = context.route;
        if (authScopes) {
          const state = context.store.getState();
          const accountScope = Account.selectors.scope(state);
          if (!find(authScopes, (authScope) => authScope === accountScope)) {
            return { redirect: '/forbidden' };
          }
        }
      }
      return context.route
        .load()
        .then((action) => action.default(context, params));
    }
    if (typeof context.route.action === 'function') {
      return context.route.action(context, params);
    }
    return undefined;
  },
});
