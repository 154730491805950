import split from 'lodash/split';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';


export function getIdentifierFormKeyByName(formValues, identifierPrefix) {
  const [identifierType, identifierEntryName] = split(identifierPrefix, '.');
  const identifierEntry = get(formValues.values, identifierType);
  const index = findIndex(identifierEntry, (identifier) => identifier.name === identifierEntryName);
  return `${identifierType}[${index}]`;
}
