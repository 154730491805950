exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Ei8p{display:-ms-flexbox;display:flex}._2Vqo4{padding-left:16px}._2zRvC{line-height:36px}._2VzAw{line-height:21px;margin-top:16px;color:#939393}._8R-w{height:36px}._8R-w path{vector-effect:none!important}", ""]);

// exports
exports.locals = {
	"wrapper": "_2Ei8p",
	"message": "_2Vqo4",
	"messageHeader": "_2zRvC",
	"messageDetails": "_2VzAw",
	"headerIcon": "_8R-w"
};