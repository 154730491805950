exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3CwEb{max-height:calc(72vh - 130px);border:1px solid #e0e8f2;border-radius:12px}", ""]);

// exports
exports.locals = {
	"codeBlockDisplay": "_3CwEb"
};