import { put, takeLatest, call, select, delay } from 'redux-saga/effects';
import { set } from 'lodash';
import ApiService from 'services/ApiService';
import App from 'modules/App';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';
import messages from './messages';


function* fetchPublishVersionsPage({ payload }) {
  try {
    let { page, perPage } = payload;
    if (page === undefined) {
      page = 0;
    }
    if (perPage === undefined) {
      perPage = 10;
    }
    const requestUrl = `/api/PublishVersion?start=${page * perPage}&limit=${perPage}`;
    const response = yield call(ApiService.originalRequest, requestUrl);
    const { records, totalRecords } = response;
    yield put(actions.fetchPublishVersionsPageSuccess({ totalRecords, records }));
  } catch (err) {
    yield put(actions.fetchPublishVersionsPageError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//------------------------------------------------------------------------------------------------------------------

function* fetchPublishVersionCommits({ payload }) {
  try {
    const { publishVersion } = payload;
    const requestUrl = `/api/PublishVersion/${publishVersion}/Commits`;
    const response = yield call(ApiService.originalRequest, requestUrl);
    yield put(actions.fetchPublishVersionCommitsSuccess(response));
  } catch (err) {
    yield put(actions.fetchPublishVersionCommitsError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//------------------------------------------------------------------------------------------------------------------

function* downloadPublishVersionDiffs({ payload }) {
  try {
    const { publishVersion, fileName } = payload;
    const requestUrl = `/api/PublishVersion/${publishVersion}/Diffs`;

    const options = set({}, 'headers.Content-Disposition', `attachment; filename="${fileName}"`);
    const response = yield call(ApiService.originalRequest, requestUrl, options);
    yield put(actions.fetchPublishVersionDiffsSuccess(response));
  } catch (err) {
    yield put(actions.fetchPublishVersionDiffsError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//------------------------------------------------------------------------------------------------------------------

function* fetchPublishVersionDiffs({ payload }) {
  try {
    const { publishVersion } = payload;
    const requestUrl = `/api/PublishVersion/${publishVersion}/Diffs`;

    const response = yield call(ApiService.originalRequest, requestUrl);
    yield put(actions.fetchPublishVersionDiffsSuccess(response));
  } catch (err) {
    yield put(actions.fetchPublishVersionDiffsError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//------------------------------------------------------------------------------------------------------------------

function* validatePublishVersion() {
  try {
    const requestUrl = '/api/PublishVersion/Validate';
    const response = yield call(ApiService.originalRequest, requestUrl, {
      method: 'POST',
    });
    yield put(actions.validatePublishVersionSuccess(response));
  } catch (err) {
    yield put(actions.validatePublishVersionError(err));
    yield call(App.dispatchError, err, messages);
  }
}


//------------------------------------------------------------------------------------------------------------------

function* addNewPublishVersion() {
  try {
    const requestUrl = '/api/PublishVersion/AddNewVersion';
    const response = yield call(ApiService.originalRequest, requestUrl, {
      method: 'POST',
    });
    yield put(actions.addNewPublishVersionSuccess(response));
  } catch (err) {
    yield put(actions.addNewPublishVersionError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//------------------------------------------------------------------------------------------------------------------

function* initializePublishVersion() {
  try {
    const requestUrl = '/api/PublishVersion/Initialize';
    const response = yield call(ApiService.originalRequest, requestUrl, {
      method: 'POST',
    });
    yield put(actions.initializePublishVersionSuccess(response));
  } catch (err) {
    yield put(actions.initializePublishVersionError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//------------------------------------------------------------------------------------------------------------------

function* fetchLastPublish() {
  try {
    const requestUrl = '/api/PublishVersion/LastPublish';
    const response = yield call(ApiService.originalRequest, requestUrl);
    yield put(actions.fetchLastPublishSuccess(response));
  } catch (err) {
    yield put(actions.fetchLastPublishError(err));
    yield call(App.dispatchError, err, messages);
  }
}

function* updatePublishSnapshotsReceived() {
  // handled in SnapshotsTable component due to update page number requirement
  yield put(App.actions.updatePublishSnapshotsSuccess());
}


function* signalRReconnected() {
  const { name } = yield select(App.selectors.route);
  if (name !== 'publishSnapshot') return;

  yield put(App.actions.signalRSend(constants.PUBLISH_SNAPSHOT_SEND.JoinPublishSnapshotPage, null));
  const openModalId = yield select(App.selectors.modal);
  if (openModalId !== constants.PUBLISH_MODAL) return;

  yield put(App.actions.signalRInvoke(
    constants.PUBLISH_SNAPSHOT_INVOKE.IsPublishInProgress, null,
    {
      init   : actions.setPublishingProgress,
      success: actions.setPublishingProgressSuccess,
      error  : actions.setPublishingProgressError,
    }));
  yield delay(400);
  const { isInProgress } = yield select(selectors.publishingStatus);
  if (isInProgress) return;

  yield put(App.actions.signalRSend(constants.PUBLISH_SNAPSHOT_SEND.JoinPublishSnapshotProcess, null));
}


//----------------------------------------------------------------------------------------------------------------------
/* eslint-disable func-names */
function* sagas() {
  yield takeLatest(actionTypes.FETCH_PUBLISH_VERSIONS_PAGE, fetchPublishVersionsPage);
  yield takeLatest(actionTypes.FETCH_PUBLISH_VERSION_COMMITS, fetchPublishVersionCommits);
  yield takeLatest(actionTypes.DOWNLOAD_PUBLISH_VERSION_DIFFS, downloadPublishVersionDiffs);
  yield takeLatest(actionTypes.FETCH_PUBLISH_VERSION_DIFFS, fetchPublishVersionDiffs);
  yield takeLatest(actionTypes.VALIDATE_PUBLISH_VERSION, validatePublishVersion);
  yield takeLatest(actionTypes.ADD_NEW_PUBLISH_VERSION, addNewPublishVersion);
  yield takeLatest(actionTypes.INITIALIZE_PUBLISH_VERSION, initializePublishVersion);
  yield takeLatest(actionTypes.FETCH_LAST_PUBLISH, fetchLastPublish);
  yield takeLatest(App.actionTypes.UPDATE_PUBLISH_SNAPSHOTS, updatePublishSnapshotsReceived);
  yield takeLatest(App.actionTypes.SIGNALR_RECONNECTED, signalRReconnected);
}

export default [
  sagas,
];
