import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { saveAs } from 'file-saver';
import App from 'modules/App';
import Modal from 'components/Modal';
import Tabs from 'components/Tabs';
import Button from 'components/Form/Button';
import messages from '../../../messages';
import * as constants from '../../../constants';
import * as actions from '../../../actions';
import * as selectors from '../../../selectors';
import CommitsTable from '../../../partials/CommitsTable';
import DiffJson from '../../../partials/DiffJson';
import styles from './SnapshotDetailsModal.pcss';


class SnapshotDetailsModal extends React.Component {

  static propTypes = {
    // Explicit props
    snapShot   : PropTypes.object,
    openModalId: PropTypes.string,

    // Implicit props
    publishVersionDiffs                 : PropTypes.any,
    isFetchPublishVersionDiffsProgress  : PropTypes.bool,
    hasFetchPublishVersionDiffsErrors   : PropTypes.bool,
    publishCommits                      : PropTypes.any,
    isFetchPublishVersionCommitsProgress: PropTypes.bool,
    hasFetchPublishVersionCommitsErrors : PropTypes.bool,
    // Implicit actions
    onFetchPublishVersionDiffs          : PropTypes.func,
    onDownloadPublishVersionDiffs       : PropTypes.func,
    onFetchPublishVersionCommits        : PropTypes.func,
    onSnapshotDetailsFinish             : PropTypes.func,
    onClose                             : PropTypes.func,

  };


  constructor(props) {
    super(props);
    this.state = {
      activeViewKey: this.viewKeysOrder[0],
      isLoading    : true,
    };
  }


  static getDerivedStateFromProps(props, state) {
    if (state.activeViewKey === 'CommitsTable') {
      state.isLoading = props.isFetchPublishVersionCommitsProgress;
      return state;
    }
    state.isLoading = props.isFetchPublishVersionDiffsProgress;
    return state;
  }


  componentDidMount() {
    this.props.onFetchPublishVersionCommits(this.props.snapShot.version);
  }


  componentDidUpdate() {
    if (this.props.openModalId) {
      if (this.state.activeViewKey === 'CommitsTable') {
        if (!this.props.isFetchPublishVersionCommitsProgress
          && !this.props.hasFetchPublishVersionCommitsErrors
          && this.props.publishCommits === undefined
        ) {
          this.props.onFetchPublishVersionCommits(this.props.snapShot.version);
        }
      } else if (!this.props.isFetchPublishVersionDiffsProgress
          && !this.props.hasFetchPublishVersionDiffsErrors
          && this.props.publishVersionDiffs === undefined
      ) {
        this.props.onFetchPublishVersionDiffs(this.props.snapShot.version);
      }
    }
  }


  componentWillUnmount() {
    this.props.onSnapshotDetailsFinish();
  }


  onChangeView(viewKey) {
    this.setState({ activeViewKey: viewKey });
  }

  onDownloadClick() {
    if (this.props.hasFetchPublishVersionDiffsErrors) {
      this.props.onDownloadPublishVersionDiffs(this.props.snapShot.version);
    } else {
      const blob = new Blob([JSON.stringify(this.props.publishVersionDiffs, null, 2)]);
      saveAs(blob, `Version ${this.props.snapShot.version}.json`);
    }
  }


  get views() {
    return ({
      CommitsTable,
      DiffJson,
    });
  }


  get viewKeysOrder() {
    return (['CommitsTable', 'DiffJson']);
  }


  get tabsLabels() {
    return this.viewKeysOrder.map((id) => ({ id, message: messages.tabs.snapshotDetails[camelCase(id)] }))
      .reduce((prev, current) => ({ ...prev, [current.id]: current.message }), {});
  }


  renderTabs() {
    return (
      <Tabs
        activeItem={this.state.activeViewKey}
        items={this.viewKeysOrder}
        messages={this.tabsLabels}
        action={(activeViewKey) => this.setState({ activeViewKey })}
      />
    );
  }


  renderContent() {
    if (this.state.isLoading) return <div className={styles.snapshotDetailsModal__content} />;
    const ActiveView = this.views[this.state.activeViewKey];
    const publishCommits = get(this.props.publishCommits, 'commits', []);
    const props = { ...this.props,
      publishCommits,
    };
    return (
      <div className={styles.snapshotDetailsModal__content}>
        {this.renderTabs()}
        <ActiveView
          {...props}
          publishVersion={this.props.snapShot.version}
        />
        {this.renderButtons()}
      </div>
    );
  }

  renderButtons() {
    if (this.state.activeViewKey === 'CommitsTable' || this.props.hasFetchPublishVersionDiffsErrors) return null;
    return (
      <Button
        type="button"
        className="btn--primary btn--filled"
        onClick={() => this.onDownloadClick()}
      >
        <FormattedMessage {...App.messages.buttons.download} />
      </Button>
    );
  }


  render() {
    return (
      <Modal
        modalId={constants.SNAPSHOT_DETAILS_MODAL}
        openModalId={this.props.openModalId}
        headerMessage={`Version ${this.props.snapShot.version}`}
        styleModifier="xl"
        onClose={this.props.onClose}
      >
        {this.renderContent()}
      </Modal>
    );
  }

}


const mapStateToProps = (state) => (
  {
    publishCommits                      : selectors.publishCommits(state),
    isFetchPublishVersionCommitsProgress: selectors.isFetchPublishVersionCommitsProgress(state),
    hasFetchPublishVersionCommitsErrors : selectors.hasFetchPublishVersionCommitsErrors(state),

    publishVersionDiffs               : selectors.publishDiffs(state),
    isFetchPublishVersionDiffsProgress: selectors.isFetchPublishVersionDiffsProgress(state),
    hasFetchPublishVersionDiffsErrors : selectors.hasFetchPublishVersionDiffsErrors(state),
  });


const mapDispatchToProps = (dispatch) => ({
  onFetchPublishVersionCommits: (publishVersion) => dispatch(actions.fetchPublishVersionCommits(publishVersion)),

  onFetchPublishVersionDiffs   : (publishVersion) => dispatch(actions.fetchPublishVersionDiffs(publishVersion)),
  onDownloadPublishVersionDiffs: (publishVersion) =>
    dispatch(actions.downloadPublishVersionDiffs(publishVersion, `Version ${publishVersion}.json`)),

  onSnapshotDetailsFinish: () => dispatch(actions.snapshotDetailsFinish()),
});


const ConnectedSnapshotDetailsModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SnapshotDetailsModal);


export default withStyles(styles)(ConnectedSnapshotDetailsModal);
