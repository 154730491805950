import { SIGN_OUT_SUCCESS } from 'modules/Account/actionTypes';
import {
  registerAction,
  unregisterAction,
} from 'helpers/reducerTools';
import * as actionTypes from './actionTypes';


const initialState = {
  components                 : [],
  deleteBLSError             : null,
  updateBlsConfigurationError: null,
  fetching                   : [],
  sending                    : [],
  errors                     : [],
};


export default function reducer(state = { ...initialState }, action) {

  switch (action.type) {

    case actionTypes.FETCH_BLS_LIST: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_BLS_LIST),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_BLS_LIST),
      };
    }
    case actionTypes.FETCH_BLS_LIST_SUCCESS: {
      const { components } = action.payload;
      return {
        ...state,
        components,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_BLS_LIST),
      };
    }
    case actionTypes.FETCH_BLS_LIST_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_BLS_LIST),
        errors  : registerAction(state.errors, actionTypes.FETCH_BLS_LIST),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.ADD_BLS: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.ADD_BLS),
        errors : unregisterAction(state.errors, actionTypes.ADD_BLS),
      };
    }
    case actionTypes.ADD_BLS_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.ADD_BLS),
      };
    }
    case actionTypes.ADD_BLS_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.ADD_BLS),
        errors : registerAction(state.errors, actionTypes.ADD_BLS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_BLS_CONFIGURATION_REGIONS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_BLS_CONFIGURATION_REGIONS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_BLS_CONFIGURATION_REGIONS),
      };
    }
    case actionTypes.FETCH_BLS_CONFIGURATION_REGIONS_SUCCESS: {
      const { countriesAssignments, regions } = action.payload;
      return {
        ...state,
        blsConfiguration: {
          countriesAssignments,
          regions,
        },
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_BLS_CONFIGURATION_REGIONS),
      };
    }
    case actionTypes.FETCH_BLS_CONFIGURATION_REGIONS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_BLS_CONFIGURATION_REGIONS),
        errors  : registerAction(state.errors, actionTypes.FETCH_BLS_CONFIGURATION_REGIONS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.UPDATE_BLS_CONFIGURATION_REGIONS: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.UPDATE_BLS_CONFIGURATION_REGIONS),
        errors : unregisterAction(state.errors, actionTypes.UPDATE_BLS_CONFIGURATION_REGIONS),
      };
    }
    case actionTypes.UPDATE_BLS_CONFIGURATION_REGIONS_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_BLS_CONFIGURATION_REGIONS),
      };
    }
    case actionTypes.UPDATE_BLS_CONFIGURATION_REGIONS_ERROR: {
      const { businessError } = action.payload;
      return {
        ...state,
        updateBlsConfigurationError: businessError,
        sending                    : unregisterAction(state.sending, actionTypes.UPDATE_BLS_CONFIGURATION_REGIONS),
        errors                     : registerAction(state.errors, actionTypes.UPDATE_BLS_CONFIGURATION_REGIONS),
      };
    }
    case actionTypes.UPDATE_BLS_CONFIGURATION_REGIONS_CLEAR: {
      return {
        ...state,
        updateBlsConfigurationError: null,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.DELETE_BLS: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.DELETE_BLS),
        errors : unregisterAction(state.errors, actionTypes.DELETE_BLS),
      };
    }
    case actionTypes.DELETE_BLS_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.DELETE_BLS),
      };
    }
    case actionTypes.DELETE_BLS_ERROR: {
      const { businessError } = action.payload;
      return {
        ...state,
        deleteBLSError: businessError,
        sending       : unregisterAction(state.sending, actionTypes.DELETE_BLS),
        errors        : registerAction(state.errors, actionTypes.DELETE_BLS),
      };
    }

    case actionTypes.DELETE_BLS_CLEAR: {
      return {
        ...state,
        deleteBLSError: null,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case SIGN_OUT_SUCCESS: {
      return {
        ...initialState,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    default:
      return state;

  }
}
