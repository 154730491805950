import * as actionTypes from './actionTypes';


export const fetchBLSList = () => ({
  type   : actionTypes.FETCH_BLS_LIST,
  payload: {

  },
});


export const fetchBLSListSuccess = ({ components }) => ({
  type   : actionTypes.FETCH_BLS_LIST_SUCCESS,
  payload: {
    components,
  },
});


export const fetchBLSListError = (error) => ({
  type: actionTypes.FETCH_BLS_LIST_ERROR,
  error,
});


export const addBLS = (name) => ({
  type   : actionTypes.ADD_BLS,
  payload: {
    name,
  },
});


export const addBLSSuccess = () => ({
  type   : actionTypes.ADD_BLS_SUCCESS,
  payload: {
  },
});


export const addBLSError = (error) => ({
  type: actionTypes.ADD_BLS_ERROR,
  error,
});

export const addBLSClear = () => ({
  type: actionTypes.ADD_BLS_CLEAR,
});


export const fetchBLSConfigurationRegions = (blsName) => ({
  type   : actionTypes.FETCH_BLS_CONFIGURATION_REGIONS,
  payload: {
    blsName,
  },
});


export const fetchBLSConfigurationRegionsSuccess = (countriesAssignments, regions) => ({
  type   : actionTypes.FETCH_BLS_CONFIGURATION_REGIONS_SUCCESS,
  payload: {
    countriesAssignments,
    regions,
  },
});


export const fetchBLSConfigurationRegionsError = (error) => ({
  type: actionTypes.FETCH_BLS_CONFIGURATION_REGIONS_ERROR,
  error,
});


export const updateBLSConfigurationRegions = (blsName, regions, countriesAssignments) => ({
  type   : actionTypes.UPDATE_BLS_CONFIGURATION_REGIONS,
  payload: {
    blsName,
    regions,
    countriesAssignments,
  },
});


export const updateBLSConfigurationRegionsSuccess = () => ({
  type   : actionTypes.UPDATE_BLS_CONFIGURATION_REGIONS_SUCCESS,
  payload: {
  },
});


export const updateBLSConfigurationRegionsError = (businessError) => ({
  type   : actionTypes.UPDATE_BLS_CONFIGURATION_REGIONS_ERROR,
  payload: {
    businessError,
  } }
);


export const updateBLSConfigurationClear = () => ({
  type: actionTypes.UPDATE_BLS_CONFIGURATION_REGIONS_CLEAR,
});


export const deleteBLS = (blsName) => ({
  type   : actionTypes.DELETE_BLS,
  payload: {
    blsName,
  },
});


export const deleteBLSSuccess = () => ({
  type: actionTypes.DELETE_BLS_SUCCESS,
});


export const deleteBLSError = (businessError) => ({
  type   : actionTypes.DELETE_BLS_ERROR,
  payload: {
    businessError,
  },
});

export const deleteBLSClear = () => ({
  type: actionTypes.DELETE_BLS_CLEAR,
});
