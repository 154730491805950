exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".m5Xl3>.tooltip__wrapper{display:none}", ""]);

// exports
exports.locals = {
	"tooltip--hide": "m5Xl3"
};