import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import map from 'lodash/map';
import isString from 'lodash/isString';


class Tabs extends React.PureComponent {

  static propTypes = {
    // Explicit props
    activeItem: PropTypes.string.isRequired,
    items     : PropTypes.arrayOf(PropTypes.string).isRequired,
    messages  : PropTypes.object.isRequired,
    errors    : PropTypes.object,
    modified  : PropTypes.object,
    // Explicit actions
    action    : PropTypes.func.isRequired,
  };


  onClick(evt, item) {
    evt.preventDefault();
    this.props.action(item);
  }


  renderLabel(item) {
    if (!this.props.messages || !this.props.messages[item]) {
      return item;
    }
    if (isString(this.props.messages[item])) {
      return this.props.messages[item];
    }
    return <FormattedMessage {...this.props.messages[item]} />;
  }


  renderTab(item) {
    const { activeItem, errors, modified } = this.props;
    return (
      <li
        key={item}
        className={cn('tab', {
          'tab--active': item === activeItem,
          'tab--error' : errors && errors[item],
        })}
      >
        <a href="" onClick={(evt) => this.onClick(evt, item)}>
          {this.renderLabel(item)}{ modified && modified[item] && '*' }
        </a>
      </li>
    );
  }


  render() {
    const { items } = this.props;
    return (
      <div className="tabs">
        { map(items, (item) => this.renderTab(item)) }
      </div>
    );
  }

}


export default Tabs;
