import { call, put, takeLatest } from 'redux-saga/effects';
import App from 'modules/App';
import AuthService from '../../services/AuthService';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import messages from './messages';


//----------------------------------------------------------------------------------------------------------------------


function* signOut() {
  try {
    AuthService.signOut();
    yield put(actions.signOutSuccess());
  } catch (err) {
    yield put(actions.signOutError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* loadAccount() {
  try {
    const account = AuthService.getAccount();
    const currentAccount = account ? {
      username: account.username,
      scope   : account.idTokenClaims.roles[0],
    } : null;
    yield put(actions.loadAccountSuccess(currentAccount));
  } catch (err) {
    yield put(actions.loadAccountError(err));
    yield call(App.dispatchError, err, messages);
  }
}


//----------------------------------------------------------------------------------------------------------------------


function* sagas() {
  yield takeLatest(actionTypes.SIGN_OUT, signOut);
  yield takeLatest(actionTypes.LOAD_ACCOUNT, loadAccount);
}

export default [
  sagas,
];
