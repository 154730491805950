import * as actionTypes from './actionTypes';


export const fetchPublishVersionsPage = (page, perPage) => ({
  type   : actionTypes.FETCH_PUBLISH_VERSIONS_PAGE,
  payload: {
    page, perPage,
  },
});


export const fetchPublishVersionsPageSuccess = ({ totalRecords, records }) => ({
  type   : actionTypes.FETCH_PUBLISH_VERSIONS_PAGE_SUCCESS,
  payload: {
    totalRecords,
    records,
  },
});


export const fetchPublishVersionsPageError = (error) => ({
  type: actionTypes.FETCH_PUBLISH_VERSIONS_PAGE_ERROR,
  error,
});


export const fetchPublishVersionCommits = (publishVersion) => ({
  type   : actionTypes.FETCH_PUBLISH_VERSION_COMMITS,
  payload: {
    publishVersion,
  },
});


export const fetchPublishVersionCommitsSuccess = (publishCommits) => ({
  type   : actionTypes.FETCH_PUBLISH_VERSION_COMMITS_SUCCESS,
  payload: {
    publishCommits,
  },
});


export const fetchPublishVersionCommitsError = (error) => ({
  type: actionTypes.FETCH_PUBLISH_VERSION_COMMITS_ERROR,
  error,
});


export const fetchPublishVersionDiffs = (publishVersion) => ({
  type   : actionTypes.FETCH_PUBLISH_VERSION_DIFFS,
  payload: {
    publishVersion,
  },
});


export const fetchPublishVersionDiffsSuccess = (publishDiffs) => ({
  type   : actionTypes.FETCH_PUBLISH_VERSION_DIFFS_SUCCESS,
  payload: {
    publishDiffs,
  },
});


export const fetchPublishVersionDiffsError = (error) => ({
  type: actionTypes.FETCH_PUBLISH_VERSION_DIFFS_ERROR,
  error,
});


export const downloadPublishVersionDiffs = (publishVersion, fileName) => ({
  type   : actionTypes.DOWNLOAD_PUBLISH_VERSION_DIFFS,
  payload: {
    publishVersion,
    fileName,
  },
});


export const downloadPublishVersionDiffsSuccess = (publishVersion, fileName) => ({
  type   : actionTypes.DOWNLOAD_PUBLISH_VERSION_DIFFS_SUCCESS,
  payload: {
    publishVersion,
    fileName,
  },
});


export const downloadPublishVersionDiffsError = (error) => ({
  type: actionTypes.DOWNLOAD_PUBLISH_VERSION_DIFFS_ERROR,
  error,
});


export const snapshotDetailsFinish = () => ({
  type: actionTypes.SNAPSHOT_DETAILS_FINISH,
});


export const validatePublishVersion = () => ({
  type: actionTypes.VALIDATE_PUBLISH_VERSION,
});


export const validatePublishVersionSuccess = (validatePublishResult) => ({
  type   : actionTypes.VALIDATE_PUBLISH_VERSION_SUCCESS,
  payload: {
    validatePublishResult,
  },
});


export const validatePublishVersionError = (error) => ({
  type: actionTypes.VALIDATE_PUBLISH_VERSION_ERROR,
  error,
});

export const validatePublishVersionFinish = () => ({
  type: actionTypes.VALIDATE_PUBLISH_VERSION_FINISH,
});

export const setPublishingProgress = () => ({
  type   : actionTypes.SET_PUBLISHING_PROGRESS,
  payload: {
  },
});

export const setPublishingProgressSuccess = (publishingStatus) => ({
  type   : actionTypes.SET_PUBLISHING_PROGRESS_SUCCESS,
  payload: {
    publishingStatus,
  },
});

export const setPublishingProgressError = (error) => ({
  type: actionTypes.SET_PUBLISHING_PROGRESS_ERROR,
  error,
});

export const addNewPublishVersion = () => ({
  type   : actionTypes.ADD_NEW_PUBLISH_VERSION,
  payload: {

  },
});


export const addNewPublishVersionSuccess = (newPublishVersion) => ({
  type   : actionTypes.ADD_NEW_PUBLISH_VERSION_SUCCESS,
  payload: {
    newPublishVersion,
  },
});


export const addNewPublishVersionError = (error) => ({
  type: actionTypes.ADD_NEW_PUBLISH_VERSION_ERROR,
  error,
});

export const initializePublishVersion = () => ({
  type   : actionTypes.INITIALIZE_PUBLISH_VERSION,
  payload: {

  },
});


export const initializePublishVersionSuccess = () => ({
  type   : actionTypes.INITIALIZE_PUBLISH_VERSION_SUCCESS,
  payload: {

  },
});


export const initializePublishVersionError = (error) => ({
  type: actionTypes.INITIALIZE_PUBLISH_VERSION_ERROR,
  error,
});

export const fetchLastPublish = (status = null) => ({
  type   : actionTypes.FETCH_LAST_PUBLISH,
  payload: {
    status,
  },
});


export const fetchLastPublishSuccess = (lastPublish) => ({
  type   : actionTypes.FETCH_LAST_PUBLISH_SUCCESS,
  payload: {
    lastPublish,
  },
});


export const fetchLastPublishError = (error) => ({
  type: actionTypes.FETCH_LAST_PUBLISH_ERROR,
  error,
});
