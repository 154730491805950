exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".niQJR{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;gap:32px}.tabs{margin-bottom:3.2rem}.n8V-e{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column}.n8V-e>button{background:rgba(0,0,0,0);margin-left:-1.6rem;display:inline-block;width:-webkit-fit-content;width:-moz-fit-content;width:fit-content}.n8V-e>div,form{display:-ms-flexbox;display:flex}form{-ms-flex-direction:column;flex-direction:column;-ms-flex-pack:start;justify-content:flex-start;-ms-flex-positive:100;flex-grow:100}form>h5:first-of-type{margin-top:0}form div.row{-ms-flex-align:start;align-items:flex-start}form div.row div.form-group{margin:0}", ""]);

// exports
exports.locals = {
	"configureCountry": "niQJR",
	"configureCountry__header": "n8V-e"
};