exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._25G0-{-ms-flex-direction:column;flex-direction:column}._1w_C2,._25G0-{display:-ms-flexbox;display:flex;gap:32px}._1w_C2{-ms-flex-direction:row;flex-direction:row}._1w_C2 ._1YIhR{display:block;width:24px;height:24px;padding-right:8px}._1w_C2 ._1tof6{margin-right:-20px;margin-left:auto;display:-ms-flexbox;display:flex}._1w_C2 ._1tof6 input{width:330px}._1w_C2 ._1tof6 svg{display:block;width:20px;width:2rem;height:20px;height:2rem;position:relative;left:-30px;left:-3rem;top:8px;top:.8rem}._2uZ81 ._1rOww{display:block;cursor:pointer;width:20px;width:2rem;height:20px;height:2rem}._2uZ81 ._1rOww>g{fill:#da1818}._2uZ81 ._1xlJI{cursor:auto;opacity:.5}._2uZ81 ._3BmYL{cursor:pointer}._2uZ81,._2uZ81 tr td:first-child,._2uZ81 tr th:first-child{padding-left:0}._2uZ81,._2uZ81 tr td:last-child,._2uZ81 tr th:last-child{padding-right:0}._2uZ81 .table__cell{width:412px}._2uZ81 .table__cell--delete{width:20px}._2uZ81 .table__cell--config{width:80px}", ""]);

// exports
exports.locals = {
	"listCountries": "_25G0-",
	"header": "_1w_C2",
	"header__addButton__icon": "_1YIhR",
	"header__search": "_1tof6",
	"countriesTable": "_2uZ81",
	"countriesTable__deleteIcon": "_1rOww",
	"countriesTable__deleteIcon--inactive": "_1xlJI",
	"countriesTable__sortingHeader": "_3BmYL"
};