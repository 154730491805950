import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import Loader from 'components/Loader';
import InfoMessage from 'components/InfoMessage';
import styles from './LoadingBanner.pcss';


class LoadingBanner extends React.PureComponent {

    static propTypes = {
      // Explicit props
      labelMessage: PropTypes.object,
      // Implicit props
      // Implicit actions
    };

    render() {
      return (
        <InfoMessage type="pending">
          <>
            <p><FormattedMessage {...this.props.labelMessage} /></p>
            <Loader isInProgress />
          </>
        </InfoMessage>
      );
    }

}

export default withStyles(styles)(LoadingBanner);


