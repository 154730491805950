import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import styles from './EllipsisTooltipText.pcss';


class EllipsisTooltipText extends React.Component {

  static propTypes = {
    children     : PropTypes.node.isRequired,
    id           : PropTypes.string.isRequired,
    tooltipText  : PropTypes.string.isRequired,
    renderTooltip: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      showTooltip: false,
    };
    this.textRef = React.createRef();
  }


  componentDidMount() {
    this.checkTextOverflow();
    window.addEventListener('resize', this.checkTextOverflow);
  }


  componentWillUnmount() {
    window.removeEventListener('resize', this.checkTextOverflow);
  }


  checkTextOverflow = () => {
    const textElement = this.textRef.current;
    if (textElement) {
      const isOverflowing = textElement.scrollWidth > textElement.offsetWidth;
      this.setState({ showTooltip: isOverflowing });
    }
  };

  renderTooltip(tooltipId) {
    if (!process.env.BROWSER) {
      return null;
    }
    return (
      <ReactTooltip
        id={tooltipId}
        place="right"
        type="dark"
        multiline
        disable
      />
    );
  }

  render() {
    const { children, id, tooltipText } = this.props;

    // Clone the children and add a ref to the wrapper element
    const childrenWithRef = React.Children.map(children, (child) => React.cloneElement(child, { ref: this.textRef }));
    return (
      <div
        className={cn(
          { [styles['tooltip--hide']]: !this.state.showTooltip,
          })}
        data-tip={tooltipText}
        data-for={id}
        // onMouseEnter={() => {
        //   const textElement = this.textRef.current;

        //   this.checkTextOverflow();
        // }}
      >
        {childrenWithRef}
        {this.props.renderTooltip()}
      </div>
    );
  }

}

export default withStyles(styles)(EllipsisTooltipText);
