import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import App from 'modules/App';
import * as constants from '../../../constants';
import * as selectors from '../../../selectors';
import messages from '../../../messages';
import AddBLSForm from './AddBLSForm';


class AddBLSModal extends React.PureComponent {

  static propTypes = {
    // Explicit props
    existingBLSs: PropTypes.arrayOf(PropTypes.string),
    // Implicit props
    openModalId : PropTypes.string,
    isInProgress: PropTypes.bool,
    hasErrors   : PropTypes.bool,
    // Implicit actions
    onClose     : PropTypes.func,
  };


  renderContent() {
    return (
      <AddBLSForm
        existingBLSs={this.props.existingBLSs}
        isInProgress={this.props.isInProgress}
        hasErrors={this.props.hasErrors}
        onClose={this.props.onClose}
      />
    );
  }


  render() {
    return (
      <Modal
        modalId={constants.ADD_BLS_MODAL}
        openModalId={this.props.openModalId}
        headerMessage={messages.headers.addNewBLS}
        styleModifier="lg"
        isInProgress={this.props.isInProgress}
        onClose={this.props.onClose}
      >
        { this.renderContent() }
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  openModalId : App.selectors.modal(state),
  isInProgress: selectors.isAddBLSProgress(state),
  hasErrors   : selectors.hasAddBLSErrors(state),
});


const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(App.actions.closeModal(constants.ADD_BLS_MODAL)),
});


const ConnectedAddBLSModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddBLSModal);


export default ConnectedAddBLSModal;
