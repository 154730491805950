import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Form/Button';
import Modal from 'components/Modal';
import App from 'modules/App';


class UnsavedChangesModal extends React.PureComponent {

  static propTypes = {
    // Explicit props
    labelMessage : PropTypes.object,
    headerMessage: PropTypes.object,
    // Implicit props
    openModalId  : PropTypes.string,
    // Implicit actions
    onClose      : PropTypes.func,
  };


  render() {
    return (
      <Modal
        modalId={App.constants.UNSAVED_CHANGES_MODAL}
        openModalId={this.props.openModalId}
        headerMessage={this.props.headerMessage}
        styleModifier="md"
        onClose={this.props.onClose}
      >
        <p className="text--paragraph mt-8 mb-8 pr-6">
          {this.props.labelMessage && <FormattedMessage {...this.props.labelMessage} />}
        </p>
        <Button
          labelMessage={App.messages.buttons.ok}
          className="btn--block btn--primary btn--filled"
          type="button"
          onClick={this.props.onClose}
        />
      </Modal>
    );
  }

}


export default UnsavedChangesModal;
