import { flatten } from 'flat';
import forEach from 'lodash/forEach';
import set from 'lodash/set';
import get from 'lodash/get';
import isNumber from 'lodash/isNumber';
import toNumber from 'lodash/toNumber';
import endsWith from 'lodash/endsWith';
import { METRICS } from 'modules/Country/constants';


function getConverted(value, toUnit, isToDisplay) {
  const { unit } = METRICS.bloodGlucoseConcentration;
  const { factor } = METRICS.bloodGlucoseConcentration.conversions.MMOL_L;
  if (!isNumber(value)) return null;

  let result = toUnit === unit ? toNumber(value / factor) : toNumber(value * factor);
  if (isToDisplay || toUnit === 'MG_DL') {
    result = toNumber(result.toFixed(1));
  }
  return result;
}


export function toConverted(glucoseRanges, toUnit) {
  const result = {};
  forEach(flatten(glucoseRanges), (value, index) => {
    const isToDisplay = endsWith(index, '-toDisplay');
    const valueToConvert = isToDisplay ? get(glucoseRanges, index.replace('-toDisplay', '')) : value;
    return set(result, index, getConverted(valueToConvert, toUnit, isToDisplay));
  });
  return result;
}

export function toStorage(glucoseRanges, unit) {
  if (unit === 'MG_DL') {
    return glucoseRanges;
  }
  const result = {};
  forEach(flatten(glucoseRanges), (value, index) =>
    set(result, index, getConverted(value, unit, endsWith(index, '-toDisplay'))));
  return result;
}

