import get from 'lodash/get';
import pick from 'lodash/pick';
import { call, select, getContext } from 'redux-saga/effects';
import { saveAs } from 'file-saver';
import { ApiError } from 'helpers/errorTypes';
import { regionName, originalRegionName } from 'modules/App/selectors';
import AuthService from './AuthService';


/**
 * Parses the JSON returned by a network request
 *
 * @param  {object} response A response from a network request
 *
 * @return {object}          The parsed JSON from the request
 */
function parseJSON(response, options) {
  let json;
  try {
    json = response.text().then((text) => (text ? JSON.parse(text) : {}));
  } catch (err) {
    throw new ApiError({
      url             : get(response, 'url'),
      status          : 500,
      error           : err,
      validationErrors: {},
      options,
    });
  }
  if (response.status >= 200 && response.status < 300) {
    return json;
  }
  if (response.status === 409) {
    return json.then((resp) => {
      throw new ApiError({
        url             : get(response, 'url'),
        status          : get(response, 'status'),
        validationErrors: {},
        businessError   : get(resp, 'errorCode') || get(resp, 'message') || get(resp, 'businessError'),
        options         : pick(options, ['method', 'headers']),
      });
    });
  }
  return json.then((resp) => {
    throw new ApiError({
      url             : get(response, 'url'),
      status          : get(response, 'status'),
      validationErrors: get(resp, 'validationErrors'),
      businessError   : get(resp, 'businessError') || get(resp, 'message'),
      requestId       : get(resp, 'requestId'),
      options         : pick(options, ['method', 'headers']),
    });
  });
}

function downloadFile(response, options) {
  try {

    const header = get(options, 'headers.Content-Disposition');
    // Extract filename from header
    const filename = header
      .split(';')
      .find((n) => n.includes('filename='))
      .replace('filename=', '')
      .replaceAll('"', '')
      .trim();
    response.blob().then((blob) => { saveAs(blob, filename); }
    );
    // Download the file
  } catch (err) {
    throw new ApiError({
      url             : get(response, 'url'),
      status          : 500,
      error           : err,
      validationErrors: {},
      options,
    });
  }
}

function* request(url, options, region, fetch) {
  if (!fetch) {
    fetch = yield getContext('fetch');
  }
  const accessToken = yield AuthService.getAccessToken();
  options = {
    ...options,
    headers: {
      ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : null),
      ...get(options, 'headers', {}),
    },
  };
  if (url.startsWith('/api')) {
    url = url.replace('/api', `/api/${region}`);
  }

  let response;

  try {
    response = yield call(fetch, url, options);
  } catch (err) {
    throw new ApiError({
      url,
      status          : 503,
      error           : `${err}`,
      validationErrors: {},
      options,
    });
  }
  if (response.status === 403) {
    const getUrl = yield getContext('getUrl');
    const redirect = yield getContext('redirect');
    const redirectUrl = getUrl('forbidden');
    return yield call(redirect, redirectUrl);
  }

  return yield call(options && get(options, 'headers.Content-Disposition')
    ? downloadFile : parseJSON,
  response, options);
}


function* originalRequest(url, options, fetch = null) {
  const region = yield select(originalRegionName);
  return yield call(request, url, options, region, fetch);
}


function* regionalRequest(url, options, fetch = null) {
  const region = yield select(regionName);
  return yield call(request, url, options, region, fetch);
}


export default {
  originalRequest,
  regionalRequest,
};
