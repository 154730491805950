import React from 'react';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import Button from 'components/Form/Button';
import AddIcon from 'svg/add.svg';
import DeleteIcon from 'svg/delete.svg';
import App from 'modules/App';
import { AppContext } from 'context';
import * as actions from '../../actions';
import * as constants from '../../constants';
import messages from '../../messages';
import * as selectors from '../../selectors';
import AddBLSModal from './AddBLSModal';
import DeleteBLSModal from './DeleteBLSModal';
import styles from './ListBLS.pcss';


class ListBLS extends React.Component {

    static contextType = AppContext;

    static propTypes = {
      // Explicit props
      // Implicit props
      isFetchInProgress  : PropTypes.bool,
      isAddInProgress    : PropTypes.bool,
      isDeleteBLSProgress: PropTypes.bool,
      hasAddErrors       : PropTypes.bool,
      blsList            : PropTypes.arrayOf(PropTypes.string.isRequired),
      deleteBLSError     : PropTypes.object,
      openModalId        : PropTypes.string,
      // Implicit actions
      onFetchBLSList     : PropTypes.func,
      onOpenModal        : PropTypes.func,
      onCloseModal       : PropTypes.func,
    };

    constructor(props) {
      super(props);
      this.state = {
        isInitialized: false,
      };
    }

    componentDidMount() {
      this.props.onFetchBLSList();
    }


    componentDidUpdate(prevProps) {
      if (prevProps.isFetchInProgress && !this.props.isFetchInProgress) {
        this.onInitialized();
      }
      if (prevProps.isAddInProgress && !this.props.isAddInProgress && !this.props.hasAddErrors) {
        this.props.onCloseModal(constants.ADD_BLS_MODAL);
        this.props.onFetchBLSList();
      }
      if (prevProps.isDeleteBLSProgress && !this.props.isDeleteBLSProgress && !this.props.deleteBLSError) {
        this.props.onFetchBLSList();
      }
    }


    onClickDelete(bls) {
      this.setState({ blsToDelete: bls.name });
      this.props.onOpenModal(constants.DELETE_BLS_MODAL);
    }


    onInitialized() {
      this.setState({ isInitialized: true });
    }


    get schema() {
      return (
        [
          {
            key         : 'name',
            labelMessage: messages.labels.blsName,
            renderer    : (bls) => <b>{bls.name}</b>,
          },
          {
            key     : 'config',
            renderer: (bls) => this.renderConfigButton(bls),
          },
          {
            key     : 'delete',
            renderer: (bls) => this.renderDeleteIcon(bls),
          },
        ]
      );
    }


    renderConfigButton(bls) {
      return (
        <Button
          labelMessage={messages.buttons.configRegions}
          className="btn--outline"
          type="link"
          to={this.context.getUrl('bls-configuration-regions', { blsName: bls.name })}
        />
      );
    }


    renderDeleteIcon(bls) {
      return (
        <DeleteIcon
          className={styles.blsTable__deleteIcon}
          onClick={() => this.onClickDelete(bls)}
        />
      );
    }


    renderAddBLSButton() {
      return (
        <Button
          className="btn--primary btn--filled"
          onClick={() => this.props.onOpenModal(constants.ADD_BLS_MODAL)}
        >
          <>
            <AddIcon className={styles.header__addButton__icon} />
            <p><FormattedMessage {...messages.buttons.addBLS} /></p>
          </>
        </Button>
      );
    }


    renderAlerts() {
      if (this.props.openModalId) return <div className="mb-5" />;
      return <App.components.AlertsBus className="mb-5" />;
    }


    renderHeader() {
      return (
        <div className={styles.header}>
          <header className="text--h1">
            <FormattedMessage {...messages.headers.businessLogicSystems} />
          </header>
          {this.renderAddBLSButton()}
        </div>
      );
    }


    renderTable() {
      return (
        <Table
          idKey="name"
          className={styles.blsTable}
          schema={this.schema}
          entities={this.props.blsList.map((bls) => ({ name: bls }))}
          perPage={20}
          isPerPageOff
        />
      );
    }


    renderModals() {
      if (this.props.openModalId === constants.ADD_BLS_MODAL) {
        return (
          <AddBLSModal
            existingBLSs={this.props.blsList}
          />
        );
      }
      if (this.props.openModalId === constants.DELETE_BLS_MODAL) {
        return (
          <DeleteBLSModal
            bls={this.state.blsToDelete}
          />
        );
      }
      return null;
    }


    renderContent() {
      return (
        <div className="card card--mainContent">
          {this.renderTable()}
        </div>
      );
    }


    render() {
      if (!this.state.isInitialized) {
        return null;
      }
      return (
        <div className={styles.listBLS}>
          {this.renderAlerts()}
          {this.renderModals()}
          {this.renderHeader()}
          {this.renderContent()}
        </div>
      );
    }

}


const mapStateToProps = (state) => (
  {
    isFetchInProgress  : selectors.isFetchBLSListProgress(state),
    isAddInProgress    : selectors.isAddBLSProgress(state),
    hasAddErrors       : selectors.hasAddBLSErrors(state),
    blsList            : selectors.blsList(state) || [],
    openModalId        : App.selectors.modal(state),
    deleteBLSError     : selectors.deleteBLSError(state),
    isDeleteBLSProgress: selectors.isDeleteBLSProgress(state),
  });


const mapDispatchToProps = (dispatch) => ({
  onOpenModal   : (modalId) => dispatch(App.actions.openModal(modalId)),
  onCloseModal  : (modalId) => dispatch(App.actions.closeModal(modalId)),
  onFetchBLSList: () => dispatch(actions.fetchBLSList()),
});


const ConnectedListBLS = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListBLS);


export default withStyles(styles)(ConnectedListBLS);
