import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { saveAs } from 'file-saver';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import Button from 'components/Form/Button';
import LoadingBanner from 'components/LoadingBanner';
import InfoMessage from 'components/InfoMessage';
import ChevronRight from 'svg/chevron-right.svg';
import App from 'modules/App';
import DiffJson from '../../../../partials/DiffJson';
import messages from '../../../../messages';
import * as actions from '../../../../actions';
import * as selectors from '../../../../selectors';
import ValidationFailed from './ValidationFailed';
import styles from './Validate.pcss';


class Validate extends React.PureComponent {

  static propTypes = {
    // Explicit props
    onChangeView                     : PropTypes.func,
    // Implicit props
    hasValidatePublishResultAnyError : PropTypes.bool,
    hasValidatePublishVersionError   : PropTypes.bool,
    validatePublishResult            : PropTypes.any,
    isValidatePublishVersionProgress : PropTypes.bool,
    hasFetchPublishVersionDiffsErrors: PropTypes.bool,
    // Implicit actions
    onValidatePublishVersion         : PropTypes.func,
    onClose                          : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }


  static getDerivedStateFromProps(props, state) {
    state.isLoading = props.isValidatePublishVersionProgress;
    return state;
  }


  componentDidMount() {
    this.props.onValidatePublishVersion();
  }

  onDownloadClick() {
    const blob = new Blob([JSON.stringify(this.props.validatePublishResult, null, 2)]);
    saveAs(blob, 'Version new.json');
  }


  renderCancelButton() {
    return (
      <Button
        type="button"
        styleModifier="primary"
        labelMessage={App.messages.buttons.cancel}
        onClick={this.props.onClose}
      />
    );
  }


  renderPublishButton() {
    return (
      <Button
        type="button"
        styleModifier="primary"
        labelMessage={messages.buttons.publish}
        className="btn btn--filled"
        onClick={() => this.props.onChangeView('Publish')}
      />
    );
  }


  renderDownloadButton() {
    return (
      <Button
        type="button"
        labelMessage={App.messages.buttons.download}
        onClick={() => this.onDownloadClick()}
        className="btn btn--filled"
      />
    );
  }


  renderBackToReviewButton() {
    return (
      <Button
        type="button"
        styleModifier="primary"
        className="btn btn--filled"
        onClick={() => this.props.onChangeView('Review')}
      >
        <ChevronRight className="backArrow" />
        <FormattedMessage
          {...messages.buttons.backToReview}
        />
      </Button>
    );
  }


  renderValidationSucceeded() {
    return (
      <div className="d-flex flex-column h-100 justify-content-between">
        <InfoMessage type="success">
          <p className="text--paragraph"><FormattedMessage {...messages.infos.validationSucceeded} /></p>
        </InfoMessage>
        <div className={styles.review__btnContainer}>
          {this.renderCancelButton()}
          {this.renderPublishButton()}
        </div>
      </div>
    );
  }


  renderValidationFailed() {
    return (
      <div className="d-flex flex-column h-100 justify-content-between">
        <div className={styles.validationFailed__tabView}>
          <ValidationFailed
            validatePublishResult={this.props.validatePublishResult}
          />
        </div>
        <div className={styles.review__btnContainer}>
          {this.renderCancelButton()}
          {this.renderBackToReviewButton()}
        </div>
      </div>
    );
  }


  renderValidationError() {
    return (
      <div className="d-flex flex-column h-100 justify-content-between">
        <div>
          <InfoMessage type="error">
            <p className="text--paragraph text--bold"><FormattedMessage {...messages.infos.validationError} /> </p>
          </InfoMessage>
          <DiffJson
            publishVersion="new"
            publishVersionDiffs={this.props.validatePublishResult}
            isFetchPublishVersionDiffsProgress={this.props.isValidatePublishVersionProgress}
          />
        </div>
        <div className={styles.review__btnContainer}>
          {this.renderBackToReviewButton()}
          {this.renderCancelButton()}
          {this.renderDownloadButton()}
        </div>
      </div>
    );
  }


  render() {
    if (this.state.isLoading) {
      return (
        <LoadingBanner labelMessage={messages.infos.validating} />
      );
    }

    if (this.props.hasValidatePublishResultAnyError) return this.renderValidationFailed();
    if (this.props.hasValidatePublishVersionError) return this.renderValidationError();
    return this.renderValidationSucceeded();
  }

}


const mapStateToProps = (state) => (
  {
    isValidatePublishVersionProgress: selectors.isValidatePublishVersionProgress(state),
    hasValidatePublishVersionError  : selectors.hasValidatePublishVersionErrors(state),

    validatePublishResult           : selectors.validatePublishResult(state),
    hasValidatePublishResultAnyError: selectors.hasValidatePublishResultAnyError(state),
  });


const mapDispatchToProps = (dispatch) => ({
  onValidatePublishVersion: () => dispatch(actions.validatePublishVersion()),
});


const ConnectedValidate = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Validate);


export default withStyles(styles)(ConnectedValidate);
