import React from 'react';
import PropTypes from 'prop-types';
import { motion } from 'framer-motion';
import isObjectLike from 'lodash/isObjectLike';
import isFunction from 'lodash/isFunction';
import { FormattedMessage } from 'react-intl';


class CollapsibleGroup extends React.PureComponent {

  static propTypes = {
    headerMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
    children     : PropTypes.node.isRequired,
    className    : PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    isOpen: PropTypes.bool,

  };

  static defaultProps = {
    className: '',
  };


  constructor(props) {
    super(props);
    this.state = {
      isOpening: false,
      overflow : 'hidden',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
      this.onSwitch();
    }
  }

  onSwitch() {
    this.setState((state) => ({ isOpening: !state.isOpening }));
  }


  onAnimationStart() {
    const { isOpen, isOpening } = this.state;
    if (isOpening && !isOpen) {
      this.setState({ isOpen: true });
    } else if (!isOpening && isOpen) {
      this.setState({ overflow: 'hidden' });
    }
  }


  onAnimationComplete() {
    const { isOpen, isOpening } = this.state;
    if (!isOpening && isOpen) {
      this.setState({ isOpen: false });
    } else if (isOpening && isOpen) {
      this.setState({ overflow: 'visible' });
    }
  }


  renderHeaderText() {
    if (isFunction(this.props.headerMessage)) return this.props.headerMessage();
    return (
      isObjectLike(this.props.headerMessage)
        ? <FormattedMessage {...this.props.headerMessage} />
        : this.props.headerMessage
    );
  }


  render() {
    const { isOpening, overflow } = this.state;
    return (
      <motion.div
        initial={{ height: 0 }}
        animate={{ height: isOpening ? 'auto' : 0 }}
        transition={{ ease: 'easeOut', duration: 0.3 }}
        style={{ overflow }}
        onAnimationStart={() => this.onAnimationStart()}
        onAnimationComplete={() => this.onAnimationComplete()}
      >
        {this.props.children}
      </motion.div>
    );
  }

}


export default CollapsibleGroup;
