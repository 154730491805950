import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import lowerCase from 'lodash/lowerCase';
import filter from 'lodash/filter';
import isEqual from 'lodash/isEqual';
import Button from 'components/Form/Button';
import Select from 'components/Form/Select';
import App from 'modules/App';
import messages from '../../../messages';


class FilterApprovers extends React.PureComponent {

    static propTypes = {
      countries     : PropTypes.array,
      chosenCounties: PropTypes.array,
      onSubmit      : PropTypes.func,
    }


    constructor(props) {
      super(props);
      if (this.props.chosenCounties) {
        this.state = {
          chosenCounties: [],
        };
      } else {
        this.state = {
          chosenCounties: [],
        };
      }

    }


    componentDidUpdate(prevProps) {
      if (prevProps.chosenCounties !== this.props.chosenCounties) {
        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ chosenCounties: this.props.chosenCounties });
      }
    }


    onChange(value) {
      if (this.state.chosenCounties.includes(value)) {
        this.setState((state) => {
          const countries = filter(state.chosenCounties, (country) => country !== value);
          return { chosenCounties: countries };
        });
      } else {
        this.setState((state) => ({ chosenCounties: [...state.chosenCounties, value] }));
      }
    }

    onSubmit(countries) {
      this.props.onSubmit(countries);
    }

    onCancel() {
      this.setState({ chosenCounties: this.props.chosenCounties });
    }


    get countryOptions() {
      return (this.props.countries || []).sort((a, b) => {
        if (lowerCase(a.englishName) > lowerCase(b.englishName)) { return 1; }
        return -1;
      }
      );
    }


    get hasUnsavedChanges() {
      return !isEqual(this.state.chosenCounties, this.props.chosenCounties);
    }


    get hasChosenCountry() {
      return this.props.chosenCounties.length;
    }


    renderActions() {
      const secondaryButton = this.hasChosenCountry
        ? (
          <Button
            type="button"
            className="col-6 mt-0 mr-6"
            styleModifier="primary"
            labelMessage={App.messages.buttons.clear}
            onClick={() => this.onSubmit([])}
          />
        )
        : (
          <Button
            type="button"
            className="col-6 mt-0 mr-6"
            styleModifier="primary"
            labelMessage={App.messages.buttons.cancel}
            isDisabled={!this.hasUnsavedChanges}
            onClick={() => this.onCancel()}
          />
        );

      return (
        <div className="d-flex w-25">
          {secondaryButton}
          <Button
            type="button"
            className="btn--filled col-6 mt-0"
            styleModifier="primary"
            labelMessage={App.messages.buttons.apply}
            isDisabled={!this.hasUnsavedChanges}
            onClick={() => {
              this.onSubmit(this.state.chosenCounties);
            }}
          />
        </div>
      );
    }


    render() {
      return (
        <div className="card mb-7">
          <h1 className="text--h5 mt-0">
            <FormattedMessage {...messages.headers.filterApprover} />
          </h1>
          <label className="form-label mb-2 mb-5" htmlFor="filterApprover">
            <FormattedMessage {...messages.labels.assignedCountries} />
          </label>
          <Select
            id="filterApprover"
            className="mb-5 w-50"
            optionsFrom={this.countryOptions}
            valueKey="code"
            labelKey="englishName"
            noValueMessage={App.messages.placeholders.select}
            value={this.state.chosenCounties}
            onChange={(input) => this.onChange(input.value)}
            multi
          />
          {this.renderActions()}
        </div>
      );
    }

}


export default FilterApprovers;
