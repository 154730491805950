import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import CopyToClipboardIcon from 'svg/copy-to-clipboard.svg';
import Check from 'svg/check.svg';
import Tooltip from 'components/Tooltip';
import styles from './CopyToClipboard.pcss';


class CopyToClipboard extends React.PureComponent {

    static propTypes = {
      copyText: PropTypes.string.isRequired,
    }

    constructor() {
      super();
      this.state = {
        isCopied: false,
      };
    }

    copyTextToClipboard(text) {
      if ('clipboard' in navigator) {
        return navigator.clipboard.writeText(text);
      }
      return document.execCommand('copy', true, text);
    }

    handleCopyClick() {
      this.copyTextToClipboard(this.props.copyText)
        .then(() => {
          this.setState({ isCopied: true });
          setTimeout(() => {
            this.setState({ isCopied: false });
          }, 1500);
        })
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.log(err);
        });
    }


    render() {
      const tooltipMessage = (
        <div className="d-flex align-items-center text--light">
          <Check className={
            cn(styles.copyToClipboard__icon, styles['consents__consent__icon--check'])
          }
          />
          <p className="text--small ml-2">Copied to clipboard!</p>
        </div>
      );
      return (
        <div className={styles.copyToClipboard}>
          <Tooltip
            isVisible={this.state.isCopied}
            message={tooltipMessage}
          >
            <CopyToClipboardIcon
              className={styles.copyToClipboard__icon}
              onClick={() => this.handleCopyClick()}
            />
          </Tooltip>
        </div>
      );
    }

}

export default withStyles(styles)(CopyToClipboard);
