import React from 'react';
import PropTypes from 'prop-types';
import { saveAs } from 'file-saver';
import { FormattedMessage } from 'react-intl';
import camelCase from 'lodash/camelCase';
import get from 'lodash/get';
import withStyles from 'isomorphic-style-loader/withStyles';
import { connect } from 'react-redux';
import Tabs from 'components/Tabs';
import Button from 'components/Form/Button';
import App from 'modules/App';
import messages from '../../../../messages';
import * as actions from '../../../../actions';
import * as selectors from '../../../../selectors';
import CommitsTable from '../../../../partials/CommitsTable';
import DiffJson from '../../../../partials/DiffJson';
import styles from './Review.pcss';


class Review extends React.PureComponent {

  static propTypes = {
    // Explicit props
    version     : PropTypes.string,
    onChangeView: PropTypes.func,
    openModalId : PropTypes.string,

    // Implicit props
    publishVersionDiffs                 : PropTypes.any,
    isFetchPublishVersionDiffsProgress  : PropTypes.bool,
    isValidationFailed                  : PropTypes.bool,
    hasFetchPublishVersionDiffsErrors   : PropTypes.bool,
    publishCommits                      : PropTypes.any,
    isFetchPublishVersionCommitsProgress: PropTypes.bool,
    hasFetchPublishVersionCommitsErrors : PropTypes.bool,
    // Implicit actions
    onFetchPublishVersionDiffs          : PropTypes.func,
    onDownloadPublishVersionDiffs       : PropTypes.func,
    onFetchPublishVersionCommits        : PropTypes.func,
    onClose                             : PropTypes.func,

  };


  constructor(props) {
    super(props);
    this.state = {
      activeViewKey: this.viewKeysOrder[0],
      isLoading    : true,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (state.activeViewKey === 'CommitsTable') {
      state.isLoading = props.isFetchPublishVersionCommitsProgress;
      return state;
    }
    state.isLoading = props.isFetchPublishVersionDiffsProgress;
    return state;
  }

  componentDidMount() {
    this.props.onFetchPublishVersionCommits(this.props.version);
  }


  componentDidUpdate() {
    if (this.state.activeViewKey === 'CommitsTable') {
      if (!this.props.isFetchPublishVersionCommitsProgress
          && !this.props.hasFetchPublishVersionCommitsErrors
          && this.props.publishCommits === undefined) {
        this.props.onFetchPublishVersionCommits(this.props.version);
      }
    } else if (!this.props.isFetchPublishVersionDiffsProgress
          && !this.props.hasFetchPublishVersionDiffsErrors
          && this.props.publishVersionDiffs === undefined
    ) {
      this.props.onFetchPublishVersionDiffs(this.props.version);
    }
  }


  onChangeView(viewKey) {
    this.setState({ activeViewKey: viewKey });
  }


  onDownloadClick() {
    if (this.props.hasFetchPublishVersionDiffsErrors) {
      this.props.onDownloadPublishVersionDiffs(this.props.version);
    } else {
      const blob = new Blob([JSON.stringify(this.props.publishVersionDiffs, null, 2)]);
      saveAs(blob, `Version ${this.props.version}.json`);
    }
  }


  get views() {
    return ({
      CommitsTable,
      DiffJson,
    });
  }


  get viewKeysOrder() {
    return (['CommitsTable', 'DiffJson']);
  }


  get tabsLabels() {
    return this.viewKeysOrder.map((id) => ({ id, message: messages.tabs.snapshotDetails[camelCase(id)] }))
      .reduce((prev, current) => ({ ...prev, [current.id]: current.message }), {});
  }

  get hasCommitsToPublish() {
    const commits = get(this.props.publishCommits, 'commits', []);
    const hasChanged = get(this.props.publishCommits, 'hasChanged', false);

    return commits.length && hasChanged;
  }

  renderTabs() {
    return (
      <Tabs
        activeItem={this.state.activeViewKey}
        items={this.viewKeysOrder}
        messages={this.tabsLabels}
        action={(activeViewKey) => this.setState({ activeViewKey })}
      />
    );
  }


  renderCancelButton() {
    return (
      <Button
        type="button"
        styleModifier="primary"
        labelMessage={App.messages.buttons.cancel}
        onClick={this.props.onClose}
      />
    );
  }


  renderValidateButton() {
    return (
      <Button
        type="button"
        styleModifier="primary"
        labelMessage={App.messages.buttons.validate}
        className="btn btn--filled"
        onClick={() => this.props.onChangeView('Validate')}
      />
    );
  }


  renderDownloadButton() {
    return (
      <Button
        type="button"
        labelMessage={App.messages.buttons.download}
        onClick={() => this.onDownloadClick()}
        className="btn btn--filled"
      />
    );
  }


  renderButtons() {
    if (this.state.activeViewKey === 'CommitsTable') {
      return (
        <div className={styles.review__CommitsTableBtnContainer}>
          {this.renderCancelButton()}
          {!this.props.isValidationFailed && this.renderValidateButton()}
        </div>
      );
    }

    return (
      <div className={styles.review__DiffJsonBtnContainer}>
        {this.renderCancelButton()}
        {!this.props.isValidationFailed && this.renderValidateButton()}
        {this.props.hasFetchPublishVersionDiffsErrors || this.renderDownloadButton()}
      </div>
    );
  }


  renderContent() {
    if (!this.hasCommitsToPublish) {
      return (
        <>
          <p className="form-error pt-7 pb-7 justify-content-center">
            <FormattedMessage {...messages.infos.noneChangesToPublish} />
          </p>
          <div className={styles.review__CommitsTableBtnContainer}>
            {this.renderCancelButton()}
          </div>
        </>

      );
    }
    const ActiveView = this.views[this.state.activeViewKey];
    const publishCommits = get(this.props.publishCommits, 'commits', []);
    const props = { ...this.props,
      publishCommits,
    };
    return (
      <>
        <div>
          {this.renderTabs()}
          <ActiveView
            {...props}
            publishVersion={this.props.version}
          />
        </div>
        {this.renderButtons()}
      </>
    );
  }


  render() {
    if (this.state.isLoading) return null;
    return (
      <>
        {this.renderContent()}
      </>
    );
  }

}


const mapStateToProps = (state) => (
  {
    publishCommits                      : selectors.publishCommits(state),
    isFetchPublishVersionCommitsProgress: selectors.isFetchPublishVersionCommitsProgress(state),
    hasFetchPublishVersionCommitsErrors : selectors.hasFetchPublishVersionCommitsErrors(state),

    publishVersionDiffs               : selectors.publishDiffs(state),
    isFetchPublishVersionDiffsProgress: selectors.isFetchPublishVersionDiffsProgress(state),
    hasFetchPublishVersionDiffsErrors : selectors.hasFetchPublishVersionDiffsErrors(state),

    isValidationFailed: selectors.hasValidatePublishResultAnyError(state)
      || selectors.hasValidatePublishVersionErrors(state),

  });


const mapDispatchToProps = (dispatch) => ({
  onFetchPublishVersionCommits: (publishVersion) => dispatch(actions.fetchPublishVersionCommits(publishVersion)),

  onFetchPublishVersionDiffs   : (publishVersion) => dispatch(actions.fetchPublishVersionDiffs(publishVersion)),
  onDownloadPublishVersionDiffs: (publishVersion) =>
    dispatch(actions.downloadPublishVersionDiffs(publishVersion, `Version ${publishVersion}.json`)),
});


const ConnectedReview = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Review);


export default withStyles(styles)(ConnectedReview);
