exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2Tsr4{width:24px;width:2.4rem;height:24px;height:2.4rem}", ""]);

// exports
exports.locals = {
	"box__icon": "_2Tsr4"
};