import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import Statement from 'components/Statement';
import messages from '../../messages';
import styles from './Error.pcss';


class Error extends React.PureComponent {

  static propTypes = {
    code: PropTypes.number,
  };


  get codeName() {
    switch (this.props.code) {
      case 403: {
        return 'forbidden';
      }
      case 404: {
        return 'notFound';
      }
      default:
        return 'general';
    }
  }


  renderHeader(title) {
    return (
      <>
        <p className="text--primary text--medium text-lh--17">
          <FormattedMessage {...messages.errorPage.errorCode} />: {this.props.code}
        </p>
        <h1 className="text--h1"><FormattedMessage {...title} /></h1>
      </>
    );
  }


  renderInfoMessage(info, info2) {

    return (
      <>
        <span><FormattedMessage {...info} /></span>
        {info2 && (
        <span className={styles.info2}>
          <FormattedMessage
            {...info2}
            values={{
              b : (chunk) => <b>{chunk}</b>,
              br: <br />,
            }}
          />
        </span>
        )}
      </>
    );
  }


  renderGoToHomeButton() {
    return (
      <a href="/" className="btn btn--primary btn--filled btn--wider mr-4">
        <span className="btn-inner">
          <div className="btn__labelWrapper text--normal">
            <span><FormattedMessage {...messages.errorPage.goToHomePage} /></span>
          </div>
        </span>
      </a>
    );
  }

  render() {
    const { title, info, info2 } = messages.errorPage[this.codeName];
    const imgSrc = `/bo/assets/svg/illustration/${this.codeName}.svg`;
    return (
      <Statement
        headerMessage={this.renderHeader(title)}
        imgSrc={imgSrc}
      >
        <div>
          <p className="mb-8">{this.renderInfoMessage(info, info2)}</p>
          {this.renderGoToHomeButton()}
        </div>
      </Statement>
    );
  }

}

export default withStyles(styles)(Error);
