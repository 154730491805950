import { combineReducers } from 'redux';
import { routerReducer } from 'redux-first-routing';
import App from 'modules/App';
import Account from 'modules/Account';
import Country from 'modules/Country';
import BusinessLogicSystem from 'modules/BusinessLogicSystem';
import Organization from 'modules/Organization';
import PublishSnapshot from 'modules/PublishSnapshot';


/**
 * Here go all core non-dynamic reducers
 * @returns {{router: routerReducer}}
 */
export function getCoreReducers() {
  return {
    router                                   : routerReducer,
    [App.constants.MODULE_ID]                : App.reducer,
    [Account.constants.MODULE_ID]            : Account.reducer,
    [Country.constants.MODULE_ID]            : Country.reducer,
    [BusinessLogicSystem.constants.MODULE_ID]: BusinessLogicSystem.reducer,
    [Organization.constants.MODULE_ID]       : Organization.reducer,
    [PublishSnapshot.constants.MODULE_ID]    : PublishSnapshot.reducer,
  };
}

export default function createReducer(asyncReducers = {}) {
  const coreReducers = getCoreReducers();
  return combineReducers({
    ...coreReducers,
    ...asyncReducers,
  });
}
