import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import App from 'modules/App';
import Button from 'components/Form/Button';
import Modal from 'components/Modal';
import messages from '../../messages';
import * as constants from '../../constants';


class PublishPendingModal extends React.PureComponent {

  static propTypes = {
    // Explicit props
    openModalId: PropTypes.string,
    account    : PropTypes.string,
    onClose    : PropTypes.func,
  };


  render() {
    const { account } = this.props;
    return (
      <Modal
        modalId={constants.PUBLISH_PENDING_MODAL}
        openModalId={this.props.openModalId}
        headerMessage={messages.headers.publishPending}
        onClose={this.props.onClose}
        styleModifier="md"
      >
        <p className="text--paragraph  mt-7 mb-7">
          <FormattedMessage {...messages.infos.publishPending} values={{ account }} />
        </p>
        <Button
          labelMessage={App.messages.buttons.ok}
          className="btn--block btn--primary btn--filled"
          type="button"
          onClick={this.props.onClose}
        />
      </Modal>
    );
  }

}


export default PublishPendingModal;
