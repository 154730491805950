exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".okydw{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;margin-top:32px}.SkSAY{-ms-flex-pack:justify;justify-content:space-between;margin-bottom:32px}.SkSAY,.SkSAY ._1YwUx{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row}.SkSAY ._1YwUx{-ms-flex-align:center;align-items:center;gap:32px}.SkSAY ._1YwUx ._2ha2J{background:#cde6e7}.SkSAY ._2MV4E{display:block;width:24px;height:24px;padding-right:8px}.SkSAY ._1l6nM{margin-right:-20px;margin-left:auto;display:-ms-flexbox;display:flex}.SkSAY ._1l6nM input{width:330px}.SkSAY ._1l6nM svg{display:block;width:20px;width:2rem;height:20px;height:2rem;position:relative;left:-30px;left:-3rem;top:8px;top:.8rem}.SkSAY .d6QGM{width:20px;height:20px;border-radius:16px;margin-left:8px;display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;background:#078285;color:#fff}._3WEqE{background:red}._3WEqE ._3Wena{display:block;cursor:pointer;width:20px;width:2rem;height:20px;height:2rem}._3WEqE ._3Wena>g{fill:#da1818}._3WEqE .J7J3R{cursor:auto;opacity:.5}._3WEqE ._3Pijh{cursor:pointer}._3WEqE ._1laWQ{overflow:hidden;white-space:nowrap;-o-text-overflow:ellipsis;text-overflow:ellipsis}._3WEqE,._3WEqE tr td:first-child,._3WEqE tr th:first-child{padding-left:0}._3WEqE,._3WEqE tr td:last-child,._3WEqE tr th:last-child{padding-right:0}._3WEqE .table__cell{padding:16px 16px 16px 0;overflow:hidden;white-space:nowrap;-o-text-overflow:ellipsis;text-overflow:ellipsis;max-width:200px}._3WEqE tr .table__cell--firstName,._3WEqE tr .table__cell--lastName{width:180px}._3WEqE .table__cell--delete{width:20px}._3WEqE .table__cell--edit{width:80px}._3WEqE .table{width:100%}", ""]);

// exports
exports.locals = {
	"listApprovers": "okydw",
	"header": "SkSAY",
	"header__side": "_1YwUx",
	"filter--active": "_2ha2J",
	"header__addButton__icon": "_2MV4E",
	"header__search": "_1l6nM",
	"header__wrapperCounter": "d6QGM",
	"approversTable": "_3WEqE",
	"approversTable__deleteIcon": "_3Wena",
	"approversTable__deleteIcon--inactive": "J7J3R",
	"approversTable__sortingHeader": "_3Pijh",
	"table__wrapCell": "_1laWQ"
};