export const MODULE_ID = 'account';

export const SCOPE_NAMES = {
  BACK_OFFICE_USER: 'BackOfficeUser',
  // ORGANIZATION_APPROVER: 'OrganizationApprover',
  COUNTRY_MANAGER : 'CountryManager',
  SDN_ADMIN       : 'SDNAdmin',
};

export const ACCOUNT_SCOPE_NAMES = {
  PERSONAL    : 'Personal',
  CAREGIVER   : 'Caregiver',
  PROFESSIONAL: 'Professional',
  CLINIC_STAFF: 'ClinicStaff',
};

const NAVITEMS_ROUTING_NAMES = {
  dashboard           : 'dashboard',
  approvers           : 'approvers',
  countries           : 'countries',
  businessLogicSystems: 'businessLogicSystems',
  publishSnapshot     : 'publishSnapshot',
  logout              : 'logout',
};

export const ROUTING_BY_SCOPE = {
  BackOfficeUser: [
    { key: 'dashboard', routeName: NAVITEMS_ROUTING_NAMES.dashboard },
    { key: 'logout', routeName: NAVITEMS_ROUTING_NAMES.logout },
  ],

  OrganizationApprover: [
    { key: 'approvers', routeName: NAVITEMS_ROUTING_NAMES.approvers },
    { key: 'logout', routeName: NAVITEMS_ROUTING_NAMES.logout },
  ],

  CountryManager: [
    { key: 'approvers', routeName: NAVITEMS_ROUTING_NAMES.approvers },
    { key: 'countries', routeName: NAVITEMS_ROUTING_NAMES.countries },
    { key: 'logout', routeName: NAVITEMS_ROUTING_NAMES.logout },
  ],

  SDNAdmin: [
    { key: 'approvers', routeName: NAVITEMS_ROUTING_NAMES.approvers },
    { key: 'countries', routeName: NAVITEMS_ROUTING_NAMES.countries },
    { key: 'businessLogicSystems', routeName: NAVITEMS_ROUTING_NAMES.businessLogicSystems },
    { key: 'publishSnapshot', routeName: NAVITEMS_ROUTING_NAMES.publishSnapshot },
    { key: 'logout', routeName: NAVITEMS_ROUTING_NAMES.logout },
  ],
};

export const HOME_PAGE_BY_SCOPE = {
  BackOfficeUser: '/bo/dashboard',
  // OrganizationApprover: '/bo/approvers',
  CountryManager: '/bo/countries',
  SDNAdmin      : '/bo/publish-snapshot',
};
