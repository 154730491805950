import React from 'react';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Button from 'components/Form/Button';
import Modal from 'components/Modal';
import App from 'modules/App';
import * as constants from '../../../../../constants';
import messages from '../../../../../messages';
import styles from './ConfirmDeletePayerModal.pcss';


class ConfirmDeletePayerModal extends React.PureComponent {

  static propTypes = {
    onDeletePayer: PropTypes.func,
    // Explicit props
    isInProgress : PropTypes.bool,
    hasErrors    : PropTypes.bool,
    // Implicit props
    openModalId  : PropTypes.string,
    // Implicit actions
    onClose      : PropTypes.func,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.isInProgress && !this.props.isInProgress && !this.props.hasErrors) {
      this.props.onClose();
    }
  }


  renderContent() {
    return (
      <>
        <p className="text--paragraph mb-5"><FormattedMessage {...messages.infos.deletePayer} /></p>
        <div className="d-flex">
          <Button
            type="button"
            styleModifier="primary"
            labelMessage={App.messages.buttons.cancel}
            className="btn col-6 mr-3"
            onClick={() => this.props.onClose()}
          />
          <Button
            type="button"
            styleModifier="primary"
            labelMessage={App.messages.buttons.delete}
            className="btn btn--filled btn--delete col-6 mr-3"
            onClick={() => this.props.onDeletePayer()}
          />
        </div>
      </>
    );
  }


  render() {
    return (
      <Modal
        modalId={constants.DELETE_PAYER_MODAL}
        openModalId={this.props.openModalId}
        headerMessage={messages.headers.deletePayer}
        styleModifier="md"
        isInProgress={this.props.isInProgress}
        onClose={this.props.onClose}
      >
        { this.renderContent() }
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  openModalId: App.selectors.modal(state),
});


const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(App.actions.closeModal()),
});


const ConnectedConfirmDeletePayerModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfirmDeletePayerModal);


export default withStyles(styles)(ConnectedConfirmDeletePayerModal);
