import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withStyles from 'isomorphic-style-loader/withStyles';
import Button from 'components/Form/Button';
import Table from 'components/Table';
import App from 'modules/App';
import messages from '../../messages';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import styles from './SnapshotsTable.pcss';


class SnapshotsTable extends React.Component {


    static propTypes = {
      // Explicit props
      onDetailsClick                               : PropTypes.func,
      // Implicit props
      totalEntities                                : PropTypes.number,
      publishVersionsPage                          : PropTypes.array,
      onFetchSnapshotVersionsPage                  : PropTypes.func,
      isUpdatePublishSnapshotNotificationInProgress: PropTypes.bool,
    }


    constructor(props) {
      super(props);
      this.state = {
        page: 0,
      };
      this.props.onFetchSnapshotVersionsPage(this.state.page, 10);
    }


    componentDidUpdate(prevProps) {
      if (prevProps.isUpdatePublishSnapshotNotificationInProgress
        && !this.props.isUpdatePublishSnapshotNotificationInProgress) {
        this.onFetch({ page: 0, perPage: 10 });
      }
    }


    onFetch({ page, perPage }) {
      this.props.onFetchSnapshotVersionsPage(page, perPage);
      this.setState({ page });
    }


    get schema() {
      return (
        [
          {
            key         : 'version',
            labelMessage: messages.labels.version,
            renderer    : (snapShot) => <b>{snapShot.version}</b>,
          },
          {
            key         : 'commitsCount',
            labelMessage: messages.labels.commitsCount,
            renderer    : (snapShot) => <p>{snapShot.commitsCount}</p>,
          },
          {
            key         : 'createdBy',
            labelMessage: messages.labels.userName,
            renderer    : (snapShot) => <p>{snapShot.createdBy}</p>,
          },
          {
            key         : 'publishFinished',
            labelMessage: messages.labels.dateTime,
            renderer    : (snapShot) => <p>{snapShot.publishFinished}</p>,
          },
          {
            key     : 'action',
            renderer: (snapShot) => this.renderAction(snapShot),
          },
        ]
      );
    }


    renderAction(snapShot) {
      return (
        <Button
          labelMessage={messages.buttons.details}
          className="btn--outline"
          type="button"
          onClick={() => this.props.onDetailsClick(snapShot)}
        />
      );
    }


    render() {
      return (
        <Table
          idKey="version"
          schema={this.schema}
          className={styles.snapshotsTable}
          entities={this.props.publishVersionsPage}
          page={this.state.page}
          perPage={10}
          totalEntities={this.props.totalEntities}
          isPerPageOff
          isOnPageChangeReturnOneSide
          onPageChange={(event) => this.onFetch(event)}
        />
      );
    }

}


const mapStateToProps = (state) => (
  {
    openModalId                                  : App.selectors.modal(state),
    totalEntities                                : selectors.publishVersionsPageTotal(state),
    publishVersionsPage                          : selectors.publishVersionsPage(state) || [],
    isUpdatePublishSnapshotNotificationInProgress: selectors.isUpdatePublishSnapshotNotificationInProgress(state),
  });

const mapDispatchToProps = (dispatch) => ({
  onFetchSnapshotVersionsPage: (page, perPage) => dispatch(actions.fetchPublishVersionsPage(page, perPage)),
  onCloseModal               : () => dispatch(App.actions.closeModal()),
});


const ConnectedSnapshotsTable = connect(
  mapStateToProps,
  mapDispatchToProps,
)(SnapshotsTable);


export default withStyles(styles)(ConnectedSnapshotsTable);
