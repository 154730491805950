import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import App from 'modules/App';
import * as constants from '../../../../../constants';
import messages from '../../../../../messages';
import AddPayerForm from './AddPayerForm';


class AddPayerModal extends React.PureComponent {

  static propTypes = {
    onAddPayer  : PropTypes.func,
    // Explicit props
    isInProgress: PropTypes.bool,
    hasErrors   : PropTypes.bool,
    payers      : PropTypes.array,
    // Implicit props
    openModalId : PropTypes.string,
    // Implicit actions
    onClose     : PropTypes.func,
  };


  componentDidUpdate(prevProps) {
    if (prevProps.isInProgress && !this.props.isInProgress && !this.props.hasErrors) {
      this.props.onClose();
    }
  }


  renderContent() {
    return (
      <AddPayerForm
        isInProgress={this.props.isInProgress}
        payers={this.props.payers}
        hasErrors={this.props.hasErrors}
        onClose={this.props.onClose}
        onSubmit={(name) => this.props.onAddPayer(name)}
      />
    );
  }


  render() {
    return (
      <Modal
        modalId={constants.ADD_PAYER_MODAL}
        openModalId={this.props.openModalId}
        headerMessage={messages.headers.addNewPayer}
        styleModifier="md"
        isInProgress={this.props.isInProgress}
        onClose={this.props.onClose}
      >
        { this.renderContent() }
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  openModalId: App.selectors.modal(state),
});


const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(App.actions.closeModal()),
});


const ConnectedAddPayerModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddPayerModal);


export default ConnectedAddPayerModal;
