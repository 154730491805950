import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Button from 'components/Form/Button';
import LoadingBanner from 'components/LoadingBanner';
import InfoMessage from 'components/InfoMessage';
import App from 'modules/App';
import StatusChip from '../../../../partials/StatusChip';
import messages from '../../../../messages';
import * as selectors from '../../../../selectors';
import * as actions from '../../../../actions';
import RegionStatusCollapsibleSection from './RegionStatusCollapsibleSection';
import styles from './Publish.pcss';


class Publish extends React.Component {

  static propTypes = {
    // Explicit props
    // Implicit props
    lastPublish                            : PropTypes.object,
    cancelPublishReasons                   : PropTypes.array,
    isLastPublishRelatedToPublishingVersion: PropTypes.bool,
    hasInitializePublishVersionErrors      : PropTypes.bool,
    // Implicit actions
    onClose                                : PropTypes.func,
    onInitializePublishVersion             : PropTypes.func,
    onFetchLastPublish                     : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.props.onInitializePublishVersion();
    this.state = {
      intervalId: null,
    };
  }

  componentDidMount() {
    const intervalId = setInterval(() => {
      this.props.onFetchLastPublish();
    }, 35000);
    this.setState({ intervalId });
  }


  componentWillUnmount() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  }


  get content() {
    if (this.props.cancelPublishReasons) { return (this.renderCancelPublishReasons()); }
    if (this.props.isLastPublishRelatedToPublishingVersion) { return (this.renderPublishLogs()); }
    if (this.props.hasInitializePublishVersionErrors) return null;
    return (
      <LoadingBanner labelMessage={messages.infos.initializePublishNewVersion} />
    );

  }


  get isPublishInProgress() {
    if (this.props.cancelPublishReasons) return false;
    if (this.props.hasInitializePublishVersionErrors) return false;
    if (!this.props.isLastPublishRelatedToPublishingVersion) return true;
    return this.props.lastPublish.isProcessing;
  }


  renderFinishButton() {
    return (
      <div>
        <Button
          type="button"
          styleModifier="primary"
          labelMessage={App.messages.buttons.finish}
          isDisabled={this.isPublishInProgress}
          isInProgress={this.isPublishInProgress}
          className="btn btn--filled"
          onClick={() => this.props.onClose()}
        />
      </div>

    );
  }


  renderRegions() {
    return (
      <>
        {this.props.lastPublish.regionLogs
          .map((regionLog) => (
            <RegionStatusCollapsibleSection
              key={`regionLog-${regionLog.region}`}
              className={styles.publish__content__regionStatusCollapsibleSection}
              regionLog={regionLog}
            />
          ))
        }
      </>
    );
  }


  renderPublishingStatus() {
    const { status } = this.props.lastPublish;
    return (
      <div className="d-flex align-items-center">
        <p className="mr-9"><FormattedMessage {...messages.headers.publishingStatus} /></p>
        <div className="ml-1">
          <StatusChip
            status={status}
          />
        </div>
      </div>
    );
  }


  renderCancelPublishReasons() {
    return (
      <div className={styles.publish__content__cancelPublishReasons}>
        {this.props.cancelPublishReasons
          .map((cancelPublishReason) => this.renderCancelPublishReason(cancelPublishReason))}
      </div>
    );
  }


  renderCancelPublishReason(cancelPublishReason) {
    return (
      <InfoMessage
        type="error"
        className="mb-6 align-items-center pr-9 pl-9"
        key={cancelPublishReason.reason}
      >
        <>
          <p><b><FormattedMessage {...messages.cancelReasons[cancelPublishReason.reason]} /></b></p>
          {this.renderCancelPublishReasonDetails(cancelPublishReason)}
        </>
      </InfoMessage>
    );
  }


  renderCancelPublishReasonDetails(cancelPublishReason) {
    if (!cancelPublishReason.data) return null;

    return cancelPublishReason.data.map((details) => (
      <li key={details}>
        <FormattedMessage
          {...messages.cancelReasons.details[cancelPublishReason.reason]}
          values={{
            component: details,
          }}
        />
      </li>
    ));
  }

  renderPublishLogs() {
    return (
      <div className={styles.publish__content_logs}>
        {this.renderPublishingStatus()}
        {this.renderRegions()}
      </div>
    );
  }

  renderContent() {
    return (
      <div className={styles.publish__content}>
        {this.content}
      </div>
    );
  }

  render() {
    return (
      <div className="d-flex flex-column h-100 justify-content-between">
        {this.renderContent()}
        {this.renderFinishButton()}
      </div>
    );
  }

}


const mapStateToProps = (state) => (
  {
    lastPublish                            : selectors.lastPublish(state),
    isLastPublishRelatedToPublishingVersion: selectors.isLastPublishRelatedToPublishingVersion(state),
    hasInitializePublishVersionErrors      : selectors.hasInitializePublishVersionErrors(state),
    cancelPublishReasons                   : selectors.cancelPublishReasons(state),
  });


const mapDispatchToProps = (dispatch) => ({
  onInitializePublishVersion: () => dispatch(actions.initializePublishVersion()),
  onFetchLastPublish        : () => dispatch(actions.fetchLastPublish()),

});


const ConnectedPublish = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Publish);


export default withStyles(styles)(ConnectedPublish);
