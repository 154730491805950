exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1uEzO{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;gap:32px}._1uEzO .modal__contentWrapper{margin-bottom:30px}._1uEzO .modal__content{padding:0 3.2rem}._298s2{gap:172px;gap:17.2rem}._298s2,._298s2 ._2nw2F{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center}._298s2 ._2nw2F{gap:8px;gap:.8rem}._298s2 ._3Ixif{font-weight:700;font-size:12px;line-height:20px}._298s2 ._2_5Yl{margin:0 0 0 auto;width:92px}", ""]);

// exports
exports.locals = {
	"publishSnapshot": "_1uEzO",
	"header": "_298s2",
	"header__publishStatus": "_2nw2F",
	"header__publishSnapshotStatus": "_3Ixif",
	"header__publishButton": "_2_5Yl"
};