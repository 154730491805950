import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import App from 'modules/App';
import * as constants from '../../../constants';
import messages from '../../../messages';
import UpdateOrAddApproverForm from './UpdateOrAddApproverForm';


class AddApproverModal extends React.PureComponent {

  static propTypes = {
    // Explicit props
    languageOptions: PropTypes.array,
    countries      : PropTypes.array,
    approver       : PropTypes.object,
    isInProgress   : PropTypes.bool,
    hasErrors      : PropTypes.bool,
    // Implicit props
    openModalId    : PropTypes.string,
    // Implicit actions
    onClose        : PropTypes.func,

  };


  componentDidUpdate(prevProps) {
    if (prevProps.isInProgress && !this.props.isInProgress && !this.props.hasErrors) {
      this.props.onClose();
    }
  }


  get isUpdate() {
    return !!this.props.approver;
  }


  renderContent() {
    return (
      <UpdateOrAddApproverForm
        languageOptions={this.props.languageOptions}
        countries={this.props.countries}
        approver={this.props.approver}
        isInProgress={this.props.isInProgress}
        hasErrors={this.props.hasErrors}
        onClose={this.props.onClose}
      />
    );
  }


  render() {
    return (
      <Modal
        modalId={constants.UPDATE_OR_ADD_ORGANIZATION_APPROVER_MODAL}
        openModalId={this.props.openModalId}
        headerMessage={this.isUpdate ? messages.headers.editApprover : messages.headers.addNewApprover}
        styleModifier="lg"
        isInProgress={this.props.isInProgress}
        onClose={this.props.onClose}
      >
        {this.renderContent()}
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  openModalId: App.selectors.modal(state),
});


const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(App.actions.closeModal()),
});


const ConnectedAddApproverModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddApproverModal);


export default ConnectedAddApproverModal;
