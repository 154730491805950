import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import get from 'lodash/get';
import set from 'lodash/set';
import { filter, includes, unset } from 'lodash';
import Validator from 'libs/Validator';
import Form from 'components/Form';
import FormGroup from 'components/Form/FormGroup';
import CheckboxRadio from 'components/Form/CheckboxRadio';
import Input from 'components/Form/Input';
import Button from 'components/Form/Button';
import FormContainerAbstract from 'components/FormContainerAbstract';
import App from 'modules/App';
import Account from 'modules/Account';
import { SCOPE_NAMES } from 'modules/Account/constants';
import messages from '../../../../../../messages';
import * as constants from '../../../../../../constants';
import validatorRules from './validatorRules.json';


class UpdateOrAddIdentifierOptionForm extends FormContainerAbstract {

  static propTypes = {
    ...FormContainerAbstract.propTypes,
    // Explicit actions
    onSubmit: PropTypes.func,
    onClose : PropTypes.func,
    isNew   : PropTypes.bool,
    // Implicit props
  };


  constructor(props) {
    super(props);
    this.validatorRules = { ...validatorRules };
    this.state = {
      isInProgress: false,
    };
    this.existingTermKeys = this.props.existingTermKeys;
    const defaultValue = {
      identifierNames: [],
    };
    props.onSetFormValues({ ...defaultValue, ...props.initialFormValue });
  }


  get assignmentIds() {
    return [constants.IDENTIFIER_TERM_TYPES.PERSONAL,
      constants.IDENTIFIER_TERM_TYPES.LICENCE,
      constants.IDENTIFIER_TERM_TYPES.ORGANIZATION];
  }


  get identifierNames() {
    return get(this.props.formValues, 'values.identifierNames', []);
  }


  onValidate(rules) {
    const values = get(this.props.formValues, 'values', {});
    const { errors } = Validator.run(values, rules);

    if (!this.props.isNew
        || (get(errors, 'termKey.alreadyExist', false)
        && !includes(this.existingTermKeys, values.termKey))
    ) {
      unset(errors, 'termKey.alreadyExist');
      unset(errors, 'termKey');
    }
    if (!get(values, 'slaveRegExp')) {
      set(values, 'slaveRegExp', '');
    }
    return {
      validatedValues: values,
      errors         : !errors || Object.keys(errors).length ? errors : null,
    };
  }


  onSetIdentifierNames(assignmentId) {
    let values = this.identifierNames;
    if (values.includes(assignmentId)) {
      values = filter(values, (value) => value !== assignmentId);
    } else {
      values = [...values, assignmentId];
    }
    this.props.onSetFormValue({
      id   : 'identifierNames',
      value: values,
    });
  }


  renderHeader() {
    const info = this.props.isNew ? messages.infos.addNewIdentifierOption : messages.infos.updateIdentifierOption;
    return (
      <div>
        <p className="mb-6"><FormattedMessage {...info} /></p>
      </div>
    );
  }


  renderActions() {
    const confirmLabel = this.props.isNew ? messages.buttons.addNew : messages.buttons.update;
    return (
      <div className="row">
        <div className="col-6">
          <Button
            type="reset"
            styleModifier="primary"
            labelMessage={App.messages.buttons.cancel}
            className="btn--block"
            onClick={this.props.onClose}
          />
        </div>
        <div className="col-6">
          <Button
            type="submit"
            styleModifier="primary"
            labelMessage={confirmLabel}
            className="btn--block btn--filled"
            isDisabled={this.isDisabled}
            isInProgress={this.state.isInProgress}
          />
        </div>
      </div>
    );
  }


  renderForm() {
    return (
      <Form
        onSubmit={() => this.onSubmit()}
      >
        <div className="row mb-8">
          <FormGroup
            id="termKey"
            className="col-12"
            labelMessage={messages.identifierTab.labels.termKey}
            formValues={this.props.formValues}
          >
            <Input
              isDisabled={!this.props.isNew}
              onChange={(input) => this.onSetValue(input)}
            />
          </FormGroup>
        </div>
        <div className="row mb-8">
          <FormGroup
            id="labelValue"
            className="col-12"
            labelMessage={messages.identifierTab.labels.name}
            formValues={this.props.formValues}
          >
            <Input
              onChange={(input) => this.onSetValue(input)}
            />
          </FormGroup>
        </div>
        <div className="row mb-8">
          <FormGroup
            id="instructionsValue"
            className="col-12"
            labelMessage={messages.identifierTab.labels.validationRule}
            formValues={this.props.formValues}
          >
            <Input
              onChange={(input) => this.onSetValue(input)}
            />
          </FormGroup>
        </div>
        {
          this.props.isSDNAdmin
          && (
          <div className="row mb-8">
            <FormGroup
              id="slaveRegExp"
              className="col-12"
              labelMessage={messages.identifierTab.labels.regExp}
              formValues={this.props.formValues}
            >
              <Input
                onChange={(input) => this.onSetValue(input)}
              />
            </FormGroup>
          </div>
          )
        }
        {this.renderTermAssignment()}
        { this.renderActions() }
      </Form>
    );
  }


  renderTermAssignment() {
    return (
      <div className="d-flex">
        {this.assignmentIds.map((assignmentId) => (this.renderCheck(assignmentId)))}
      </div>

    );
  }


  renderCheck(termKey) {
    return (
      <CheckboxRadio
        className="mr-4"
        key={`createIdentifier-${termKey}`}
        id={`createIdentifier-${termKey}`}
        inputValue="true"
        labelMessage={messages.identifierTab.labels[termKey]}
        onChange={() => this.onSetIdentifierNames(termKey)}
        value={includes(this.identifierNames, termKey) ? 'true' : 'false'}
      />
    );
  }


  render() {
    return (
      <div>
        {this.renderHeader()}
        {this.renderForm()}
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  formValues: App.selectors.formSelector(constants.ADD_IDENTIFIER_OPTION_FORM)(state),
  isSDNAdmin: Account.selectors.scope(state) === SCOPE_NAMES.SDN_ADMIN,
});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.ADD_IDENTIFIER_OPTION_FORM;
  return {
    onSetFormValue  : (input) => dispatch(App.actions.setFormValue({ formName, input })),
    onSetFormValues : (values) => dispatch(App.actions.setFormValues({ formName, values })),
    onFormErrors    : (errors) => dispatch(App.actions.setFormErrors({ formName, errors })),
    onFormProcessing: () => dispatch(App.actions.startFormProcessing(formName)),
    onClearForm     : () => dispatch(App.actions.clearForm(formName)),
  };
};


const ConnectedUpdateOrAddIdentifierOptionForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateOrAddIdentifierOptionForm);


export default ConnectedUpdateOrAddIdentifierOptionForm;
