import React from 'react';
import withStyles from 'isomorphic-style-loader/withStyles';
import ReactTooltip from 'react-tooltip';
import omit from 'lodash/omit';
import isEqual from 'lodash/isEqual';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl, FormattedMessage } from 'react-intl';
import filter from 'lodash/filter';
import get from 'lodash/get';
import has from 'lodash/has';
import includes from 'lodash/includes';
import lowerCase from 'lodash/lowerCase';
// eslint-disable-next-line import/no-unresolved
import InfoIcon from 'svg/info.svg';
import App from 'modules/App';
import Form from 'components/Form';
import FormGroup from 'components/Form/FormGroup';
import Input from 'components/Form/Input';
import Button from 'components/Form/Button';
import Select from 'components/Form/Select';
import FormContainerAbstract from 'components/FormContainerAbstract';
import intlShape from 'shapes/intlShape';
import * as actions from '../../../actions';
import messages from '../../../messages';
import * as selectors from '../../../selectors';
import * as constants from '../../../constants';
import { getApproverCountriesCodesByUserScope, hasAssignedCountriesOutOfUserScope } from '../managedCountriesHelper';
import validatorRules from './validatorRules.json';
import styles from './UpdateOrAddApproverForm.pcss';


class UpdateOrAddApproverForm extends FormContainerAbstract {

  static propTypes = {
    ...FormContainerAbstract.propTypes,
    // Explicit props
    languageOptions: PropTypes.array,
    countries      : PropTypes.array,
    approver       : PropTypes.object,
    isInProgress   : PropTypes.bool,
    // Explicit actions
    onClose        : PropTypes.func,
    // Implicit props
    intl           : intlShape.isRequired,
  };


  constructor(props) {
    super(props);
    this.validatorRules = { ...validatorRules };
    const { approver, countries } = this.props;
    if (approver) {
      const userScopeCountriesCodes
         = getApproverCountriesCodesByUserScope(approver.countries, countries);
      const values = {
        ...omit(approver, ['countries']),
        countryCodes: userScopeCountriesCodes,
      };
      this.onSetValues(values);
      this.state = {
        formInitialState: JSON.parse(JSON.stringify(values)),
      };
    }

  }


  get isUpdate() {
    return !!this.props.approver;
  }


  get isSubmitDisabled() {
    if (this.isUpdate) {
      const values = get(this.props.formValues, 'values');
      return this.isDisabled || isEqual(this.state.formInitialState, values);
    }
    return this.isDisabled;
  }


  get languageOptions() {
    return this.props.languageOptions.sort((a, b) => {
      if (lowerCase(a.englishName) > lowerCase(b.englishName)) { return 1; }
      return -1;
    }
    );
  }

  get countryOptions() {
    return this.props.countries.sort((a, b) => {
      if (lowerCase(a.englishName) > lowerCase(b.englishName)) { return 1; }
      return -1;
    }
    );
  }

  get requiredRules() {
    const rules = super.requiredRules;
    if (!this.isUpdate) return rules;
    const { approver, countries } = this.props;

    if (hasAssignedCountriesOutOfUserScope(approver.countries, countries)) {
      return omit(rules, 'countryCodes');
    }
    return rules;
  }

  onValidate(rules, isToUnfold = false) {
    const validateResults = super.onValidate(rules, isToUnfold);
    if (!this.isUpdate) return validateResults;

    const { approver, countries } = this.props;
    if (validateResults.errors !== null && has(validateResults.errors, 'countryCodes')
    && hasAssignedCountriesOutOfUserScope(approver.countries, countries)) {
      validateResults.errors = omit(validateResults.errors, 'countryCodes');
      if (!Object.keys(validateResults.errors).length) validateResults.errors = null;
      return validateResults;
    }
    return validateResults;
  }


  renderInfo() {
    const header = this.isUpdate ? messages.infos.editApprover : messages.infos.addNewApprover;
    return (
      <div>
        <p className="mb-8 mt-6"><FormattedMessage {...header} /></p>
      </div>
    );
  }


  onSetManagedCountries({ id, value }) {
    const countries = get(this.props.formValues, 'values.countryCodes', []);

    const countriesInput = {
      id,
      value: includes(countries, value)
        ? filter(countries, (country) => country !== value)
        : [...countries, value],
    };
    this.onSetValue(countriesInput);
  }


  onSubmit(isClearForm = false) {
    this.props.onFormProcessing();
    const { validatedValues, errors } = this.onValidate(this.validatorRules);
    this.props.onFormErrors(errors);
    if (!errors) {
      if (this.isUpdate) {
        this.props.onSubmitUpdate(validatedValues);
      } else {
        this.props.onSubmitAdd(validatedValues);
      }
      if (this.props.onClearForm && isClearForm) this.props.onClearForm();
    }
  }


  renderActions() {
    const confirm = this.isUpdate ? messages.buttons.saveChanges : messages.buttons.addApprover;
    return (
      <div className="row">
        <div className="col-6">
          <Button
            type="reset"
            styleModifier="primary"
            labelMessage={App.messages.buttons.cancel}
            className="btn--block"
            isInProgress={this.props.isInProgress}
            onClick={this.props.onClose}
          />
        </div>
        <div className="col-6">
          <Button
            type="submit"
            styleModifier="primary"
            labelMessage={confirm}
            className="btn--block btn--filled"
            isDisabled={this.isSubmitDisabled}
            isInProgress={this.props.isInProgress}
          />
        </div>
      </div>
    );
  }


  renderForm() {
    const managedCountriesInfo = this.props.intl.formatMessage(messages.infos.managedCountries);
    return (
      <Form
        className={styles.addApproverForm}
        onSubmit={() => this.onSubmit()}
      >
        <div className="row mb-4">
          <FormGroup
            id="firstName"
            className="col-6"
            labelMessage={messages.labels.firstName}
            formValues={this.props.formValues}
          >
            <Input
              placeholder={messages.placeholders.firstName}
              onChange={(input) => this.onSetValue(input)}
            />
          </FormGroup>
          <FormGroup
            id="lastName"
            className="col-6"
            labelMessage={messages.labels.lastName}
            formValues={this.props.formValues}
          >
            <Input
              placeholder={messages.placeholders.lastName}
              onChange={(input) => this.onSetValue(input)}
            />
          </FormGroup>
        </div>
        <div className="row mb-4">
          <FormGroup
            id="email"
            className="col-12"
            labelMessage={messages.labels.email}
            formValues={this.props.formValues}
          >
            <Input
              placeholder={messages.placeholders.countryName}
              onChange={(input) => this.onSetValue(input)}
              isDisabled={this.isUpdate}

            />
          </FormGroup>
        </div>
        <div className="row mb-4">
          <FormGroup
            id="languageCode"
            className="col-12"
            labelMessage={messages.labels.language}
            formValues={this.props.formValues}
          >
            <Select
              optionsFrom={this.languageOptions}
              valueKey="code"
              labelKey="englishName"
              noValueMessage={messages.placeholders.language}
              onChange={(input) => this.onSetValue(input)}
            />
          </FormGroup>
        </div>
        <div className="row">
          <FormGroup
            id="countryCodes"
            className="col-12"
            labelMessage={(
              <div className="d-flex align-items-center">
                <span><FormattedMessage {...messages.labels.managedCountries} /></span>
                <InfoIcon
                  className={styles.managedCountries__InfoIcon}
                  data-tip={managedCountriesInfo}
                  data-for="managedCountries-info"
                />
                {this.renderTooltip()}

              </div>
)}
            formValues={this.props.formValues}
          >
            <Select
              optionsFrom={this.countryOptions}
              valueKey="code"
              labelKey="englishName"
              noValueMessage={messages.placeholders.managedCountries}
              onChange={(input) => this.onSetManagedCountries(input)}
              multi
            />
          </FormGroup>
        </div>
        { this.renderActions() }
      </Form>
    );
  }

  renderTooltip() {
    return (
      <ReactTooltip
        id="managedCountries-info"
        className="tooltip__wrapper"
        place="right"
        type="dark"
        multiline
      />
    );
  }

  render() {
    return (
      <div>
        {this.renderInfo()}
        {this.renderForm()}
      </div>
    );
  }

}


const mapStateToProps = (state) => ({
  formValues  : App.selectors.formSelector(constants.UPDATE_OR_ADD_ORGANIZATION_APPROVER_FORM)(state),
  isInProgress: selectors.isUpdateApproverProgress(state) || selectors.isAddApproverProgress(state),
});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.UPDATE_OR_ADD_ORGANIZATION_APPROVER_FORM;
  return {
    onSubmitUpdate  : (values) => dispatch(actions.updateApprover(values)),
    onSubmitAdd     : (values) => dispatch(actions.addApprover(values)),
    onSetFormValue  : (input) => dispatch(App.actions.setFormValue({ formName, input })),
    onSetFormValues : (values) => dispatch(App.actions.setFormValues({ formName, values })),
    onFormErrors    : (errors) => dispatch(App.actions.setFormErrors({ formName, errors })),
    onFormProcessing: () => dispatch(App.actions.startFormProcessing(formName)),
    onClearForm     : () => dispatch(App.actions.clearForm(formName)),
  };
};


const ConnectedUpdateOrAddApproverForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateOrAddApproverForm);


export default withStyles(styles)(injectIntl(ConnectedUpdateOrAddApproverForm));
