import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import isString from 'lodash/isString';
import CheckCircleIcon from 'svg/check-circle.svg';
import styles from './Statement.pcss';


class Statement extends React.PureComponent {

  static propTypes = {
    // Explicit props
    headerMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
    imgSrc       : PropTypes.string,
    withIcon     : PropTypes.oneOf(['success']), // error, loading
    isOnTop      : PropTypes.bool,
    children     : PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]).isRequired,
    boxClassName : PropTypes.string,
  };


  static defaultProps = {
    isOnTop: false,
  }


  get header() {
    const { headerMessage } = this.props;


    if (isString(headerMessage)) return <h2 className="text--h2">{ headerMessage}</h2>;
    if (React.isValidElement(headerMessage)) return headerMessage;
    return <h2 className="text--h2"><FormattedMessage {...headerMessage} /></h2>;
  }


  get icon() {
    switch (this.props.withIcon) {
      case 'success': {
        return <CheckCircleIcon className={styles.box__icon} />;
      }
      default: return null;
    }
  }


  renderHeader() {
    if (!this.props.headerMessage) {
      return null;
    }
    return (
      <header className={styles.box__header}>
        { this.header }
      </header>
    );
  }


  renderContent() {
    return (
      <div className="col">
        { this.renderHeader() }
        <div className="text--paragraph">
          { this.props.children }
        </div>
      </div>
    );
  }


  renderImage() {
    if (!this.props.imgSrc) {
      return null;
    }
    return (
      <div className="col-auto">
        <img src={this.props.imgSrc} className={`${styles.box__image} mr-7`} alt="" />
      </div>
    );
  }


  render() {
    const Icon = this.props.withIcon && this.icon;
    const classNameValue = this.props.boxClassName || '';

    return (
      <div
        className={cn(styles.wrapper,
          { [styles['wrapper--center']]: !this.props.isOnTop },
          {
            'align-items-center': !this.props.isOnTop,
            'align-items-start' : this.props.isOnTop,
          })}
      >
        <div className={cn(classNameValue, styles.box)}>
          <div className="row no-gutters">
            { (Icon) || this.renderImage() }
            { this.renderContent() }
            { (Icon) && this.renderImage() }
          </div>
        </div>
      </div>
    );
  }

}


export default withStyles(styles)(Statement);
