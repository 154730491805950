exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".-EUFF{display:-ms-flexbox;display:flex;-ms-flex-flow:row wrap;flex-flow:row wrap;-ms-flex-align:baseline;align-items:baseline}.-EUFF .row{-ms-flex-preferred-size:100%;flex-basis:100%;-ms-flex-align:baseline;align-items:baseline;margin-right:0;margin-left:0;-webkit-column-gap:30px;-moz-column-gap:30px;column-gap:30px}.-EUFF .col,.-EUFF .col-6,.-EUFF .col-12{padding-right:0;padding-left:0}.-EUFF .col-6{-ms-flex-preferred-size:calc(50% - 15px);flex-basis:calc(50% - 15px)}", ""]);

// exports
exports.locals = {
	"addBLSRegionForm": "-EUFF"
};