import { createSelector } from 'reselect';
import includes from 'lodash/includes';
import get from 'lodash/get';
import * as AppActionTypes from 'modules/App/actionTypes';
import * as constants from './constants';
import * as actionTypes from './actionTypes';

/**
 * Input selectors
 */
const mainSelector = (state) => state[constants.MODULE_ID];
const fetchingSelector = (state) => state[constants.MODULE_ID].fetching;
const errorsSelector = (state) => state[constants.MODULE_ID].errors;
const sendingSelector = (state) => state[constants.MODULE_ID].sending;

export const publishVersionsPageTotal = createSelector(
  mainSelector,
  (state) => state.publishVersionsPageTotal,
);

export const publishVersionsPage = createSelector(
  mainSelector,
  (state) => state.publishVersionsPage,
);

export const isFetchPublishVersionsPageInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_PUBLISH_VERSIONS_PAGE),
);

export const isDownloadPublishVersionDiffsProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.DOWNLOAD_PUBLISH_VERSION_DIFFS),
);

export const isFetchPublishVersionDiffsProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_PUBLISH_VERSION_DIFFS),
);

export const hasFetchPublishVersionDiffsErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.FETCH_PUBLISH_VERSION_DIFFS),
);

export const publishDiffs = createSelector(
  mainSelector,
  (state) => state.publishDiffs,
);

export const isFetchPublishVersionCommitsProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_PUBLISH_VERSION_COMMITS),
);

export const hasFetchPublishVersionCommitsErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.FETCH_PUBLISH_VERSION_COMMITS),
);

export const publishCommits = createSelector(
  mainSelector,
  (state) => state.publishCommits,
);

export const isValidatePublishVersionProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.VALIDATE_PUBLISH_VERSION),
);

export const hasValidatePublishVersionErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.VALIDATE_PUBLISH_VERSION),
);


export const validatePublishResult = createSelector(
  mainSelector,
  (state) => state.validatePublishResult,
);

export const newPublishVersion = createSelector(
  mainSelector,
  (state) => state.newPublishVersion,
);

export const isAddNewPublishVersionProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.ADD_NEW_PUBLISH_VERSION),
);

export const hasValidatePublishResultAnyError = createSelector(
  mainSelector,
  (state) => {
    const missingTerms = get(state.validatePublishResult, 'missingTerms', []);
    const missingTranslations = get(state.validatePublishResult, 'missingTranslations', []);
    const missingRegexps = get(state.validatePublishResult, 'missingRegexps', []);
    return !!(missingTerms.length
    || missingTranslations.length
    || missingRegexps.length);
  },
);

export const publishingStatus = createSelector(
  mainSelector,
  (state) => state.publishingStatus,
);

export const isSetPublishingProgressSending = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.SET_PUBLISHING_PROGRESS),
);

export const lastPublish = createSelector(
  mainSelector,
  (state) => state.lastPublish,
);

export const lastPublishStatus = createSelector(
  mainSelector,
  (state) => state.lastPublish && state.lastPublish.status,
);


export const isLastPublishRelatedToPublishingVersion = createSelector(
  mainSelector,
  (state) => {
    if (!state.lastPublish || !state.lastPublish.version || !state.newPublishVersion) return false;
    return state.newPublishVersion === state.lastPublish.version;
  },
);

export const isFetchLastPublishProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_LAST_PUBLISH),
);

export const hasInitializePublishVersionErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.INITIALIZE_PUBLISH_VERSION),
);

export const cancelPublishReasons = createSelector(
  mainSelector,
  (state) => state.cancelPublishReasons,
);

export const isUpdatePublishSnapshotNotificationInProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, AppActionTypes.UPDATE_PUBLISH_SNAPSHOTS),
);
