import {
  registerAction,
  unregisterAction,
} from 'helpers/reducerTools';
import { SIGN_OUT_SUCCESS } from 'modules/Account/actionTypes';
import * as AppActionTypes from 'modules/App/actionTypes';
import * as actionTypes from './actionTypes';


const initialState = {
  publishVersionsPage     : null,
  publishVersionsPageTotal: null,
  publishCommits          : undefined,
  publishDiffs            : undefined,
  validatePublishResult   : undefined,
  publishingStatus        : null,
  newPublishVersion       : null,
  lastPublish             : null,
  cancelPublishReasons    : null,
  fetching                : [],
  sending                 : [],
  errors                  : [],
};


export default function reducer(state = { ...initialState }, action) {

  switch (action.type) {

    case actionTypes.FETCH_PUBLISH_VERSIONS_PAGE: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_PUBLISH_VERSIONS_PAGE),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_PUBLISH_VERSIONS_PAGE),
      };
    }
    case actionTypes.FETCH_PUBLISH_VERSIONS_PAGE_SUCCESS: {
      const { totalRecords, records } = action.payload;
      return {
        ...state,
        publishVersionsPage     : records,
        publishVersionsPageTotal: totalRecords,
        fetching                : unregisterAction(state.fetching, actionTypes.FETCH_PUBLISH_VERSIONS_PAGE),
      };
    }
    case actionTypes.FETCH_PUBLISH_VERSIONS_PAGE_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_PUBLISH_VERSIONS_PAGE),
        errors  : registerAction(state.errors, actionTypes.FETCH_PUBLISH_VERSIONS_PAGE),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.DOWNLOAD_PUBLISH_VERSION_DIFFS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.DOWNLOAD_PUBLISH_VERSION_DIFFS),
        errors  : unregisterAction(state.errors, actionTypes.DOWNLOAD_PUBLISH_VERSION_DIFFS),
      };
    }
    case actionTypes.DOWNLOAD_PUBLISH_VERSION_DIFFS_SUCCESS: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.DOWNLOAD_PUBLISH_VERSION_DIFFS),
      };
    }
    case actionTypes.DOWNLOAD_PUBLISH_VERSION_DIFFS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.DOWNLOAD_PUBLISH_VERSION_DIFFS),
        errors  : registerAction(state.errors, actionTypes.DOWNLOAD_PUBLISH_VERSION_DIFFS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_PUBLISH_VERSION_COMMITS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_PUBLISH_VERSION_COMMITS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_PUBLISH_VERSION_COMMITS),
      };
    }
    case actionTypes.FETCH_PUBLISH_VERSION_COMMITS_SUCCESS: {
      const { publishCommits } = action.payload;
      return {
        ...state,
        publishCommits,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_PUBLISH_VERSION_COMMITS),
      };
    }
    case actionTypes.FETCH_PUBLISH_VERSION_COMMITS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_PUBLISH_VERSION_COMMITS),
        errors  : registerAction(state.errors, actionTypes.FETCH_PUBLISH_VERSION_COMMITS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_PUBLISH_VERSION_DIFFS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_PUBLISH_VERSION_DIFFS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_PUBLISH_VERSION_DIFFS),
      };
    }
    case actionTypes.FETCH_PUBLISH_VERSION_DIFFS_SUCCESS: {
      const { publishDiffs } = action.payload;
      return {
        ...state,
        publishDiffs,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_PUBLISH_VERSION_DIFFS),
      };
    }
    case actionTypes.FETCH_PUBLISH_VERSION_DIFFS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_PUBLISH_VERSION_DIFFS),
        errors  : registerAction(state.errors, actionTypes.FETCH_PUBLISH_VERSION_DIFFS),
      };
    }

    case actionTypes.SNAPSHOT_DETAILS_FINISH: {
      return {
        ...state,
        publishCommits: undefined,
        publishDiffs  : undefined,
        fetching      : unregisterAction(state.fetching,
          actionTypes.FETCH_PUBLISH_VERSION_DIFFS,
          actionTypes.FETCH_PUBLISH_VERSION_COMMITS),
        errors: unregisterAction(state.errors,
          actionTypes.FETCH_PUBLISH_VERSION_DIFFS,
          actionTypes.FETCH_PUBLISH_VERSION_COMMITS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.VALIDATE_PUBLISH_VERSION: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.VALIDATE_PUBLISH_VERSION),
        errors : unregisterAction(state.errors, actionTypes.VALIDATE_PUBLISH_VERSION),
      };
    }
    case actionTypes.VALIDATE_PUBLISH_VERSION_SUCCESS: {
      const { validatePublishResult } = action.payload;
      return {
        ...state,
        validatePublishResult,
        cancelPublishReasons: null,
        sending             : unregisterAction(state.sending, actionTypes.VALIDATE_PUBLISH_VERSION),
      };
    }
    case actionTypes.VALIDATE_PUBLISH_VERSION_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.VALIDATE_PUBLISH_VERSION),
        errors : registerAction(state.errors, actionTypes.VALIDATE_PUBLISH_VERSION),
      };
    }

    case actionTypes.VALIDATE_PUBLISH_VERSION_FINISH: {
      return {
        ...state,
        publishCommits       : undefined,
        publishDiffs         : undefined,
        validatePublishResult: undefined,
        newPublishVersion    : null,
        fetching             : unregisterAction(state.fetching,
          actionTypes.FETCH_PUBLISH_VERSION_DIFFS,
          actionTypes.FETCH_PUBLISH_VERSION_COMMITS,
          actionTypes.VALIDATE_PUBLISH_VERSION
        ),
        errors: unregisterAction(state.errors,
          actionTypes.FETCH_PUBLISH_VERSION_DIFFS,
          actionTypes.FETCH_PUBLISH_VERSION_COMMITS,
          actionTypes.VALIDATE_PUBLISH_VERSION,
        ),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.SET_PUBLISHING_PROGRESS: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.SET_PUBLISHING_PROGRESS),
        errors : unregisterAction(state.errors, actionTypes.SET_PUBLISHING_PROGRESS),
      };
    }
    case actionTypes.SET_PUBLISHING_PROGRESS_SUCCESS: {
      const { publishingStatus } = action.payload;
      return {
        ...state,
        publishingStatus,
        sending: unregisterAction(state.sending, actionTypes.SET_PUBLISHING_PROGRESS),
      };
    }
    case actionTypes.SET_PUBLISHING_PROGRESS_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.SET_PUBLISHING_PROGRESS),
        errors : registerAction(state.errors, actionTypes.SET_PUBLISHING_PROGRESS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.ADD_NEW_PUBLISH_VERSION: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.ADD_NEW_PUBLISH_VERSION),
        errors : unregisterAction(state.errors, actionTypes.ADD_NEW_PUBLISH_VERSION),
      };
    }
    case actionTypes.ADD_NEW_PUBLISH_VERSION_SUCCESS: {
      const { newPublishVersion } = action.payload;
      return {
        ...state,
        newPublishVersion: newPublishVersion.version,
        sending          : unregisterAction(state.sending, actionTypes.ADD_NEW_PUBLISH_VERSION),
      };
    }
    case actionTypes.ADD_NEW_PUBLISH_VERSION_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.ADD_NEW_PUBLISH_VERSION),
        errors : registerAction(state.errors, actionTypes.ADD_NEW_PUBLISH_VERSION),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.INITIALIZE_PUBLISH_VERSION: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.INITIALIZE_PUBLISH_VERSION),
        errors : unregisterAction(state.errors, actionTypes.INITIALIZE_PUBLISH_VERSION),
      };
    }
    case actionTypes.INITIALIZE_PUBLISH_VERSION_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.INITIALIZE_PUBLISH_VERSION),
      };
    }
    case actionTypes.INITIALIZE_PUBLISH_VERSION_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.INITIALIZE_PUBLISH_VERSION),
        errors : registerAction(state.errors, actionTypes.INITIALIZE_PUBLISH_VERSION),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.FETCH_LAST_PUBLISH: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_LAST_PUBLISH),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_LAST_PUBLISH),
      };
    }
    case actionTypes.FETCH_LAST_PUBLISH_SUCCESS: {
      const { lastPublish } = action.payload;

      return {
        ...state,
        lastPublish,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_LAST_PUBLISH),
      };
    }
    case actionTypes.FETCH_LAST_PUBLISH_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_LAST_PUBLISH),
        errors  : registerAction(state.errors, actionTypes.FETCH_LAST_PUBLISH),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case AppActionTypes.UPDATE_PUBLISH_SNAPSHOTS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, AppActionTypes.UPDATE_PUBLISH_SNAPSHOTS),
        errors  : unregisterAction(state.errors, AppActionTypes.UPDATE_PUBLISH_SNAPSHOTS),
      };
    }

    case AppActionTypes.UPDATE_PUBLISH_SNAPSHOTS_SUCCESS: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, AppActionTypes.UPDATE_PUBLISH_SNAPSHOTS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case AppActionTypes.CANCEL_PUBLISH_PROGRESS: {
      return {
        ...state,
        cancelPublishReasons: action.payload,
      };
    }

    case AppActionTypes.UPDATE_PUBLISH_PROGRESS: {
      return {
        ...state,
        lastPublish: action.payload,
      };
    }

    case AppActionTypes.UPDATE_LAST_PUBLISH_PROGRESS: {
      const lastPublish = state.lastPublish || {};
      lastPublish.status = action.payload.status;
      return {
        ...state,
        lastPublish,
      };
    }
    //------------------------------------------------------------------------------------------------------------------

    case SIGN_OUT_SUCCESS: {
      return {
        ...initialState,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    default:
      return state;

  }
}
