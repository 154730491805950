import * as actionTypes from './actionTypes';


export const fetchOrganizationApprovers = (request) => ({
  type   : actionTypes.FETCH_ORGANIZATION_APPROVERS,
  payload: {
    ...request,
  },
});


export const fetchOrganizationApproversSuccess = (organizationApproversPage) => ({
  type   : actionTypes.FETCH_ORGANIZATION_APPROVERS_SUCCESS,
  payload: {
    organizationApproversPage,
  },
});


export const fetchOrganizationApproversError = (error) => ({
  type: actionTypes.FETCH_ORGANIZATION_APPROVERS_ERROR,
  error,
});


export const addApprover = (approver) => ({
  type   : actionTypes.ADD_APPROVER,
  payload: {
    approver,
  },
});


export const addApproverSuccess = () => ({
  type   : actionTypes.ADD_APPROVER_SUCCESS,
  payload: {

  },
});


export const addApproverError = (error) => ({
  type: actionTypes.ADD_APPROVER_ERROR,
  error,
});

export const updateApprover = (approver) => ({
  type   : actionTypes.UPDATE_APPROVER,
  payload: {
    approver,
  },
});


export const updateApproverSuccess = () => ({
  type   : actionTypes.UPDATE_APPROVER_SUCCESS,
  payload: {

  },
});


export const updateApproverError = (error) => ({
  type: actionTypes.UPDATE_APPROVER_ERROR,
  error,
});


export const deleteApprover = (email) => ({
  type   : actionTypes.DELETE_APPROVER,
  payload: {
    email,
  },
});


export const deleteApproverSuccess = () => ({
  type   : actionTypes.DELETE_APPROVER_SUCCESS,
  payload: {

  },
});


export const deleteApproverError = (error) => ({
  type: actionTypes.DELETE_APPROVER_ERROR,
  error,
});
