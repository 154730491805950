exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "@-webkit-keyframes _3LMtS{5%{opacity:1}50%{opacity:.35}95%{opacity:1}}@keyframes _3LMtS{5%{opacity:1}50%{opacity:.35}95%{opacity:1}}._1pKRO{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;-ms-flex-align:center;align-items:center;padding:3.2rem 16.3rem;gap:10px;gap:1rem;width:100%;border-radius:8px}.SADJK{background:#e0fff7}.SADJK p{color:#078285}._3CBsp{background:#feefef}._3CBsp p{color:#da1818}._3vgAW{background:#fdf9e6}._3vgAW p{color:#806b20}", ""]);

// exports
exports.locals = {
	"infoMessage": "_1pKRO",
	"infoMessage--success": "SADJK",
	"infoMessage--error": "_3CBsp",
	"infoMessage--pending": "_3vgAW",
	"fading": "_3LMtS"
};