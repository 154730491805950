import assign from 'lodash/assign';
import get from 'lodash/get';


/**
 * Local error
 *
 * @class LocalError
 * @param {Object} params The parameters of the error
 * @param {string} params.message General error message
 */
export function LocalError(params) {
  assign(this, params);
  this.getBusinessError = () => this;
}

/**
 * Api error
 *
 * @class ApiError
 * @param {Object} params The parameters of the error
 * @param {string} params.status Error status code
 * @param {string} params.error General error message
 * @param {Object[]} params.validationErrors Form validation errors
 */
export function ApiError(params) {
  assign(this, params);
  this.getBusinessError = () => get(this, 'businessError');
}
