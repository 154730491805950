import { call, put, takeLatest } from 'redux-saga/effects';
import forEach from 'lodash/forEach';
import ApiService from 'services/ApiService';
import App from 'modules/App';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import messages from './messages';


function* fetchOrganizationApprovers({ payload }) {
  try {
    const page = payload.page || 0;
    const perPage = payload.perPage || 10;
    const { term, countryCode } = payload;
    let requestUrl = `/api/OrganizationApprover?start=${page * perPage}&limit=${perPage}`;
    if (term) {
      requestUrl += `&term=${term}`;
    }
    if (countryCode && countryCode.length) {
      forEach(countryCode, (country) => {
        requestUrl += `&countryCodes=${country}`;
      });
    }
    const response = yield call(ApiService.originalRequest, requestUrl);
    yield put(actions.fetchOrganizationApproversSuccess(response));
  } catch (err) {
    yield put(actions.fetchOrganizationApproversError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//------------------------------------------------------------------------------------------------------------------

function* addApprover({ payload }) {
  try {
    const { approver } = payload;

    const requestUrl = '/api/OrganizationApprover';
    yield call(ApiService.originalRequest, requestUrl, {
      method: 'POST',
      body  : {
        ...approver,
      },
    }); const response = yield call(ApiService.originalRequest, requestUrl);
    yield put(actions.addApproverSuccess(response));
  } catch (err) {
    yield put(actions.addApproverError(err));
    yield call(App.dispatchError, err, messages);
  }
}


//------------------------------------------------------------------------------------------------------------------

function* updateApprover({ payload }) {
  try {
    const { approver } = payload;
    const requestUrl = '/api/OrganizationApprover';
    yield call(ApiService.originalRequest, requestUrl, {
      method: 'PUT',
      body  : {
        ...approver,
      },
    }); const response = yield call(ApiService.originalRequest, requestUrl);
    yield put(actions.updateApproverSuccess(response));
  } catch (err) {
    yield put(actions.updateApproverError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//------------------------------------------------------------------------------------------------------------------

function* deleteApprover({ payload }) {
  try {
    const { email } = payload;

    const requestUrl = '/api/OrganizationApprover';
    yield call(ApiService.originalRequest, requestUrl, {
      method: 'DELETE',
      body  : {
        email,
      },
    });
    const response = yield call(ApiService.originalRequest, requestUrl);
    yield put(actions.deleteApproverSuccess(response));
  } catch (err) {
    yield put(actions.deleteApproverError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------
/* eslint-disable func-names */
function* sagas() {
  yield takeLatest(actionTypes.FETCH_ORGANIZATION_APPROVERS, fetchOrganizationApprovers);
  yield takeLatest(actionTypes.ADD_APPROVER, addApprover);
  yield takeLatest(actionTypes.UPDATE_APPROVER, updateApprover);
  yield takeLatest(actionTypes.DELETE_APPROVER, deleteApprover);
}

export default [
  sagas,
];
