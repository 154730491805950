import forEach from 'lodash/forEach';
import omit from 'lodash/omit';
import get from 'lodash/get';


export default class ValidatorRulesExtension {

    static highThresholdName = 'highThreshold-toDisplay';

    static lowThresholdName = 'lowThreshold-toDisplay';


    static addDefaults(rules, formValues) {
      const rulesCopy = JSON.parse(JSON.stringify(rules));
      forEach(omit(formValues, ['default']), (range, type) => {
        forEach(range, (thresholds, thresholdsName) => {
          const highThresholdValue = thresholds['highThreshold-toDisplay'];
          const lowThresholdValue = thresholds['lowThreshold-toDisplay'];
          if (lowThresholdValue === null && highThresholdValue
            && get(formValues, `default.${thresholdsName}.lowThreshold-toDisplay`) !== null) {
            rulesCopy[`${type}.${thresholdsName}.highThreshold-toDisplay`]
              += `, greaterThen default.${thresholdsName}.lowThreshold-toDisplay`;
          }
          if (highThresholdValue === null && lowThresholdValue
            && get(formValues, `default.${thresholdsName}.lowThreshold-toDisplay`) !== null) {
            rulesCopy[`${type}.${thresholdsName}.lowThreshold-toDisplay`]
              += `, lowerThen default.${thresholdsName}.highThreshold-toDisplay`;
          }
        });

      }
      );
      return rulesCopy;
    }

}

