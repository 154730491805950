import { defineMessages } from 'react-intl';


export default defineMessages({
  headers: {
    loggingOutInProgress: { id: 'account.headers.loggingOutInProgress' },
    somethingWrong      : { id: 'account.headers.somethingWrong' },
    signIn              : { id: 'account.headers.signIn' },
    signOut             : { id: 'account.headers.signOut' },
    signUp              : { id: 'account.headers.signUp' },
    seeYouLater         : { id: 'account.headers.seeYouLater' },
    welcomeToBackOffice : { id: 'account.headers.welcomeToBackOffice' },

  },

  infos: {
    loggingOutSuccessful: { id: 'account.infos.loggingOutSuccessful' },
    profileSecure       : { id: 'account.infos.profileSecure' },
    tryLoggingOutAgain  : { id: 'account.infos.tryLoggingOutAgain' },
    welcomeToBackOffice : { id: 'account.infos.welcomeToBackOffice' },

  },

  errors: {
    businessErrors: {
    },
  },
});
