import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import forEach from 'lodash/forEach';
import keysIn from 'lodash/keysIn';
import isEmpty from 'lodash/isEmpty';
import startsWith from 'lodash/startsWith';
import { unfoldObject } from 'helpers/transformers';
import Validator from 'libs/Validator';
import App from 'modules/App';


class FormContainerAbstract extends React.PureComponent {

  static propTypes = {
    // Implicit props
    formValues       : PropTypes.object,
    isInProgress     : PropTypes.bool,
    hasErrors        : PropTypes.bool,
    // Implicit actions
    onSubmit         : PropTypes.func,
    onSetFormValue   : PropTypes.func.isRequired,
    onSetFormValues  : PropTypes.func,
    onUnsetFormValues: PropTypes.func,
    onFormProcessing : PropTypes.func.isRequired,
    onFormErrors     : PropTypes.func.isRequired,
    onResetForm      : PropTypes.func,
    onClearForm      : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.validatorRules = {};
    this._requiredRules = {};
  }


  componentWillUnmount() {
    if (this.props.onClearForm) this.props.onClearForm();
  }


  onSetValue(input) {
    this.props.onSetFormValue(input);
  }


  onSetValues(values) {
    if (this.props.onSetFormValues) this.props.onSetFormValues(values);
  }


  onUnsetValues(ids) {
    if (this.props.onUnsetFormValues) this.props.onUnsetFormValues(ids);
  }


  onCheck() {
    const { errors } = this.onValidate(this.validatorRules);
    this.props.onFormErrors(errors);
  }


  onReset() {
    if (this.props.onResetForm) this.props.onResetForm();
  }


  onSubmit(isClearForm = false) {
    this.props.onFormProcessing();
    const { validatedValues, errors } = this.onValidate(this.validatorRules);
    this.props.onFormErrors(errors);
    if (!errors) {
      if (this.props.onSubmit) this.props.onSubmit(validatedValues);
      if (this.props.onClearForm && isClearForm) this.props.onClearForm();
    }
  }


  onValidate(rules, isToUnfold = false) {
    const values = get(this.props.formValues, 'values', {});
    const { validatedValues, errors } = Validator.run(values, rules);
    return {
      validatedValues: isToUnfold ? unfoldObject(validatedValues) : validatedValues,
      errors,
    };
  }


  get requiredRules() {
    if (isEmpty(this._requiredRules)) {
      const fields = keysIn(this.validatorRules);
      forEach(fields, (field) => {
        if (startsWith(this.validatorRules[field], 'required')) {
          this._requiredRules[field] = 'required';
        }
      });
    }
    return this._requiredRules;
  }


  get isDisabled() {
    const { errors } = this.onValidate(this.requiredRules);
    return !!errors;
  }


  get isInProgress() {
    return !!this.props.isInProgress;
  }


  get hasErrors() {
    return !!this.props.hasErrors;
  }


  set requiredRules(requiredRules) {
    this._requiredRules = requiredRules;
  }


  renderAlerts() {
    return <App.components.AlertsBus className="mb-5" />;
  }

}

export default FormContainerAbstract;

