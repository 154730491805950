import { ApplicationInsights } from '@microsoft/applicationinsights-web';


let ai = null;

if (!__DEV__ && process.env.BROWSER) {
  const instrumentationKey = window.App.apps.appInsights;
  ai = new ApplicationInsights({
    config: {
      instrumentationKey,
    },
  });
  ai.loadAppInsights();
}


export default {
  trackEvent     : (eventName, properties) => ai && ai.trackEvent({ name: eventName, properties }),
  trackPageView  : (properties) => ai && ai.trackPageView(properties),
  trackException : (exception, properties) => ai && ai.trackException({ exception, properties }),
  startTrackEvent: (eventName) => ai && ai.startTrackEvent(eventName),
  stopTrackEvent : (eventName, properties) => ai && ai.stopTrackEvent(eventName, properties),
};
