import { createSelector } from 'reselect';
import includes from 'lodash/includes';
import * as constants from './constants';
import * as actionTypes from './actionTypes';

/**
 * Input selectors
 */
const mainSelector = (state) => state[constants.MODULE_ID];
const fetchingSelector = (state) => state[constants.MODULE_ID].fetching;
const errorsSelector = (state) => state[constants.MODULE_ID].errors;
const sendingSelector = (state) => state[constants.MODULE_ID].sending;


export const isFetchBLSListProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_BLS_LIST),
);

export const blsList = createSelector(
  mainSelector,
  (state) => state.components,
);

export const isAddBLSProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.ADD_BLS),
);

export const hasAddBLSErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.ADD_BLS),
);

export const isFetchBLSConfigurationRegionsProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_BLS_CONFIGURATION_REGIONS),
);

export const hasFetchBLSConfigurationRegionsErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.FETCH_BLS_CONFIGURATION_REGIONS),
);

export const blsConfiguration = createSelector(
  mainSelector,
  (state) => state.blsConfiguration,
);

export const isUpdateBLSConfigurationRegionsProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.UPDATE_BLS_CONFIGURATION_REGIONS),
);

export const updateBlsConfigurationError = createSelector(
  mainSelector,
  (state) => state.updateBlsConfigurationError,
);
export const isDeleteBLSProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.DELETE_BLS),
);

export const deleteBLSError = createSelector(
  mainSelector,
  (state) => state.deleteBLSError,
);
