import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import CopyToClipboard from 'components/CopyToClipboard';
import messages from '../../../../../../../messages';


class Translations extends React.Component {

  static propTypes = {
    // Explicit props

    missingTranslations: PropTypes.array,
    className          : PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }


  static defaultProps = {
    className: '',
  };


  get schema() {
    return [
      {
        key         : 'term',
        labelMessage: messages.labels.term,
        renderer    : (translation) => (
          <div className="d-flex align-items-center">
            <p className="mr-3">{translation.term}</p>
            <CopyToClipboard copyText={translation.term} />
          </div>
        ),
      },
      {
        key         : 'languageNames',
        labelMessage: messages.labels.languageNames,
        renderer    : (translation) => (
          <p>
            {translation.languageNames}
          </p>
        ),
      },
    ];
  }

  render() {
    return (
      <Table
        idKey="term"
        className={this.props.className}
        schema={this.schema}
        entities={this.props.missingTranslations}
        perPage={-1}
        isPerPageOff
      />
    );
  }

}

export default Translations;
