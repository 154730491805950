import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/Modal';
import Button from 'components/Form/Button';
import App from 'modules/App';
import * as constants from '../../../constants';
import * as actions from '../../../actions';
import messages from '../../../messages';


class UpdateBLSConfigurationFailedModal extends React.PureComponent {

  static propTypes = {
    // Explicit props
    businessError: PropTypes.string,
    // Explicit actions
    // Implicit props
    openModalId  : PropTypes.string,
    // Implicit actions
    onClose      : PropTypes.func,
    onClearResult: PropTypes.func,
  };

  componentWillUnmount() {
    this.props.onClearResult();
  }


  renderContent() {
    return (
      <div>
        <div className="form-error d-flex flex-column pt-7 pb-7 pl-8 pr-8 align-items-start">
          <p><FormattedMessage
            {...messages.errors.businessErrors.updateBLSConfiguration[this.props.businessError.code]}
          />
          </p>
        </div>
        <Button
          type="button"
          styleModifier="primary"
          labelMessage={App.messages.buttons.ok}
          className="btn--block btn--filled"
          onClick={() => this.props.onClose()}
        />
      </div>
    );
  }


  render() {
    return (
      <Modal
        modalId={constants.UPDATE_BLS_CONFIGURATION_FAILED_MODAL}
        openModalId={this.props.openModalId}
        headerMessage={messages.headers.updateBLSConfigurationFailed}
        styleModifier="lg"
        onClose={this.props.onClose}
      >
        { this.renderContent() }
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  openModalId: App.selectors.modal(state),
});


const mapDispatchToProps = (dispatch) => ({
  onClose      : () => dispatch(App.actions.closeModal()),
  onClearResult: () => dispatch(actions.updateBLSConfigurationClear()),
});


const ConnectedUpdateBLSConfigurationFailedModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateBLSConfigurationFailedModal);

export default ConnectedUpdateBLSConfigurationFailedModal;
