import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/Modal';
import Button from 'components/Form/Button';
import App from 'modules/App';
import * as constants from '../../../constants';
import * as actions from '../../../actions';
import * as selectors from '../../../selectors';
import messages from '../../../messages';


class DeleteCountryModal extends React.Component {

  static propTypes = {
    // Explicit props
    country                     : PropTypes.object,
    // Explicit actions
    onDeleteSuccess             : PropTypes.func,
    // Implicit props
    isInProgress                : PropTypes.bool,
    hasErrors                   : PropTypes.bool,
    isDeleteCountryResultSuccess: PropTypes.bool,
    openModalId                 : PropTypes.string,
    // Implicit actions
    onClose                     : PropTypes.func,
    onDeleteCountry             : PropTypes.func,
  };


  componentDidUpdate(prevProps) {
    if (prevProps.isInProgress && !this.props.isInProgress && !this.props.hasErrors
    ) {
      this.props.onDeleteSuccess();
    }
  }


  renderContent() {
    if (this.props.isDeleteCountryResultSuccess === null) {
      return (
        <>
          <p className="text--paragraph pr-2"><FormattedMessage
            {...messages.infos.deleteCountry}
            values={{
              b: (chunk) => <b>{chunk}</b>,
            }}
          />
          </p>
          <div className="d-flex">
            <Button
              type="button"
              styleModifier="primary"
              labelMessage={App.messages.buttons.cancel}
              className="btn--block mr-5"
              isInProgress={this.props.isInProgress}
              onClick={() => this.props.onClose()}
            />
            <Button
              type="button"
              styleModifier="primary"
              labelMessage={App.messages.buttons.delete}
              className="btn--block btn--filled btn--danger"
              isInProgress={this.props.isInProgress}
              onClick={() => this.props.onDeleteCountry(this.props.country.code)}
            />
          </div>
        </>
      );
    }
    return (
      <>
        <p className="text--paragraph"><FormattedMessage
          {...messages.infos.unableToDeleteCountry}
          values={{
            b: (chunk) => <b>{chunk}</b>,
          }}
        />
        </p>
        <div className="d-flex">
          <Button
            type="button"
            styleModifier="primary"
            onClick={() => this.props.onClose()}
            labelMessage={App.messages.buttons.close}
            className="btn--block btn--filled"
          />
        </div>
      </>
    );

  }


  render() {
    return (
      <Modal
        modalId={constants.DELETE_COUNTRY_MODAL}
        openModalId={this.props.openModalId}
        headerMessage={this.props.isDeleteCountryResultSuccess
          ? messages.headers.unableToDeleteCountry
          : messages.headers.deleteCountry}
        styleModifier="md"
        isInProgress={this.props.isInProgress}
        onClose={this.props.onClose}
      >
        { this.renderContent() }
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  openModalId                 : App.selectors.modal(state),
  isInProgress                : selectors.isDeleteCountryProgress(state),
  isDeleteCountryResultSuccess: selectors.isDeleteCountryResultSuccess(state),
});


const mapDispatchToProps = (dispatch) => ({
  onClose        : () => dispatch(App.actions.closeModal()),
  onDeleteCountry: (countryCode) => dispatch(actions.deleteCountry(countryCode)),
});


const ConnectedDeleteCountryModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteCountryModal);

export default ConnectedDeleteCountryModal;
