import React from 'react';
import PropTypes from 'prop-types';
import camelCase from 'lodash/camelCase';
import withStyles from 'isomorphic-style-loader/withStyles';
import Chip from 'components/Chip';
import messages from '../../messages';
import * as constants from '../../constants';
import styles from './StatusChip.pcss';


class StatusChip extends React.PureComponent {

    static propTypes = {
      // Explicit props
      status: PropTypes.string.isRequired,
    };

    render() {
      return (
        <Chip
          className={styles.statusChip}
          labelMessage={messages.publishStatus[camelCase(this.props.status)]}
          styleModifier={constants.CHIP_STYLE_BY_STATUS[camelCase(this.props.status)]}
        />
      );
    }

}

export default withStyles(styles)(StatusChip);

