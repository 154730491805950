import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/Modal';
import Button from 'components/Form/Button';
import App from 'modules/App';
import * as constants from '../../../constants';
import * as actions from '../../../actions';
import * as selectors from '../../../selectors';
import messages from '../../../messages';


class DeleteApproverModal extends React.PureComponent {

  static propTypes = {
    // Explicit props
    approver        : PropTypes.string,
    // Explicit actions
    // Implicit props
    isInProgress    : PropTypes.bool,
    openModalId     : PropTypes.string,
    // Implicit actions
    onClose         : PropTypes.func,
    onDeleteApprover: PropTypes.func,
  };


  renderContent() {
    return (
      <div>
        <p className="text--paragraph pr-2 mb-3"><FormattedMessage
          {...messages.infos.confirmDeleteApprover}
          values={{
            b    : (chunk) => <b>{chunk}</b>,
            email: this.props.approver.email,
          }}
        />
        </p>
        <div className="d-flex">
          <Button
            type="button"
            styleModifier="primary"
            labelMessage={App.messages.buttons.cancel}
            className="btn--block  mr-5"
            isInProgress={this.props.isInProgress}
            onClick={() => this.props.onClose()}
          />
          <Button
            type="button"
            styleModifier="primary"
            labelMessage={App.messages.buttons.delete}
            className="btn--block btn--filled btn--danger"
            isInProgress={this.props.isInProgress}
            onClick={() => this.props.onDeleteApprover(this.props.approver.email)}
          />
        </div>
      </div>
    );
  }


  render() {
    return (
      <Modal
        modalId={constants.DELETE_ORGANIZATION_APPROVER_MODAL}
        openModalId={this.props.openModalId}
        headerMessage={messages.headers.deleteApprover}
        styleModifier="md"
        isInProgress={this.props.isInProgress}
        onClose={this.props.onClose}
      >
        { this.renderContent() }
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  openModalId : App.selectors.modal(state),
  isInProgress: selectors.isDeleteApproverProgress(state),
});


const mapDispatchToProps = (dispatch) => ({
  onClose         : () => dispatch(App.actions.closeModal()),
  onDeleteApprover: (approverName) => dispatch(actions.deleteApprover(approverName)),
});


const ConnectedDeleteApproverModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteApproverModal);

export default ConnectedDeleteApproverModal;
