import { MODULE_ID } from './constants';


export const GET_ME = `${MODULE_ID}/GET_ME`;
export const GET_ME_SUCCESS = `${MODULE_ID}/GET_ME_SUCCESS`;
export const GET_ME_ERROR = `${MODULE_ID}/GET_ME_ERROR`;

export const SIGN_IN_CALLBACK = `${MODULE_ID}/SIGN_IN_CALLBACK`;

export const LOAD_ACCOUNT = `${MODULE_ID}/LOAD_ACCOUNT`;
export const LOAD_ACCOUNT_SUCCESS = `${MODULE_ID}/LOAD_ACCOUNT_SUCCESS`;
export const LOAD_ACCOUNT_ERROR = `${MODULE_ID}/LOAD_ACCOUNT_ERROR`;

export const SIGN_OUT = `${MODULE_ID}/SIGN_OUT`;
export const SIGN_OUT_SUCCESS = `${MODULE_ID}/SIGN_OUT_SUCCESS`;
export const SIGN_OUT_ERROR = `${MODULE_ID}/SIGN_OUT_ERROR`;
