exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3cev9{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;padding:.4rem 1.2rem;color:#0d0d0d;font-size:12px;font-size:1.2rem;font-weight:600;background:#078285;border-radius:8px;line-height:2}._2ngX8{color:#fff}._1iB3E{color:#127e68;background:#e0fff7}._3q-u5{color:#da1818;background:#feefef}._1seUD{color:#806b20;background:#fdf9e6}", ""]);

// exports
exports.locals = {
	"chip": "_3cev9",
	"chip--inactive": "_2ngX8",
	"chip--success": "_1iB3E",
	"chip--error": "_3q-u5",
	"chip--pending": "_1seUD"
};