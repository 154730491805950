import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { motion } from 'framer-motion';
import withStyles from 'isomorphic-style-loader/withStyles';
import isObjectLike from 'lodash/isObjectLike';
import isFunction from 'lodash/isFunction';
import { FormattedMessage } from 'react-intl';
import ChevronRightIcon from 'svg/chevron-right.svg';
import styles from './CollapsibleSection.pcss';


class CollapsibleSection extends React.PureComponent {

  static propTypes = {
    headerMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
    children     : PropTypes.node.isRequired,
    className    : PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  };

  static defaultProps = {
    className: '',
  };


  constructor(props) {
    super(props);
    this.state = {
      isOpen   : false,
      isOpening: false,
      overflow : 'hidden',
    };
  }


  onSwitch(evt) {
    evt.preventDefault();
    this.setState((state) => ({ isOpening: !state.isOpening }));
  }


  onAnimationStart() {
    const { isOpen, isOpening } = this.state;
    if (isOpening && !isOpen) {
      this.setState({ isOpen: true });
    } else if (!isOpening && isOpen) {
      this.setState({ overflow: 'hidden' });
    }
  }


  onAnimationComplete() {
    const { isOpen, isOpening } = this.state;
    if (!isOpening && isOpen) {
      this.setState({ isOpen: false });
    } else if (isOpening && isOpen) {
      this.setState({ overflow: 'visible' });
    }
  }


  renderHeaderText() {
    if (isFunction(this.props.headerMessage)) return this.props.headerMessage();
    return (
      isObjectLike(this.props.headerMessage)
        ? <FormattedMessage {...this.props.headerMessage} />
        : this.props.headerMessage
    );
  }


  renderSwitchIndicator() {
    return (
      <div
        className={cn(styles.section__header__switchIndicator, {
          [styles['section__header__switchIndicator--expanded']]: this.state.isOpening,
        })}
      >
        <ChevronRightIcon className={styles.section__header__switchIndicator__icon} />
      </div>
    );
  }


  render() {
    const { isOpen, isOpening, overflow } = this.state;
    return (
      <section className={cn(this.props.className, styles.section, { [styles['section--open']]: isOpen })}>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events */}
        <a
          className={styles.section__switch}
          onClick={(evt) => this.onSwitch(evt)}
        >
          <header className={styles.section__header}>
            <h5 className={cn(styles.section__header__text, 'text--h5')}>
              { this.renderHeaderText() }
            </h5>
            { this.renderSwitchIndicator() }
          </header>
        </a>
        <motion.div
          initial={{ height: 0 }}
          animate={{ height: isOpening ? 'auto' : 0 }}
          transition={{ ease: 'easeOut', duration: 0.3 }}
          className={styles.section__contentContainer}
          style={{ overflow }}
          onAnimationStart={() => this.onAnimationStart()}
          onAnimationComplete={() => this.onAnimationComplete()}
        >
          <div className="section mt-0">
            {this.props.children}
          </div>
        </motion.div>
      </section>
    );
  }

}


export default withStyles(styles)(CollapsibleSection);
