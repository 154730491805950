module.exports = {
  'brand-success'   : '#127E68',
  'brand-success-bg': '#E0FFF7',
  'brand-pending'   : '#806B20',
  'brand-pending-bg': '#FDF9E6',
  'brand-error'     : '#DA1818',
  'brand-error-bg'  : '#FEEFEF',
  'brand-info'      : '#4299F0',
  'brand-warning'   : '#F2A65A',
};
