import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import camelCase from 'lodash/camelCase';
import { connect } from 'react-redux';
import { findIndex } from 'lodash';
import App from 'modules/App';
import Modal from 'components/Modal';
import Steps from 'components/Steps';
import LoadingBanner from 'components/LoadingBanner';
import messages from '../../messages';
import * as constants from '../../constants';
import * as actions from '../../actions';
import * as selectors from '../../selectors';
import Review from './Steps/Review';
import Validate from './Steps/Validate';
import Publish from './Steps/Publish';
import styles from './PublishModal.pcss';


class PublishModal extends React.PureComponent {

  static propTypes = {
    // Explicit props
    openModalId: PropTypes.string,

    // Implicit props
    publishVersionDiffs                    : PropTypes.any,
    isFetchPublishVersionDiffsProgress     : PropTypes.bool,
    hasFetchPublishVersionDiffsErrors      : PropTypes.bool,
    publishCommits                         : PropTypes.any,
    isFetchPublishVersionCommitsProgress   : PropTypes.bool,
    hasFetchPublishVersionCommitsErrors    : PropTypes.bool,
    version                                : PropTypes.string,
    isAddNewPublishVersionProgress         : PropTypes.bool,
    lastPublish                            : PropTypes.object,
    cancelPublishReasons                   : PropTypes.array,
    isLastPublishRelatedToPublishingVersion: PropTypes.bool,
    hasInitializePublishVersionErrors      : PropTypes.bool,
    // Implicit actions
    onAddNewPublishVersion                 : PropTypes.func,
    onFetchPublishVersionDiffs             : PropTypes.func,
    onFetchPublishVersionCommits           : PropTypes.func,
    onPublishClose                         : PropTypes.func,
    onClose                                : PropTypes.func,
    onSendJoinPublishSnapshotProcess       : PropTypes.func,
    onSendLeavePublishSnapshotProcess      : PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      activeViewKey: this.viewStepsOrder[0],
      isLoading    : true,
    };
    this.props.onSendJoinPublishSnapshotProcess();
    this.props.onAddNewPublishVersion();
  }


  componentDidUpdate(prevProps) {
    if (prevProps.isAddNewPublishVersionProgress && !this.props.isAddNewPublishVersionProgress) {
      this.onInitialize();
    }
  }


  componentWillUnmount() {
    this.props.onPublishClose();
    this.props.onSendLeavePublishSnapshotProcess();
  }


  onInitialize() {
    this.setState({ isLoading: false });
  }


  onChangeView(viewKey) {
    this.setState({ activeViewKey: viewKey });
  }


  get views() {
    return ({
      Review,
      Validate,
      Publish,
    });
  }

  get isDisabled() {
    if (this.state.activeViewKey !== 'Publish') return false;
    return this.isPublishInProgress;
  }

  get isPublishInProgress() {
    if (this.props.cancelPublishReasons) return false;
    if (this.props.hasInitializePublishVersionErrors) return false;
    if (!this.props.isLastPublishRelatedToPublishingVersion) return true;
    return this.props.lastPublish.isProcessing;
  }

  get viewStepsOrder() {
    return (['Review', 'Validate', 'Publish']);
  }


  renderContent() {

    if (this.state.isLoading) {
      return (
        <div className={styles.snapshotDetailsModal__content}>
          <LoadingBanner labelMessage={messages.infos.loadingNewVersion} />
        </div>
      );
    }
    const ActiveView = this.views[this.state.activeViewKey];
    return (
      <div className={styles.snapshotDetailsModal__content}>
        <ActiveView
          onChangeView={(viewKey) => this.onChangeView(viewKey)}
          {...this.props}
        />
      </div>
    );
  }


  renderHeader() {
    const template = this.viewStepsOrder.map((id) => ({ id, labelMessage: messages.steps.publish[camelCase(id)] }));
    const activeStepIdx = findIndex(this.viewStepsOrder, (step) => step === this.state.activeViewKey);
    const { version } = this.props;
    return (
      <div className="d-flex justify-content-between align-items-center">
        {
          version
            ? <p><FormattedMessage {...messages.headers.publishNewVersion} values={{ version }} /></p>
            : <p><FormattedMessage {...messages.headers.publishRecentChanges} /></p>
        }
        <Steps className="mr-10" template={template} activeStepIdx={activeStepIdx} />
      </div>
    );
  }


  render() {
    return (
      <Modal
        modalId={constants.PUBLISH_MODAL}
        openModalId={this.props.openModalId}
        headerMessage={() => this.renderHeader()}
        styleModifier="xl"
        onClose={() => { this.props.onClose(); }}
        isOverlayClickClose={false}
        isDisabled={this.isDisabled}
      >
        {this.renderContent()}
      </Modal>
    );
  }

}


const mapStateToProps = (state) => (
  {
    version                             : selectors.newPublishVersion(state),
    isAddNewPublishVersionProgress      : selectors.isAddNewPublishVersionProgress(state),
    publishCommits                      : selectors.publishCommits(state),
    isFetchPublishVersionCommitsProgress: selectors.isFetchPublishVersionCommitsProgress(state),
    hasFetchPublishVersionCommitsErrors : selectors.hasFetchPublishVersionCommitsErrors(state),

    publishVersionDiffs               : selectors.publishDiffs(state),
    isFetchPublishVersionDiffsProgress: selectors.isFetchPublishVersionDiffsProgress(state),
    hasFetchPublishVersionDiffsErrors : selectors.hasFetchPublishVersionDiffsErrors(state),

    lastPublish                            : selectors.lastPublish(state),
    isLastPublishRelatedToPublishingVersion: selectors.isLastPublishRelatedToPublishingVersion(state),
    hasInitializePublishVersionErrors      : selectors.hasInitializePublishVersionErrors(state),
    cancelPublishReasons                   : selectors.cancelPublishReasons(state),
  });


const mapDispatchToProps = (dispatch) => {
  const { PUBLISH_SNAPSHOT_SEND } = constants;
  return ({
    onAddNewPublishVersion      : () => dispatch(actions.addNewPublishVersion()),
    onFetchPublishVersionCommits: (publishVersion) => dispatch(actions.fetchPublishVersionCommits(publishVersion)),

    onFetchPublishVersionDiffs   : (publishVersion) => dispatch(actions.fetchPublishVersionDiffs(publishVersion)),
    onDownloadPublishVersionDiffs: (publishVersion) =>
      dispatch(actions.downloadPublishVersionDiffs(publishVersion, `Version ${publishVersion}.json`)),
    onPublishClose                  : () => dispatch(actions.validatePublishVersionFinish()),
    onSendJoinPublishSnapshotProcess: () => dispatch(App.actions.signalRSend(
      PUBLISH_SNAPSHOT_SEND.JoinPublishSnapshotProcess, null
    )),
    onSendLeavePublishSnapshotProcess: () => dispatch(App.actions.signalRSend(
      PUBLISH_SNAPSHOT_SEND.LeavePublishSnapshotProcess, null
    )),
    onClose: () => dispatch(App.actions.closeModal()),
  });
};


const ConnectedPublishModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublishModal);


export default withStyles(styles)(ConnectedPublishModal);
