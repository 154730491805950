import { getDefaultRoute, isAuthenticated } from 'helpers/routeTools';
/* eslint-disable global-require */
import Account from 'modules/Account';
import SessionStorage from 'libs/SessionStorage';
import { LOGIN_REDIRECT_TO_KEY } from 'modules/App/constants';
import { SCOPE_NAMES } from 'modules/Account/constants';

// The top-level (parent) route
const routes = {
  // Keep in mind, routes are evaluated in order
  path    : '',
  children: [
    {
      path: '/',
      name: '/',
      async action({ store }) {
        const state = store.getState();
        if (isAuthenticated(state)) {
          return { redirect: getDefaultRoute(state) };
        }
        return { redirect: '/login' };
      },
    },
    {
      path: '/login',
      name: '/login',
      async action({ store, next }) {
        const state = store.getState();
        if (isAuthenticated(state)) {
          return { redirect: getDefaultRoute(state) };
        }
        const route = await next();
        return route;
      },
      load: () => import(/* webpackChunkName: 'admin' */ './login'),
    },
    // with auth
    {
      path    : '/bo',
      children: [
        {
          name      : 'dashboard',
          path      : '/dashboard',
          authScopes: [SCOPE_NAMES.BACK_OFFICE_USER],
          load      : () => import(/* webpackChunkName: 'account' */ './dashboard'),
        },
        {
          name      : 'approvers',
          path      : '/approvers',
          authScopes: [SCOPE_NAMES.SDN_ADMIN, SCOPE_NAMES.COUNTRY_MANAGER, SCOPE_NAMES.ORGANIZATION_APPROVER],
          load      : () => import(/* webpackChunkName: 'approvers' */ './approvers'),
        },
        {
          name      : 'countries',
          path      : '/countries',
          authScopes: [SCOPE_NAMES.SDN_ADMIN, SCOPE_NAMES.COUNTRY_MANAGER],
          load      : () => import(/* webpackChunkName: 'countries' */ './countries'),
        },
        {
          name      : 'country-configuration',
          path      : '/country/:countryCode/configuration',
          authScopes: [SCOPE_NAMES.SDN_ADMIN, SCOPE_NAMES.COUNTRY_MANAGER],
          load      : () => import(/* webpackChunkName: 'countries' */ './country-configuration'),
        },
        {
          name      : 'businessLogicSystems',
          path      : '/bls',
          authScopes: [SCOPE_NAMES.SDN_ADMIN],
          load      : () => import(/* webpackChunkName: 'bls' */ './bls'),
        },
        {
          name      : 'bls-configuration-regions',
          path      : '/bls/:blsName/region',
          authScopes: [SCOPE_NAMES.SDN_ADMIN],
          load      : () => import(/* webpackChunkName: 'bls' */ './bls-configuration-regions'),
        },
        {
          name      : 'publishSnapshot',
          path      : '/publish-snapshot',
          authScopes: [SCOPE_NAMES.SDN_ADMIN],
          load      : () => import(/* webpackChunkName: 'publishSnapshot' */ './publish-snapshot'),
        },
      ],
      async action(context) {
        const { store, next, pathname } = context;
        const state = store.getState();
        if (!process.env.BROWSER) {
          const route = await next();
          return route;
        }
        if (!isAuthenticated(state)) {
          SessionStorage.setItem(LOGIN_REDIRECT_TO_KEY, pathname);
          return { redirect: '/' };
        }
        const route = await next();
        return route;
      },
    },
    {
      name: 'logout',
      path: '/logout',
      async action({ store }) {
        store.dispatch(Account.actions.signOut());
        return { redirect: '/logout-success' };
      },
    },
    {
      name: 'error',
      path: '/error/:code',
      load: () => import(/* webpackChunkName: 'error' */ './error'),
    },
    {
      name: 'logout-success',
      path: '/logout-success',
      load: () => import(/* webpackChunkName: 'account' */ './logout-success'),
    },
    {
      name: 'forbidden',
      path: '/forbidden',
      load: () => import(/* webpackChunkName: 'forbidden' */ './forbidden'),
    },
    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      name: 'not-found',
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],

  async action(context) {
    // Execute each child route until one of them return the result
    const route = await context.next();
    return route;
  },
};

export default routes;
