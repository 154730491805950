import { defineMessages } from 'react-intl';


export default defineMessages({
  validationErrors: {
    forceError                       : { id: 'form.validationErrors.forceError' },
    required                         : { id: 'form.validationErrors.required' },
    length                           : { id: 'form.validationErrors.length' },
    limit                            : { id: 'form.validationErrors.limit' },
    limitDigits                      : { id: 'form.validationErrors.limitDigits' },
    minimum                          : { id: 'form.validationErrors.minimum' },
    range                            : { id: 'form.validationErrors.range' },
    alreadyExist                     : { id: 'form.validationErrors.alreadyExist' },
    alphaNumeric                     : { id: 'form.validationErrors.alphanumeric' },
    capitalLetters                   : { id: 'form.validationErrors.capitalLetters' },
    lowerLetters                     : { id: 'form.validationErrors.lowerLetters' },
    capitalLettersDashesAndUnderscore: { id: 'form.validationErrors.capitalLettersDashesAndUnderscore' },
    bit                              : { id: 'form.validationErrors.bit' },
    integer                          : { id: 'form.validationErrors.integer' },
    decimal                          : { id: 'form.validationErrors.decimal' },
    doublePrecision                  : { id: 'form.validationErrors.doublePrecision' },
    positive                         : { id: 'form.validationErrors.positive' },
    greaterThen                      : { id: 'form.validationErrors.greaterThen' },
    lowerThen                        : { id: 'form.validationErrors.lowerThen' },
    nonNegative                      : { id: 'form.validationErrors.nonNegative' },
    maxValue                         : { id: 'form.validationErrors.maxValue' },
    minValue                         : { id: 'form.validationErrors.minValue' },
    regExp                           : { id: 'form.validationErrors.regExp' },
    equalTo                          : { id: 'form.validationErrors.equalTo' },
    masterFor                        : { id: 'form.validationErrors.masterFor' },
    slaveFor                         : { id: 'form.validationErrors.slaveFor' },
    alternative                      : { id: 'form.validationErrors.alternative' },
    date                             : { id: 'form.validationErrors.date' },
    time                             : { id: 'form.validationErrors.time' },
    email                            : { id: 'form.validationErrors.email' },
    phone                            : { id: 'form.validationErrors.phone' },
    phoneWithPrefix                  : { id: 'form.validationErrors.phoneWithPrefix' },
    zipcode                          : { id: 'form.validationErrors.zipcode' },
    url                              : { id: 'form.validationErrors.url' },
    password                         : { id: 'form.validationErrors.password' },
    legalAge                         : { id: 'form.validationErrors.legalAge' },
  },
  uploaderMessages: {
    errors: {
      limit          : { id: 'form.uploaderMessages.errors.limit' },
      acceptFileTypes: { id: 'form.uploaderMessages.errors.acceptFileTypes' },
      maxSize        : { id: 'form.uploaderMessages.errors.maxSize' },
      other          : { id: 'form.uploaderMessages.errors.other' },
    },
  },
  select: {
    default: { id: 'form.select.default' },
  },
  labels: {
    optional: { id: 'form.labels.optional' },
  },
});
