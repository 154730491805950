import React from 'react';
import { connect } from 'react-redux';
import ReactTooltip from 'react-tooltip';
import cn from 'classnames';
import delay from 'lodash/delay';
import reduce from 'lodash/reduce';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import Table from 'components/Table';
import CollapsibleGroup from 'components/CollapsibleGroup';
import EllipsisTooltipText from 'components/EllipsisTooltipText';
import Button from 'components/Form/Button';
import Input from 'components/Form/Input';
import DeleteIcon from 'svg/delete.svg';
import Loupe from 'svg/loupe-light.svg';
import AddIcon from 'svg/add.svg';
import FilterIcon from 'svg/tune-vertical.svg';
import App from 'modules/App';
import Country from 'modules/Country';
import { AppContext } from 'context';
import * as actions from '../../actions';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import messages from '../../messages';
import { hasAssignedCountriesOutOfUserScope } from './managedCountriesHelper';
import UpdateOrAddApproverModal from './UpdateOrAddApproverModal';
import DeleteApproverModal from './DeleteApproverModal';
import FilterApprovers from './FilterApprovers';
import styles from './ListOrganizationApprovers.pcss';


class ListOrganizationApprovers extends React.PureComponent {

    static contextType = AppContext;

    static propTypes = {
      // Explicit props
      // Implicit props
      organizationApprovers        : PropTypes.array,
      languageOptions              : PropTypes.array,
      countries                    : PropTypes.array,
      allCountries                 : PropTypes.array,
      totalEntities                : PropTypes.number,
      openModalId                  : PropTypes.string,
      isUpdateOrAddApproverProgress: PropTypes.bool,
      isDeleteApproverProgress     : PropTypes.bool,
      hasUpdateOrAddApproverErrors : PropTypes.bool,
      // Implicit actions
      onOpenModal                  : PropTypes.func,
      onCloseModal                 : PropTypes.func,
      onFetchOrganizationApprovers : PropTypes.func,
      onFetchCountries             : PropTypes.func,
      onFetchAllCountries          : PropTypes.func,
      onFetchLanguages             : PropTypes.func,
    };


    constructor(props) {
      super(props);
      this.state = {
        approverToUpdate    : null,
        approverToDelete    : null,
        search              : '',
        filterByCountryCodes: [],
        showFilter          : false,
      };
    }


    componentDidMount() {
      this.props.onFetchOrganizationApprovers();
      this.props.onFetchCountries();
      this.props.onFetchAllCountries();
      this.props.onFetchLanguages();
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.isUpdateOrAddApproverProgress && !this.props.isUpdateOrAddApproverProgress
        && !this.props.hasUpdateOrAddApproverErrors) {
        this.props.onCloseModal(constants.UPDATE_OR_ADD_ORGANIZATION_APPROVER_MODAL);
        this.onFetch();
      }
      if (prevState.search !== this.state.search
        || prevState.filterByCountryCodes !== this.state.filterByCountryCodes
      ) {
        this.onFetch();
      }
      if (prevProps.isDeleteApproverProgress && !this.props.isDeleteApproverProgress) {
        this.props.onCloseModal(constants.DELETE_ORGANIZATION_APPROVER_MODAL);
        this.onFetch();
      }

    }


    onClickAddApprover() {
      this.setState({ approverToUpdate: null });
      this.props.onOpenModal(constants.UPDATE_OR_ADD_ORGANIZATION_APPROVER_MODAL);
    }


    onClickEditApprover(approver) {
      this.setState({ approverToUpdate: approver });
      this.props.onOpenModal(constants.UPDATE_OR_ADD_ORGANIZATION_APPROVER_MODAL);
    }


    onClickDeleteApprover(approver) {
      this.setState({ approverToDelete: approver });
      this.props.onOpenModal(constants.DELETE_ORGANIZATION_APPROVER_MODAL);
    }


    onFetch(event) {
      const page = (event && event.page) || 0;
      const perPage = (event && event.perPage) || 10;
      this.props.onFetchOrganizationApprovers({
        page,
        perPage,
        term       : this.state.search,
        countryCode: this.state.filterByCountryCodes,
      });
      this.setState({ page });
    }


    onToggleShowFilter() {
      this.setState((state) => ({ showFilter: !state.showFilter }));
    }


    get organizationApprovers() {
      return this.props.organizationApprovers || [];
    }


    get schema() {
      const schema = [
        {
          key         : 'email',
          labelMessage: messages.labels.email,
          renderer    : (approver) => <b>{this.renderEmail(approver)}</b>,
        },
        {
          key         : 'firstName',
          labelMessage: messages.labels.firstName,
          renderer    : (approver) => <>{ approver.firstName }</>,
        },
        {
          key         : 'lastName',
          labelMessage: messages.labels.lastName,
          renderer    : (approver) => <>{approver.lastName}</>,
        },
        {
          key         : 'countries',
          labelMessage: messages.labels.assignedCountries,
          renderer    : (approver) => this.renderCountries(approver),
        },
        {
          key     : 'edit',
          renderer: (approver) => this.renderEditButton(approver),
        },
        {
          key     : 'delete',
          renderer: (approver) => this.renderDeleteIcon(approver),
        },
      ];
      return schema;
    }

    get activeFiltersCounter() {
      return this.state.filterByCountryCodes.length ? 1 : 0;
    }

    renderEmail(approver) {
      const id = `tooltip-email${approver.email}`;
      return (
        <EllipsisTooltipText
          tooltipText={approver.email}
          id={id}
          renderTooltip={() => this.renderTooltip(id)}
        >
          <div className={styles.table__wrapCell}>{approver.email}</div>
        </EllipsisTooltipText>

      );
    }


    renderCountries(approver) {
      const countries = approver.countries || [];
      const countriesNames = reduce(countries.map((country) => country.name), (prev, next) => `${prev}, ${next}`);
      const countriesNamesTooltip = reduce(countries.map((country) => country.name), (prev, next, currentIndex) => {
        const shouldAddBreak = currentIndex % 3 === 0;
        prev = `${prev}${shouldAddBreak ? ',<br />' : ', '}${next}`;
        return prev;
      });
      const id = `tooltip-countries${approver.email}`;
      return (
        <EllipsisTooltipText
          tooltipText={countriesNamesTooltip}
          id={id}
          renderTooltip={() => this.renderTooltip(id)}
        >
          <div className={styles.table__wrapCell}>{countriesNames}</div>
        </EllipsisTooltipText>

      );
    }


    renderDeleteIcon(approver) {
      const isDisable = hasAssignedCountriesOutOfUserScope(approver.countries, this.props.countries);
      return (
        <DeleteIcon
          className={cn(styles.approversTable__deleteIcon,
            { [styles['approversTable__deleteIcon--inactive']]: isDisable },)
          }
          onClick={() => !isDisable && this.onClickDeleteApprover(approver)}
        />
      );
    }


    renderEditButton(approver) {
      return (
        <Button
          labelMessage={messages.buttons.edit}
          className="btn--outline"
          onClick={() => this.onClickEditApprover(approver)}
        />
      );
    }


    renderAddApproverButton() {
      return (
        <Button
          className="btn--primary btn--filled"
          onClick={() => this.onClickAddApprover()}
        >
          <>
            <AddIcon className={styles.header__addButton__icon} />
            <p><FormattedMessage {...messages.buttons.addApprover} /></p>
          </>
        </Button>
      );
    }

    renderFilterButton() {
      return (
        <Button
          className={cn('btn--primary btn--outline',
            { [styles['filter--active']]: this.state.showFilter })}
          onClick={() => { this.onToggleShowFilter(); }}
        >
          <>
            <FilterIcon className={styles.header__addButton__icon} />
            <p><FormattedMessage {...messages.buttons.filter} /></p>
            {!this.activeFiltersCounter
            || (
            <div className={styles.header__wrapperCounter}>
              <span className={styles.header__counter}>{this.activeFiltersCounter}</span>

            </div>
            )
              }
          </>
        </Button>
      );
    }


    renderSearch() {
      return (
        <div className={styles.header__search}>
          <Input
            placeholder={messages.placeholders.searchApprover}
            value={this.state.search}
            onChange={(input) => { delay(() => this.setState({ search: input.value })); }}
          />
          <Loupe className={styles.search__loupe} />
        </div>
      );
    }


    renderHeader() {
      return (
        <div className={styles.header}>
          <div className={styles.header__side}>
            <header className="text--h1">
              <FormattedMessage {...messages.headers.organizationApprovers} />
            </header>
          </div>
          <div className={styles.header__side}>
            {this.renderSearch()}
            {this.renderFilterButton()}
            {this.renderAddApproverButton()}
          </div>
        </div>
      );
    }


    renderFilter() {
      return (
        <CollapsibleGroup
          className="mb-7"
          isOpen={this.state.showFilter}
        >
          <FilterApprovers
            countries={this.props.allCountries}
            chosenCounties={this.state.filterByCountryCodes}
            onSubmit={(countryCode) => { this.setState({ filterByCountryCodes: countryCode }); }}
          />
        </CollapsibleGroup>
      );
    }


    renderTooltip(tooltipId) {
      if (!process.env.BROWSER) {
        return null;
      }
      return (
        <div className="tooltip__wrapper">
          <ReactTooltip
            className="tooltip__wrapper"
            id={tooltipId}
            place="right"
            type="dark"
            multiline
          />
        </div>

      );
    }


    renderTable() {
      return (
        <>
          <Table
            idKey="email"
            className={styles.approversTable}
            schema={this.schema}
            entities={this.organizationApprovers}
            page={this.state.page}
            perPage={10}
            totalEntities={this.props.totalEntities}
            isPerPageOff
            isOnPageChangeReturnOneSide
            onPageChange={(event) => this.onFetch(event)}
          />
        </>

      );
    }


    renderModals() {
      if (this.props.openModalId === constants.UPDATE_OR_ADD_ORGANIZATION_APPROVER_MODAL) {
        return (
          <UpdateOrAddApproverModal
            openModalId={this.props.openModalId}
            countries={this.props.countries}
            languageOptions={this.props.languageOptions}
            approver={this.state.approverToUpdate}
          />
        );
      }
      if (this.props.openModalId === constants.DELETE_ORGANIZATION_APPROVER_MODAL && this.state.approverToDelete) {
        return (
          <DeleteApproverModal
            approver={this.state.approverToDelete}
          />
        );
      }
      return null;
    }


    renderAlerts() {
      if (this.props.openModalId) return <div className="mb-5" />;
      return <App.components.AlertsBus className="mb-5" />;
    }


    render() {
      return (
        <div className={styles.listApprovers}>
          {this.renderAlerts()}
          {this.renderHeader()}
          {this.renderFilter()}
          {this.renderTable()}
          {this.renderModals()}
        </div>
      );
    }

}


const mapStateToProps = (state) => (
  {
    openModalId                  : App.selectors.modal(state),
    totalEntities                : selectors.organizationApproversTotal(state),
    organizationApprovers        : selectors.organizationApprovers(state),
    isUpdateOrAddApproverProgress: selectors.isUpdateApproverProgress(state) || selectors.isAddApproverProgress(state),
    hasUpdateOrAddApproverErrors : selectors.hasAddApproverErrors(state),
    isDeleteApproverProgress     : selectors.isDeleteApproverProgress(state),
    countries                    : Country.selectors.countries(state),
    allCountries                 : Country.selectors.allCountries(state),
    languageOptions              : Country.selectors.languages(state),
  });


const mapDispatchToProps = (dispatch) => ({
  onOpenModal                 : (modalId) => dispatch(App.actions.openModal(modalId)),
  onCloseModal                : () => dispatch(App.actions.closeModal()),
  onFetchOrganizationApprovers: (request) => dispatch(actions.fetchOrganizationApprovers(request)),
  onFetchCountries            : () => dispatch(Country.actions.fetchCountries()),
  onFetchAllCountries         : () => dispatch(Country.actions.fetchAllCountries()),
  onFetchLanguages            : () => dispatch(Country.actions.fetchLanguages()),
});


const ConnectedListOrganizationApprovers = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ListOrganizationApprovers);


export default withStyles(styles)(ConnectedListOrganizationApprovers);
