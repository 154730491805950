import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/Modal';
import Button from 'components/Form/Button';
import App from 'modules/App';
import * as constants from '../../../constants';
import * as actions from '../../../actions';
import * as selectors from '../../../selectors';
import messages from '../../../messages';


class DeleteBLSModal extends React.PureComponent {

  static propTypes = {
    // Explicit props
    bls           : PropTypes.string,
    // Explicit actions
    // Implicit props
    isInProgress  : PropTypes.bool,
    deleteBLSError: PropTypes.object,
    openModalId   : PropTypes.string,
    // Implicit actions
    onClose       : PropTypes.func,
    onClearResult : PropTypes.func,
    onDeleteBLS   : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      isValidated: false,
    };
  }


  componentDidUpdate(prevProps) {
    if (prevProps.isInProgress && !this.props.isInProgress) {
      this.onValidated();
    }
  }


  componentWillUnmount() {
    this.props.onClearResult();
    this.setState({ isValidated: false });
  }


  onValidated() {
    this.setState({ isValidated: true });
  }


  renderContent() {
    if (!this.state.isValidated) return this.renderToConfirm();
    if (this.props.deleteBLSError) return this.renderErrors();
    return this.renderSuccess();
  }


  renderToConfirm() {
    return (
      <div>
        <p className="text--paragraph pr-2 mb-3"><FormattedMessage
          {...messages.infos.confirmDeleteBLS}
          values={{ blsName: this.props.bls }}
        />
        </p>
        <div className="d-flex">
          <Button
            type="button"
            styleModifier="primary"
            labelMessage={App.messages.buttons.cancel}
            className="btn--block "
            isInProgress={this.props.isInProgress}
            onClick={() => this.props.onClose()}
          />
          <Button
            type="button"
            styleModifier="primary"
            labelMessage={App.messages.buttons.confirm}
            className="btn--block btn--filled mr-8"
            isInProgress={this.props.isInProgress}
            onClick={() => this.props.onDeleteBLS(this.props.bls)}
          />
        </div>
      </div>
    );
  }


  renderSuccess() {
    return (
      <div>
        <div className="form-success d-flex flex-column pt-7 pb-7 pl-8 pr-8 align-items-center">
          <p><FormattedMessage
            {...messages.infos.deleteBLSSuccess}
            values={{ blsName: this.props.bls,
            }}


          />
          </p>
        </div>
        <div className="d-flex">
          <Button
            type="button"
            styleModifier="primary"
            labelMessage={App.messages.buttons.ok}
            className="btn--block btn--filled"
            isInProgress={this.props.isInProgress}
            onClick={() => this.props.onClose()}
          />
        </div>
      </div>
    );
  }


  renderErrors() {
    return (
      <div>
        <div className="form-error d-flex flex-column pt-7 pb-7 pl-8 pr-8 align-items-start">
          <p><FormattedMessage {...messages.errors.businessErrors.deleteBLS.ComponentHasActiveCountries} /></p>
        </div>
        <Button
          type="button"
          styleModifier="primary"
          labelMessage={App.messages.buttons.ok}
          className="btn--block btn--filled"
          onClick={() => this.props.onClose()}
        />
      </div>
    );
  }


  render() {
    return (
      <Modal
        modalId={constants.DELETE_BLS_MODAL}
        openModalId={this.props.openModalId}
        headerMessage={messages.headers.deleteBLS}
        styleModifier="lg"
        isInProgress={this.props.isInProgress}
        onClose={this.props.onClose}
      >
        { this.renderContent() }
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  openModalId   : App.selectors.modal(state),
  deleteBLSError: selectors.deleteBLSError(state),
  isInProgress  : selectors.isDeleteBLSProgress(state),
});


const mapDispatchToProps = (dispatch) => ({
  onClose      : () => dispatch(App.actions.closeModal()),
  onDeleteBLS  : (blsName) => dispatch(actions.deleteBLS(blsName)),
  onClearResult: () => dispatch(actions.deleteBLSClear()),
});


const ConnectedDeleteBLSModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(DeleteBLSModal);

export default ConnectedDeleteBLSModal;
