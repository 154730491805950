import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import CollapsibleSection from 'components/CollapsibleSection';
import StatusChip from '../../../../../partials/StatusChip';
import messages from '../../../../../messages';


class RegionStatusCollapsibleSection extends React.PureComponent {

    static propTypes = {
      // Explicit props
      regionLog: PropTypes.object,
      className: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      // Implicit props
      // Implicit actions
    };

    static defaultProps = {
      className: '',
    }

    get schema() {
      return [
        {
          key         : 'component',
          labelMessage: messages.labels.component,
          renderer    : (component) => (
            <b>{ component.component }</b>
          ),
        },
        {
          key         : 'errorMessage',
          labelMessage: messages.labels.message,
          renderer    : (component) => (
            <p>{ component.errorMessage }</p>
          ),
        },
        {
          key         : 'status',
          labelMessage: messages.labels.status,
          renderer    : (component) => (
            <StatusChip
              status={component.status}
            />
          ),
        },
        {
          key         : 'duration',
          labelMessage: messages.labels.duration,
          renderer    : (component) => (
            <p>{component.duration.substring(3)}</p>
          ),
        },
      ];
    }

    renderRegionHeader(regionLog) {
      return (
        <div className="regionHeader">
          <b className="regionHeader__name">{regionLog.region}</b>
          <StatusChip
            status={regionLog.status}
          />
        </div>
      );
    }


    render() {
      return (
        <CollapsibleSection
          key={`component-${this.props.regionLog.region}`}
          headerMessage={() => this.renderRegionHeader(this.props.regionLog)}
          className={this.props.className}
        >
          <Table
            idKey="component"
            schema={this.schema}
            entities={this.props.regionLog.componentLogs || []}
            perPage={20}
            isPerPageOff
          />
        </CollapsibleSection>
      );
    }

}


export default RegionStatusCollapsibleSection;
