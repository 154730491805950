import includes from 'lodash/includes';
import { createSelector } from 'reselect';
import * as actionTypes from './actionTypes';
import * as constants from './constants';


/**
 * Input selectors
 */
const mainSelector = (state) => state[constants.MODULE_ID];
const sendingSelector = (state) => state[constants.MODULE_ID].sending;
// const errorsSelector = (state) => state[constants.MODULE_ID].errors;


/**
 * Selects current user's identity data
 */
export const account = createSelector(
  mainSelector,
  (state) => state.account,
);

export const scope = createSelector(
  mainSelector,
  (state) => state.account && state.account.scope,
);

export const isAuthenticated = createSelector(
  mainSelector,
  (state) => state.isAuthenticated,
);


export const authorizedRoutingsByScope = createSelector(
  mainSelector,
  (state) => state.account && state.account.scope && constants.ROUTING_BY_SCOPE[state.account.scope],
);


export const defaultRoute = createSelector(
  mainSelector,
  (state) => state.account && state.account.scope && constants.HOME_PAGE_BY_SCOPE[state.account.scope],
);


/**
 * Selects current user's identity data
 */
export const loadedIdentity = createSelector(
  mainSelector,
  (state) => state.loadedIdentity,
);


export const availableCountries = createSelector(
  mainSelector,
  (state) => state.availableCountries,
);

export const scopes = createSelector(
  mainSelector,
  (state) => state.scopes,
);

export const availableIdentities = createSelector(
  mainSelector,
  (state) => state.availableIdentities,
);


export const identities = createSelector(
  mainSelector,
  (state) => state.identities,
);

//----------------------------------------------------------------------------------------------------------------------

export const isSignOutInProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.SIGN_OUT),
);

//----------------------------------------------------------------------------------------------------------------------
