exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2dUbx{-ms-flex-direction:column;flex-direction:column;gap:32px}._15aJ5,._2dUbx{display:-ms-flexbox;display:flex}._15aJ5{-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between}._15aJ5 ._2kFRf{display:block;width:24px;height:24px;padding-right:8px}.card{padding:2.4rem 0}._1cOFh ._16FTm{cursor:pointer;display:block;width:20px;width:2rem;height:20px;height:2rem}._1cOFh ._16FTm>g{fill:#da1818;cursor:pointer}._1cOFh,._1cOFh tr td:first-child,._1cOFh tr th:first-child{padding-left:0}._1cOFh,._1cOFh tr td:last-child,._1cOFh tr th:last-child{padding-right:0}._1cOFh td.table__cell.table__cell--delete,._1cOFh th.table__cell.table__cell--delete{width:20px;padding:0}._1cOFh td.table__cell.table__cell--config,._1cOFh th.table__cell.table__cell--config{width:80px;padding:1.6rem 2.4rem}", ""]);

// exports
exports.locals = {
	"listBLS": "_2dUbx",
	"header": "_15aJ5",
	"header__addButton__icon": "_2kFRf",
	"blsTable": "_1cOFh",
	"blsTable__deleteIcon": "_16FTm"
};