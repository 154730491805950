import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import isObject from 'lodash/isObject';
import cn from 'classnames';
import { motion, AnimatePresence } from 'framer-motion';
import Link from 'components/Link';
import intlShape from 'shapes/intlShape';
import Loader from 'svg/loader.svg';


/**
 * Button component
 * @param {node} children - Content with the greatest priority
 * @param {Object} labelMessage - Intl message definition for button label which is used if no children are passed
 * @param {Object} labelMessageValues - values that are replacing variables in an intl message
 * @param {string='default','primary','success','info','warning','danger','link'} [styleModifier=default]
 *        - alternate button class modifier
 * @param {string='button','submit','reset','link'} [type=button] - button type
 * @param {string} [to=''] - if button is an anchor it's possible to add href address
 * @param {boolean} [isInProgress=false] - injected action progress indicator, generally connected
 *        with some store state
 * @param {boolean} [isDisabled=false] - flag to disable button actions
 * @param {(string|Object)} [className] - additional Component className
 * @param {func} [onClick] - injected action triggered by button click
 */
class Button extends React.PureComponent {

  static propTypes = {
    children          : PropTypes.node,
    labelMessage      : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    labelMessageValues: PropTypes.object,
    titleMessage      : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    styleModifier     : PropTypes.string,
    type              : PropTypes.oneOf(['button', 'submit', 'reset', 'link']),
    to                : PropTypes.string,
    data              : PropTypes.object,
    isInProgress      : PropTypes.bool,
    isDisabled        : PropTypes.bool,
    className         : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onClick           : PropTypes.func,
    onClickDisabled   : PropTypes.func,
    intl              : intlShape.isRequired,
    id                : PropTypes.string,
  };

  static defaultProps = {
    type        : 'button',
    to          : '',
    data        : {},
    isInProgress: false,
    isDisabled  : false,
    onClick     : () => {},
  };


  constructor(props) {
    super(props);
    this.isInitInProgress = props.isInProgress;
  }


  onClick(evt, isEnabled) {
    if (!isEnabled) {
      evt.preventDefault();
      if (this.props.isDisabled && this.props.onClickDisabled) {
        this.props.onClickDisabled(evt);
      }
      return;
    }
    this.props.onClick(evt);
  }


  get isInProgress() {
    return this.props.isInProgress;
  }


  get title() {
    const { titleMessage } = this.props;
    if (!titleMessage) {
      return null;
    }
    if (isObject(titleMessage)) {
      return this.props.intl.formatMessage(titleMessage);
    }
    return titleMessage;
  }


  renderAnchor() {
    const isEnabled = !this.props.isDisabled && !this.props.isInProgress;
    return (
      <Link
        id={this.props.id}
        to={isEnabled ? this.props.to : ''}
        className={cn(
          'btn',
          {
            [`btn--${this.props.styleModifier}`]: this.props.styleModifier,
            'btn--disabled'                     : this.props.isDisabled,
            'btn--in-progress'                  : this.props.isInProgress,
          },
          this.props.className
        )}
        {...this.props.data}
        onClick={(evt) => this.onClick(evt, isEnabled)}
      >
        <span className="btn-inner">
          { this.renderLabel() }
        </span>
      </Link>
    );
  }


  renderLoader() {
    if (!this.isInProgress) {
      return null;
    }
    return (
      <motion.div
        initial={{ x: this.isInitInProgress ? 0 : '100%' }}
        animate={{ x: 0 }}
        exit={{ x: '100%' }}
        transition={{ ease: 'easeOut', duration: 0.15 }}
        className="btn__loaderWrapper"
      >
        <Loader className="btn__loader rotatingLoader" />
      </motion.div>
    );
  }


  renderLabel() {
    const { children, intl, labelMessage, labelMessageValues } = this.props;
    let label = null;
    if (children) {
      label = children;
    }

    if (labelMessage) {
      label = intl.formatMessage(labelMessage, labelMessageValues);
    }

    if (!label) {
      return null;
    }

    return (
      <motion.div
        initial={this.isInitInProgress ? { x: '-100%' } : { x: 0 }}
        animate={this.isInProgress ? { x: '-100%' } : { x: 0 }}
        transition={{ ease: 'easeOut', duration: 0.15 }}
        className="btn__labelWrapper"
      >
        { label }
      </motion.div>
    );
  }


  renderButton() {
    const isEnabled = (!this.props.isDisabled || !!this.props.onClickDisabled) && !this.props.isInProgress;
    return (
      /* eslint-disable react/button-has-type */
      <button
        id={this.props.id}
        type={this.props.type}
        className={cn(
          'btn',
          {
            [`btn--${this.props.styleModifier}`]: this.props.styleModifier,
            'btn--disabled'                     : this.props.isDisabled,
            'btn--in-progress'                  : this.props.isInProgress,
          },
          this.props.className
        )}
        {...this.props.data}
        disabled={!isEnabled}
        onClick={(evt) => this.onClick(evt, isEnabled)}
        title={this.title}
      >
        <span className="btn-inner">
          { this.renderLabel() }
          <AnimatePresence>
            { this.renderLoader() }
          </AnimatePresence>
        </span>
      </button>
    );
  }


  render() {
    if (this.props.type === 'link') {
      return this.renderAnchor();
    }
    return this.renderButton();
  }

}


export default injectIntl(Button);
