exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1YaBd{display:-ms-flexbox;display:flex;gap:24px;gap:2.4rem}.s7K21 .table__wrapper{padding:0;overflow-y:auto;height:45vh}.s7K21 .table__cell.table__cell--languageNames{width:auto}.s7K21 .table__cell.table__cell--term{padding-right:16px;width:auto}", ""]);

// exports
exports.locals = {
	"review__btnContainer": "_1YaBd",
	"validationFailed__tabView": "s7K21"
};