export const MODULE_ID = 'businessLogicSystem';

export const ADD_BLS_MODAL = `${MODULE_ID}/addBLSModal`;
export const ADD_BLS_FORM = `${MODULE_ID}/addBLSForm`;

export const ADD_BLS_REGION_MODAL = `${MODULE_ID}/addBLSRegionModal`;
export const ADD_BLS_REGION_FORM = `${MODULE_ID}/addBLSRegionForm`;

export const DELETE_BLS_MODAL = `${MODULE_ID}/deleteBLSModal`;

export const UPDATE_BLS_CONFIGURATION_FAILED_MODAL = `${MODULE_ID}/updateBLSConfigurationFailedModal`;

export const UNSPECIFIED_REGION_NAME = 'Unspecified';
