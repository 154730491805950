exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".form-group{margin:3.2rem 0 0}._1bvlr .input-container:before{content:\"+\";position:absolute;opacity:.7;font-size:14px;font-size:1.4rem;height:calc(1.375em + 1.4rem + 2px);padding-top:10px;padding-left:6px}", ""]);

// exports
exports.locals = {
	"callingCode": "_1bvlr"
};