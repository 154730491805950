import { SIGN_OUT_SUCCESS } from 'modules/Account/actionTypes';
import {
  registerAction,
  unregisterAction,
} from 'helpers/reducerTools';
import * as actionTypes from './actionTypes';


const initialState = {
  components: [],
  fetching  : [],
  sending   : [],
  errors    : [],
};


export default function reducer(state = { ...initialState }, action) {

  switch (action.type) {


    case actionTypes.FETCH_ORGANIZATION_APPROVERS: {
      return {
        ...state,
        fetching: registerAction(state.fetching, actionTypes.FETCH_ORGANIZATION_APPROVERS),
        errors  : unregisterAction(state.errors, actionTypes.FETCH_ORGANIZATION_APPROVERS),
      };
    }
    case actionTypes.FETCH_ORGANIZATION_APPROVERS_SUCCESS: {
      const { organizationApproversPage } = action.payload;
      const { totalRecords,
        records } = organizationApproversPage;
      return {
        ...state,
        organizationApprovers     : records,
        organizationApproversTotal: totalRecords,
        fetching                  : unregisterAction(state.fetching, actionTypes.FETCH_ORGANIZATION_APPROVERS),
      };
    }
    case actionTypes.FETCH_ORGANIZATION_APPROVERS_ERROR: {
      return {
        ...state,
        fetching: unregisterAction(state.fetching, actionTypes.FETCH_ORGANIZATION_APPROVERS),
        errors  : registerAction(state.errors, actionTypes.FETCH_ORGANIZATION_APPROVERS),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.ADD_APPROVER: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.ADD_APPROVER),
        errors : unregisterAction(state.errors, actionTypes.ADD_APPROVER),
      };
    }
    case actionTypes.ADD_APPROVER_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.ADD_APPROVER),
      };
    }
    case actionTypes.ADD_APPROVER_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.ADD_APPROVER),
        errors : registerAction(state.errors, actionTypes.ADD_APPROVER),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case actionTypes.UPDATE_APPROVER: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.UPDATE_APPROVER),
        errors : unregisterAction(state.errors, actionTypes.UPDATE_APPROVER),
      };
    }
    case actionTypes.UPDATE_APPROVER_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_APPROVER),
      };
    }
    case actionTypes.UPDATE_APPROVER_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.UPDATE_APPROVER),
        errors : registerAction(state.errors, actionTypes.UPDATE_APPROVER),
      };
    }

    //------------------------------------------------------------------------------------------------------------------


    case actionTypes.DELETE_APPROVER: {
      return {
        ...state,
        sending: registerAction(state.sending, actionTypes.DELETE_APPROVER),
        errors : unregisterAction(state.errors, actionTypes.DELETE_APPROVER),
      };
    }
    case actionTypes.DELETE_APPROVER_SUCCESS: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.DELETE_APPROVER),
      };
    }
    case actionTypes.DELETE_APPROVER_ERROR: {
      return {
        ...state,
        sending: unregisterAction(state.sending, actionTypes.DELETE_APPROVER),
        errors : registerAction(state.errors, actionTypes.DELETE_APPROVER),
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    case SIGN_OUT_SUCCESS: {
      return {
        ...initialState,
      };
    }

    //------------------------------------------------------------------------------------------------------------------

    default:
      return state;

  }
}
