import filter from 'lodash/filter';
import some from 'lodash/some';


// Country manager can have limited access to countries list.

/**
 * This function reduce array of all approver countriesCode to those witch are accessible for user.
 * @param {array} approverCountryCodes array of all countries codes to which approver is assigned
 * @param {array} countriesCodesAvailableForUser
 * @returns {array}
 */
export function getApproverCountriesCodesByUserScope(approverCountries, countriesAvailableForUser) {
  const approverCountryCodes = approverCountries.map(((country) => country.code));
  const countriesCodesAvailableForUser = countriesAvailableForUser.map(((country) => country.code));
  return filter(approverCountryCodes,
    (countryCode) => countriesCodesAvailableForUser.includes(countryCode)
  );
}

export function hasAssignedCountriesOutOfUserScope(approverCountries, countriesAvailableForUser) {
  const approverCountryCodes = approverCountries.map(((country) => country.code));
  const countriesCodesAvailableForUser = countriesAvailableForUser.map(((country) => country.code));
  return some(approverCountryCodes,
    (countryCode) => !countriesCodesAvailableForUser.includes(countryCode)
  );
}

