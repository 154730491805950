import { defineMessages } from 'react-intl';


export default defineMessages({

  headers: {
    businessLogicSystems        : { id: 'businessLogicSystems.headers.businessLogicSystems' },
    addNewBLS                   : { id: 'businessLogicSystems.headers.addNewBLS' },
    addNewRegionToBLS           : { id: 'businessLogicSystems.headers.addNewRegionToBLS' },
    regions                     : { id: 'businessLogicSystems.headers.regions' },
    region                      : { id: 'businessLogicSystems.headers.region' },
    country                     : { id: 'businessLogicSystems.headers.country' },
    listOfCountries             : { id: 'businessLogicSystems.headers.listOfCountries' },
    deleteBLS                   : { id: 'businessLogicSystems.headers.deleteBLS' },
    updateBLSConfigurationFailed: { id: 'businessLogicSystems.headers.updateBLSConfigurationFailed' },
  },
  labels: {
    blsName   : { id: 'businessLogicSystems.labels.blsName' },
    addNewBLS : { id: 'businessLogicSystems.labels.addNewBLS' },
    regionName: { id: 'businessLogicSystems.headers.regionName' },
  },
  buttons: {
    configRegions: { id: 'businessLogicSystems.buttons.configRegions' },
    addBLS       : { id: 'businessLogicSystems.buttons.addBLS' },
    addNewBLS    : { id: 'businessLogicSystems.buttons.addNewBLS' },
    backToListBLS: { id: 'businessLogicSystems.buttons.backToListBLS' },
    addBLSRegion : { id: 'businessLogicSystems.buttons.addBLSRegion' },
  },
  placeholders: {
    blsName                      : { id: 'businessLogicSystems.placeholders.blsName' },
    selectCountriesToAssignRegion: { id: 'businessLogicSystems.placeholders.selectCountriesToAssignRegion' },
    assignRegion                 : { id: 'businessLogicSystems.placeholders.assignRegion' },
    regionName                   : { id: 'businessLogicSystems.placeholders.regionName' },
  },
  infos: {
    addNewBLS                      : { id: 'businessLogicSystems.infos.addNewBLS' },
    unsavedConfigurationOnClickBack: { id: 'businessLogicSystems.infos.unsavedConfigurationOnClickBack' },
    listOfCountries                : { id: 'businessLogicSystems.infos.listOfCountries' },
    addNewBLSRegion                : { id: 'businessLogicSystems.infos.addNewBLSRegion' },
    deleteBLSSuccess               : { id: 'businessLogicSystems.infos.deleteBLSSuccess' },
    confirmDeleteBLS               : { id: 'businessLogicSystems.infos.confirmDeleteBLS' },
  },

  errors: {
    businessErrors: {
      updateBLSConfiguration: {
        AssignedInDifferentRegion:
          { id: 'businessLogicSystems.errors.businessErrors.updateBLSConfiguration.AssignedInDifferentRegion' },
        NotAssignedToAnyRegion:
          { id: 'businessLogicSystems.errors.businessErrors.updateBLSConfiguration.NotAssignedToAnyRegion' },
      },
      deleteBLS: {
        ComponentHasActiveCountries:
        { id: 'businessLogicSystems.errors.businessErrors.deleteBLS.ComponentHasActiveCountries' },
      },
    },
  },
});
