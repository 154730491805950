import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import messages from '../../../../../../../messages';


class Terms extends React.Component {

  static propTypes = {
    // Explicit props
    missingTerms: PropTypes.array,
    className   : PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }


  static defaultProps = {
    className: '',
  };


  get schema() {
    return [
      {
        key         : 'name',
        labelMessage: messages.labels.term,
        renderer    : (term) => (
          <p>
            {term.name}
          </p>
        ),
      },
    ];
  }


  render() {
    return (
      <Table
        idKey="name"
        className={this.props.className}
        schema={this.schema}
        entities={this.props.missingTerms.map((term) => ({ name: term }))}
        perPage={-1}
        isPerPageOff
      />
    );
  }

}

export default Terms;
