exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._29U7g{display:-ms-flexbox;display:flex;gap:24px;gap:2.4rem;margin:3.2rem 0}._2WwOc{line-height:24px;line-height:2.4rem}._1IqtZ{max-height:calc(72vh - 66px);overflow-y:auto}.m2k2W{width:100%;max-width:100%!important;border-bottom:1px solid #e0e8f2!important}.m2k2W .regionHeader{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center}.m2k2W .regionHeader .regionHeader__name{width:164px;width:16.4rem}.m2k2W .regionHeader .regionHeader__status{width:88px;width:8.8rem}.m2k2W div.section{width:100%;max-width:100%;margin-right:0;padding:0}.m2k2W .table__cell{padding:.8rem 2.4rem .8rem 0}.m2k2W .table__cell--component{width:150px;width:15rem}.m2k2W .table__cell--errorMessage{width:560px!important;width:56rem!important}.m2k2W .table__cell--status{width:80px;width:8rem}.m2k2W .table__cell--duration{width:80px;width:8rem;margin-right:0}.m2k2W .table__wrapper{padding:0}.Am-Nr{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;gap:16px;gap:1.6rem;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;padding:3.2rem 1.6rem;background:#fdf9e6;color:#806b20;border-radius:8px}", ""]);

// exports
exports.locals = {
	"publish_content": "_29U7g",
	"publish__content__cancelPublishReasons": "_2WwOc",
	"publish__content_logs": "_1IqtZ",
	"publish__content__regionStatusCollapsibleSection": "m2k2W",
	"publish__content--loading": "Am-Nr"
};