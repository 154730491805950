import { MODULE_ID } from './constants';


export const FETCH_BLS_LIST = `${MODULE_ID}/FETCH_BLS_LIST`;
export const FETCH_BLS_LIST_SUCCESS = `${MODULE_ID}/FETCH_BLS_LIST_SUCCESS`;
export const FETCH_BLS_LIST_ERROR = `${MODULE_ID}/FETCH_BLS_LIST_ERROR`;

export const ADD_BLS = `${MODULE_ID}/ADD_BLS`;
export const ADD_BLS_SUCCESS = `${MODULE_ID}/ADD_BLS_SUCCESS`;
export const ADD_BLS_ERROR = `${MODULE_ID}/ADD_BLS_ERROR`;
export const ADD_BLS_CLEAR = `${MODULE_ID}/ADD_BLS_CLEAR`;

export const FETCH_BLS_CONFIGURATION_REGIONS = `${MODULE_ID}/FETCH_BLS_CONFIGURATION_REGIONS`;
export const FETCH_BLS_CONFIGURATION_REGIONS_SUCCESS = `${MODULE_ID}/FETCH_BLS_CONFIGURATION_REGIONS_SUCCESS`;
export const FETCH_BLS_CONFIGURATION_REGIONS_ERROR = `${MODULE_ID}/FETCH_BLS_CONFIGURATION_REGIONS_ERROR`;

export const UPDATE_BLS_CONFIGURATION_REGIONS = `${MODULE_ID}/UPDATE_BLS_CONFIGURATION_REGIONS`;
export const UPDATE_BLS_CONFIGURATION_REGIONS_SUCCESS = `${MODULE_ID}/UPDATE_BLS_CONFIGURATION_REGIONS_SUCCESS`;
export const UPDATE_BLS_CONFIGURATION_REGIONS_ERROR = `${MODULE_ID}/UPDATE_BLS_CONFIGURATION_REGIONS_ERROR`;
export const UPDATE_BLS_CONFIGURATION_REGIONS_CLEAR = `${MODULE_ID}/UPDATE_BLS_CONFIGURATION_REGIONS_CLEAR`;

export const DELETE_BLS = `${MODULE_ID}/DELETE_BLS`;
export const DELETE_BLS_SUCCESS = `${MODULE_ID}/DELETE_BLS_SUCCESS`;
export const DELETE_BLS_ERROR = `${MODULE_ID}/DELETE_BLS_ERROR`;
export const DELETE_BLS_CLEAR = `${MODULE_ID}/DELETE_BLS_CLEAR`;
