import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from 'components/Modal';
import App from 'modules/App';
import * as constants from '../../../constants';
import messages from '../../../messages';
import AddBLSRegionForm from './AddBLSRegionForm';


class AddBLSRegionModal extends React.PureComponent {

  static propTypes = {
    onSubmit       : PropTypes.func,
    // Explicit props
    existingRegions: PropTypes.arrayOf(PropTypes.string),
    // Implicit props
    openModalId    : PropTypes.string,
    isInProgress   : PropTypes.bool,
    hasErrors      : PropTypes.bool,
    // Implicit actions
    onClose        : PropTypes.func,
  };


  renderContent() {
    return (
      <AddBLSRegionForm
        isInProgress={this.props.isInProgress}
        hasErrors={this.props.hasErrors}
        onClose={this.props.onClose}
        onSubmit={this.props.onSubmit}
        existingRegions={this.props.existingRegions}
      />
    );
  }


  render() {
    return (
      <Modal
        modalId={constants.ADD_BLS_REGION_MODAL}
        openModalId={this.props.openModalId}
        headerMessage={messages.headers.addNewRegionToBLS}
        styleModifier="lg"
        onClose={this.props.onClose}
      >
        { this.renderContent() }
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  openModalId: App.selectors.modal(state),
});


const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(App.actions.closeModal(constants.ADD_BLS_MODAL)),
});


const ConnectedAddBLSRegionModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddBLSRegionModal);


export default ConnectedAddBLSRegionModal;
