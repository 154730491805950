import React from 'react';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import PropTypes from 'prop-types';
import styles from './Tooltip.pcss';


class Tooltip extends React.PureComponent {

    static propTypes = {
      isVisible: PropTypes.bool,
      message  : PropTypes.object,
      children : PropTypes.object,
    };

    render() {
      return (
        <div className={styles.tooltip_container}>
          <div
            className={cn(styles.tooltip_box, {
              [styles['tooltip_box--visible']]: this.props.isVisible,
            })}
          >
            {this.props.message}
            <span className={styles.tooltip_arrow} />
          </div>
          <div>
            {this.props.children}
          </div>
        </div>
      );
    }

}

export default withStyles(styles)(Tooltip);
