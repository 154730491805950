import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { motion, AnimatePresence } from 'framer-motion';
import cn from 'classnames';
import isString from 'lodash/isString';
import isFunction from 'lodash/isFunction';
import X from 'svg/x.svg';
import App from 'modules/App';


/**
 * Modal component
 *
 * @param {string} modalId - modal unique id
 * @param {string} openModalId - current open modal unique id
 * @param {string='md','lg','xl} - modal width modifier
 * @param {Element} children - modal content
 * @param {number} isInProgress - operations in progress indicator
 * @param {boolean} isDisabled - actions disabling
 * @param {function} onClose - function that is triggered if modal is being closed without confirm
 */
class Modal extends React.PureComponent {

  static propTypes = {
    // Explicit props
    modalId            : PropTypes.string.isRequired,
    openModalId        : PropTypes.string,
    styleModifier      : PropTypes.oneOf(['md', 'lg', 'xl']),
    headerMessage      : PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.func]),
    children           : PropTypes.node,
    isInProgress       : PropTypes.bool,
    isDisabled         : PropTypes.bool,
    isOverlayClickClose: PropTypes.bool,
    // Explicit actions
    onClose            : PropTypes.func,
  };

  static defaultProps = {
    isInProgress       : false,
    isDisabled         : false,
    isOverlayClickClose: true,
  };


  onClose(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (!this.props.onClose || this.props.isInProgress || this.props.isDisabled) {
      return;
    }
    this.props.onClose();
  }

  onOverlayClick(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    if (this.props.isOverlayClickClose) {
      this.onClose(evt);
    }
  }

  get isOpen() {
    return this.props.openModalId === this.props.modalId;
  }


  renderCloseBtn() {
    if (!this.props.onClose) {
      return <div className="modal__close" />;
    }
    const className = `modal__close__btn ${this.props.isDisabled
      ? 'modal__close__btn--disabled'
      : 'modal__close__btn--enabled'}`;
    return (
      <div className="modal__close">
        <button
          type="button"
          className={className}
          onClick={(evt) => this.onClose(evt)}
          isdisabled={this.props.isDisabled ? 'true' : 'false'}
        >
          <span className="btn-inner">
            <div className="modal__close__btn__iconWrapper">
              <X className="modal__close__btn__icon" />
            </div>
          </span>
        </button>
      </div>
    );
  }


  renderHeader() {
    if (!this.props.headerMessage) {
      return null;
    }
    let header = null;
    if (isString(this.props.headerMessage)) {
      header = this.props.headerMessage;
    } else if (isFunction(this.props.headerMessage)) {
      header = this.props.headerMessage();
    } else {
      header = <FormattedMessage {...this.props.headerMessage} />;
    }


    return (
      <div>
        <h2 className="modal__header brand__h1">
          {header}
        </h2>
        <App.components.AlertsBus className="mb-4" />
      </div>
    );
  }


  renderContent() {
    return (
      <div className={cn('modal__contentWrapper', { 'mt-5': !this.props.onClose })}>
        <div className="modal__content">
          { this.renderHeader() }
          { this.props.children }
        </div>
      </div>
    );
  }


  renderModal() {
    if (!this.isOpen) {
      return null;
    }

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ ease: 'easeOut', duration: 0.15 }}
        className={cn('modal', { [`modal--${this.props.styleModifier}`]: this.props.styleModifier })}
      >
        <div
          className="body-overlay"
          role="presentation"
          onClick={(evt) => this.onOverlayClick(evt)}
        />
        <div className="modal__container">
          <div className="modal__body">
            { this.renderCloseBtn() }
            { this.renderContent() }
          </div>
        </div>
      </motion.div>
    );
  }


  render() {
    return (
      <AnimatePresence>
        { this.renderModal() }
      </AnimatePresence>
    );
  }

}


export default Modal;
