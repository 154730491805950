import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import Modal from 'components/Modal';
import Button from 'components/Form/Button';
import App from 'modules/App';
import * as constants from '../../../constants';
import * as actions from '../../../actions';
import * as selectors from '../../../selectors';
import messages from '../../../messages';
import styles from './EnableCountryModal.pcss';


class EnableCountryModal extends React.PureComponent {

  static propTypes = {
    // Explicit props
    country            : PropTypes.object,
    // Explicit actions
    // Implicit props
    isInProgress       : PropTypes.bool,
    enableCountryErrors: PropTypes.array,
    openModalId        : PropTypes.string,
    // Implicit actions
    onClose            : PropTypes.func,
    onClearResult      : PropTypes.func,
    onEnableCountry    : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      isValidated: false,
    };
  }


  componentDidUpdate(prevProps) {
    if (prevProps.isInProgress && !this.props.isInProgress) {
      this.onValidated();
    }
  }


  componentWillUnmount() {
    this.props.onClearResult();
    this.setState({ isValidated: false });
  }


  onValidated() {
    this.setState({ isValidated: true });
  }

  renderDetails(error) {
    return (
      <li key={error}><FormattedMessage {...messages.errors.enableCountry[error]} /> </li>
    );
  }


  renderContent() {
    if (!this.state.isValidated) return this.renderToConfirm();
    if (this.props.enableCountryErrors) return this.renderErrors();
    return this.renderSuccess();
  }


  renderToConfirm() {
    const { englishName, code } = this.props.country;
    return (
      <div>
        <p className="text--paragraph pr-2 mb-3"><FormattedMessage
          {...messages.infos.confirmEnableCountry}
          values={{ countryName: englishName }}
        />
        </p>
        <div className="d-flex">
          <Button
            type="button"
            styleModifier="primary"
            labelMessage={App.messages.buttons.cancel}
            className="btn--block mr-8"
            isInProgress={this.props.isInProgress}
            onClick={() => this.props.onClose()}
          />
          <Button
            type="button"
            styleModifier="primary"
            labelMessage={App.messages.buttons.confirm}
            className="btn--block btn--filled"
            isInProgress={this.props.isInProgress}
            onClick={() => this.props.onEnableCountry(code)}
          />
        </div>
      </div>
    );
  }


  renderSuccess() {
    return (
      <div>
        <div className="form-success d-flex flex-column pt-7 pb-7 pl-8 pr-8 align-items-center">
          <p><FormattedMessage {...messages.infos.enableCountrySuccess} /></p>
        </div>
        <div className="d-flex">
          <Button
            type="button"
            styleModifier="primary"
            labelMessage={App.messages.buttons.confirm}
            className="btn--block btn--filled"
            isInProgress={this.props.isInProgress}
            onClick={() => this.props.onClose()}
          />
        </div>
      </div>
    );
  }


  renderErrors() {
    const errors = this.props.enableCountryErrors.map((error) => this.renderDetails(error));
    return (
      <div className={styles.enableCountryErrorInfo}>
        <div className="form-error d-flex flex-column pt-7 pb-7 pl-8 pr-8 align-items-start">
          <p><b><FormattedMessage {...messages.infos.enableCountryFailed} /></b></p>
          <div>{errors}</div>
        </div>
        <Button
          type="button"
          styleModifier="primary"
          labelMessage={App.messages.buttons.ok}
          className="btn--block btn--filled"
          onClick={() => this.props.onClose()}
        />
      </div>
    );
  }


  render() {
    return (
      <Modal
        modalId={constants.ENABLE_COUNTRY_MODAL}
        openModalId={this.props.openModalId}
        headerMessage={messages.headers.enableCountryFailed}
        styleModifier="lg"
        isInProgress={this.props.isInProgress}
        onClose={this.props.onClose}
      >
        { this.renderContent() }
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  openModalId        : App.selectors.modal(state),
  enableCountryErrors: selectors.enableCountryErrors(state),
  isInProgress       : selectors.isEnableCountryProgress(state),
});


const mapDispatchToProps = (dispatch) => ({
  onClose        : () => dispatch(App.actions.closeModal()),
  onEnableCountry: (countryCode) => dispatch(actions.enableCountry(countryCode)),
  onClearResult  : () => dispatch(actions.enableCountryClear()),
});


const ConnectedEnableCountryModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EnableCountryModal);

export default withStyles(styles)(ConnectedEnableCountryModal);
