import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import Button from 'components/Form/Button';
import Modal from 'components/Modal';
import App from 'modules/App';
import * as constants from '../../../../../constants';
import messages from '../../../../../messages';
import styles from './MFADisableWarningModal.pcss';


class MFADisableWarningModal extends React.PureComponent {

  static propTypes = {
    // Explicit props
    mfaConfirmMode: PropTypes.oneOf(['enableMFASMS', 'disableMFASMS', 'disableMFAEmail']),
    // Implicit props
    openModalId   : PropTypes.string,
    // Implicit actions
    onClose       : PropTypes.func,
    onConfirm     : PropTypes.func,

  };


  handleConfirm = () => {
    this.props.onConfirm();
  }

  renderEnableMFASMSContent() {
    return (
      <>
        <p className="text--paragraph mt-6 mb-8">
          <FormattedMessage {...messages.infos.MFAEnableSMSWarning} />
        </p>
        <div className="d-flex">
          <Button
            type="button"
            styleModifier="primary"
            labelMessage={App.messages.buttons.ok}
            className="btn--block btn--filled mt-0"
            onClick={this.handleConfirm}
          />
        </div>
      </>
    );
  }


  renderDisableMFASMSContent() {
    return (
      <>
        <p className="text--paragraph mt-6 mb-8">
          <FormattedMessage {...messages.infos.MFADisableSMSWarning} />
        </p>
        <div className="d-flex">
          <Button
            type="button"
            styleModifier="primary"
            labelMessage={App.messages.buttons.cancel}
            className="btn--block mr-8 mt-0"
            onClick={() => this.props.onClose()}
          />
          <Button
            type="button"
            styleModifier="primary"
            labelMessage={App.messages.buttons.confirm}
            className="btn--block btn--filled mt-0"
            onClick={this.handleConfirm}
          />
        </div>
      </>
    );
  }


  renderDisableMFAEmailContent() {
    return (
      <>
        <p className="text--paragraph mt-6 mb-8">
          <FormattedMessage {...messages.infos.MFADisableEmailWarning} />
        </p>
        <div className="d-flex">
          <Button
            type="button"
            styleModifier="primary"
            labelMessage={App.messages.buttons.cancel}
            className="btn--block mr-8 mt-0"
            onClick={() => this.props.onClose()}
          />
          <Button
            type="button"
            styleModifier="primary"
            labelMessage={App.messages.buttons.confirm}
            className="btn--block btn--filled mt-0"
            onClick={this.handleConfirm}
          />
        </div>
      </>
    );
  }

  render() {
    const { mfaConfirmMode } = this.props;
    const isEnableWarning = mfaConfirmMode === 'enableMFASMS';
    let content = null;
    if (mfaConfirmMode === 'enableMFASMS') {
      content = this.renderEnableMFASMSContent();
    } else if (mfaConfirmMode === 'disableMFASMS') {
      content = this.renderDisableMFASMSContent();
    } else if (mfaConfirmMode === 'disableMFAEmail') {
      content = this.renderDisableMFAEmailContent();
    }
    return (
      <Modal
        modalId={constants.MFA_WARNING_MODAL}
        openModalId={this.props.openModalId}
        styleModifier="md"
        headerMessage={isEnableWarning
          ? messages.headers.MFAEnableSMSWarning
          : messages.headers.MFADisableWarning}
        onClose={this.props.onClose}
      >
        {content}
      </Modal>
    );
  }

}


export default withStyles(styles)(MFADisableWarningModal);
