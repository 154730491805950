import { createSelector } from 'reselect';
import includes from 'lodash/includes';
import * as constants from './constants';
import * as actionTypes from './actionTypes';

/**
 * Input selectors
 */
const mainSelector = (state) => state[constants.MODULE_ID];
const fetchingSelector = (state) => state[constants.MODULE_ID].fetching;
const errorsSelector = (state) => state[constants.MODULE_ID].errors;
const sendingSelector = (state) => state[constants.MODULE_ID].sending;


export const organizationApprovers = createSelector(
  mainSelector,
  (state) => state.organizationApprovers,
);

export const organizationApproversTotal = createSelector(
  mainSelector,
  (state) => state.organizationApproversTotal,
);

export const isFetchOrganizationApproversProgress = createSelector(
  fetchingSelector,
  (state) => includes(state, actionTypes.FETCH_ORGANIZATION_APPROVERS),
);

export const isAddApproverProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.ADD_APPROVER),
);

export const hasAddApproverErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.ADD_APPROVER),
);

export const isUpdateApproverProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.UPDATE_APPROVER),
);

export const hasUpdateApproverErrors = createSelector(
  errorsSelector,
  (state) => includes(state, actionTypes.UPDATE_APPROVER),
);


export const isDeleteApproverProgress = createSelector(
  sendingSelector,
  (state) => includes(state, actionTypes.DELETE_APPROVER),
);
