exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3wh-g{height:72vh}", ""]);

// exports
exports.locals = {
	"snapshotDetailsModal__content": "_3wh-g"
};