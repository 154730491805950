import PropTypes from 'prop-types';


export const country = PropTypes.shape({
  code               : PropTypes.string.isRequired,
  defaultLanguageCode: PropTypes.string,
  englishName        : PropTypes.string.isRequired,
  isActive           : PropTypes.bool.isRequired,
});

export const language = PropTypes.shape({
  code       : PropTypes.string.isRequired,
  englishName: PropTypes.string.isRequired,
});

export const generalSettings = PropTypes.shape({
  defaultLanguageCode: PropTypes.string.isRequired,
  firstDayOfWeek     : PropTypes.string.isRequired,
  isPrivateCloud     : PropTypes.bool.isRequired,
  isLicenceMandatory : PropTypes.bool.isRequired,
  androidStoreURL    : PropTypes.string,
  iosStoreURL        : PropTypes.string,
});

export const countryConfiguration = PropTypes.shape({
  generalSettings: PropTypes.objectOf(generalSettings),
});
