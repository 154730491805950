import * as actions from './actions';
import * as actionTypes from './actionTypes';
import * as constants from './constants';
import * as selectors from './selectors';
import * as shapes from './shapes';
import reducer from './reducer';
import sagas from './sagas';
import messages from './messages';
import SignOutSuccessStatement from './components/SignOutSuccessStatement';
import WelcomeStatement from './components/WelcomeStatement';

import LogoutBtn from './partials/LogoutBtn';


const components = {
  SignOutSuccessStatement,
  WelcomeStatement,
};

const partials = {
  LogoutBtn,
};


export default {
  actions,
  actionTypes,
  components,
  constants,
  partials,
  reducer,
  selectors,
  sagas,
  shapes,
  messages,
};
