import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import find from 'lodash/find';
import Input from 'components/Form/Input';


class UniformChoice extends React.PureComponent {

  static propTypes = {
    // Explicit props
    id        : PropTypes.string.isRequired,
    inputValue: PropTypes.string.isRequired,
    value     : PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    attributes: PropTypes.object,
    isDisabled: PropTypes.bool,
    isMultiple: PropTypes.bool,
    isRadio   : PropTypes.bool,
    // Explicit Actions
    onChange  : PropTypes.func.isRequired,
    onBlur    : PropTypes.func,
  };


  static defaultProps = {
    value     : '',
    attributes: {},
    isDisabled: false,
    isMultiple: false,
    isRadio   : false,
    onBlur    : () => {},
  };


  onChange(input) {
    if (!this.props.isMultiple) {
      return this.props.onChange({
        id   : this.props.id,
        value: input.value,
      });
    }
    let value = this.props.value ? this.props.value : [];
    if (input.value) {
      value = [...value, input.value];
    } else {
      value = value.filter((v) => v !== this.props.inputValue);
    }

    return this.props.onChange({
      id: this.props.id,
      value,
    });
  }


  get isMultiple() {
    return this.props.isMultiple && !this.props.isRadio;
  }


  get value() {
    if (!this.isMultiple) {
      return this.props.value;
    }
    return find(this.props.value, (i) => i === this.props.inputValue.toString());
  }


  get isChecked() {
    return this.value === this.props.inputValue;
  }


  render() {
    const id = `${this.props.id}-${this.props.inputValue}`;
    const attributes = {
      ...this.props.attributes,
      checked: this.isChecked,
    };
    return (
      <div
        className={cn({
          'uniform-checker': !this.props.isRadio,
          'uniform-choice' : this.props.isRadio,
          disabled         : this.props.isDisabled,
        })}
      >
        <div className={cn('form-input__hover', { disabled: this.props.isDisabled })} />
        <span className={cn({ checked: this.isChecked })}>
          <Input
            id={id}
            type={this.props.isRadio ? 'radio' : 'checkbox'}
            value={this.props.inputValue}
            formControl={false}
            attributes={attributes}
            onChange={(input) => this.onChange(input)}
            onBlur={this.props.onBlur}
            isDisabled={this.props.isDisabled}
          />
        </span>
      </div>
    );
  }

}


export default UniformChoice;
