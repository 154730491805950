export const MODULE_ID = 'publishSnapshot';

export const SNAPSHOT_DETAILS_MODAL = `${MODULE_ID}/snapshotDetailsModal`;
export const PUBLISH_MODAL = `${MODULE_ID}/publishModal`;
export const PUBLISH_PENDING_MODAL = `${MODULE_ID}/PUBLISH_PENDING_MODAL`;

export const CHIP_STYLE_BY_STATUS = {
  publishing: 'pending',
  published : 'success',
  failed    : 'error',
  created   : 'inactive',
  success   : 'success',
};


export const PUBLISH_SNAPSHOT_NOTIFICATIONS = {
  UpdatePublishSnapshots: 'UpdatePublishSnapshots',
  UpdatePublishProgress : 'UpdatePublishProgress',
};

export const PUBLISH_SNAPSHOT_SEND = {
  JoinPublishSnapshotPage    : 'JoinPublishSnapshotPage',
  LeavePublishSnapshotPage   : 'LeavePublishSnapshotPage',
  JoinPublishSnapshotProcess : 'JoinPublishSnapshotProcess',
  LeavePublishSnapshotProcess: 'LeavePublishSnapshotProcess',
};

export const PUBLISH_SNAPSHOT_INVOKE = {
  IsPublishInProgress: 'IsPublishInProgress',
};
