exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3R8CU{height:72vh;display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;-ms-flex-direction:column;flex-direction:column}", ""]);

// exports
exports.locals = {
	"snapshotDetailsModal__content": "_3R8CU"
};