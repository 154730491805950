exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3wpBe{width:650px;width:65rem}.gIfc1:after{color:#787676;font-size:14px;font-size:1.4rem;position:absolute;bottom:4px;height:20px}html[dir=ltr] .gIfc1:after{right:12px}html[dir=rtl] .gIfc1:after{left:12px}.Hgcu5:after{content:\"mg/dL\"}.KS18B:after{content:\"mmol/L\"}", ""]);

// exports
exports.locals = {
	"glucoseRanges": "_3wpBe",
	"glucoseLevel__unitSymbol": "gIfc1",
	"glucoseLevel__unitSymbol--mgdl": "Hgcu5",
	"glucoseLevel__unitSymbol--mmolL": "KS18B"
};