
import { HubConnectionBuilder, JsonHubProtocol, LogLevel } from '@microsoft/signalr'; // HttpTransportType
import { select, getContext } from 'redux-saga/effects';
import * as selectors from 'modules/App/selectors';
import AuthService from './AuthService';


let hubConnection = null;

function* getHubConnection(signalRHub) {
  if (hubConnection) {
    return hubConnection;
  }
  const options = {
    accessTokenFactory: () => AuthService.getAccessToken(),
  };
  const signalRUrl = yield getContext('signalRUrl');
  const region = yield select(selectors.regionName);
  const url = `${signalRUrl}/${region}/${signalRHub}`;
  hubConnection = new HubConnectionBuilder()
    .withUrl(url, options)
    .withHubProtocol(new JsonHubProtocol())
    .withAutomaticReconnect([10000, 30000, 120000, 600000])
    .configureLogging(__DEV__ ? LogLevel.Debug : LogLevel.Information)
    .build();
  return hubConnection;
}

function removeHubConnection() {
  hubConnection = null;
}

export default {
  getHubConnection,
  removeHubConnection,
};
