import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import Button from 'components/Form/Button';
import App from 'modules/App';
import styles from './SubmitOrDiscardButtonsContainer.pcss';


class SubmitOrDiscardButtonsContainer extends React.PureComponent {

    static propTypes = {
      // Explicit props
      isSaveEnable         : PropTypes.bool,
      isDiscardEnable      : PropTypes.bool,
      isInProgress         : PropTypes.bool,
      additionalInfoMessage: PropTypes.object,
      // Implicit props
    }


    render() {
      return (
        <div className={styles.submitOrDiscardButtonsContainer}>
          <Button
            type="reset"
            styleModifier="primary"
            labelMessage={App.messages.buttons.cancel}
            isDisabled={!this.props.isDiscardEnable}
            isInProgress={this.props.isInProgress}
            className="btn"
          />
          <Button
            type="submit"
            styleModifier="primary"
            labelMessage={App.messages.buttons.save}
            className="btn btn--filled"
            isDisabled={!this.props.isSaveEnable}
            isInProgress={this.props.isInProgress}
          />
          {this.props.additionalInfoMessage}
        </div>
      );
    }

}


export default withStyles(styles)(SubmitOrDiscardButtonsContainer);
