exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._2lF4M{font-size:14px;font-size:1.4rem;font-weight:400;line-height:2.4;color:#939393;list-style:none}._2lF4M>.col{padding-left:4px;padding-right:4px}._245pr:after{background:#078285}.hDcKb:after{background:#0d0d0d}._2WDpz{position:relative;padding:0 4px 4px 0;display:-ms-flexbox;display:flex;gap:4px;-ms-flex-align:center;align-items:center}._2WDpz>svg{width:12px;height:12px}.luHcK{font-weight:600;color:#078285}._29EEZ{font-weight:400;color:#0d0d0d}._26z53{width:24px;height:1px;background:#e0e8f2}.EVAEp{background:#0d0d0d}", ""]);

// exports
exports.locals = {
	"steps": "_2lF4M",
	"steps--active": "_245pr",
	"steps--done": "hDcKb",
	"step": "_2WDpz",
	"step--active": "luHcK",
	"step--done": "_29EEZ",
	"step__lineBetween": "_26z53",
	"step__lineBetween--done": "EVAEp"
};