exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3SxJz .table__wrapper{padding:0}._3SxJz .table__cell{padding:1.6rem 0;margin-right:3rem}._3SxJz .table__cell--commitId{width:80px;width:8rem}._3SxJz .table__cell--title{width:400px;width:40rem}._3SxJz .table__cell--createdBy{width:200px;width:20rem}._3SxJz .table__cell--commitCreated{width:120px;width:12rem}", ""]);

// exports
exports.locals = {
	"commitsTable": "_3SxJz"
};