import each from 'lodash/each';
import hasIn from 'lodash/hasIn';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import unset from 'lodash/unset';
import startsWith from 'lodash/startsWith';
import endsWith from 'lodash/endsWith';
import includes from 'lodash/includes';
import isArray from 'lodash/isArray';
import isBoolean from 'lodash/isBoolean';
import isEmpty from 'lodash/isEmpty';
import isFinite from 'lodash/isFinite';
import isInteger from 'lodash/isInteger';
import isNan from 'lodash/isNaN';
import isNumber from 'lodash/isNumber';
import isObjectLike from 'lodash/isObjectLike';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import join from 'lodash/join';
import split from 'lodash/split';
import padStart from 'lodash/padStart';
import toNumber from 'lodash/toNumber';
import toString from 'lodash/toString';
import XRegExp from 'xregexp';
import moment from 'moment';


const precision = (a) => {
  if (!isFinite(a)) return 0;
  let e = 1;
  let p = 0;
  while (Math.round(a * e) / e !== a) { e *= 10; p++; }
  return p;
};


const convertToNumber = (a) => {
  if (isString(a)) {
    a = a.trim().replace(',', '.');
  }
  return toNumber(a);
};

const splitNoParen = (s) => {
  const M = s.match(/([^()]+)|([()])/g);
  const L = M.length;
  let A = [];
  let left = 0;
  let right = 0;
  let next;
  let str = '';

  for (let i = 0; i < L; i++) {
    next = M[i];
    if (next === '(') {
      ++left;
    } else if (next === ')') {
      ++right;
    }
    if (left !== 0) {
      str += next;
      if (left === right) {
        A[A.length - 1] += str.slice(1, -1);
        left = 0;
        right = 0;
        str = '';
      }
    } else {
      A = A.concat(next.match(/([^,]+)/g));
    }
  }
  return A;
};


/* eslint-disable consistent-return */

/**
 * Function to filter and validate data sets.
 * @param {object} data to validate
 * @param {object} validatorsRules rules as strings separated by coma. `*` is a passthrough wildcard.
 * @return @return {object} validated data and errors object
 */
export default class Validator {

  static run(values = {}, validatorsRules, stopOnFirstError = true) {
    let errors = {};
    const validatedValues = { ...values };
    if (validatedValues) {
      each(validatedValues, (value, key) => {
        if (!hasIn(validatorsRules, key)) {
          delete (validatedValues[key]);
        }
      });
    }

    each(validatorsRules, (validatorRules, dataFieldName) => {
      validatorRules = splitNoParen(validatorRules);
      each(validatorRules, (validatorRule) => {
        validatorRule = validatorRule.trim();
        if (validatorRule === '*') {
          return;
        }
        const validatorRuleParameters = validatorRule.split(' ');
        validatorRule = validatorRuleParameters.shift();
        let validationResult = {};
        if (typeof this[validatorRule] === 'function') {
          validationResult = this[validatorRule](
            validatedValues[dataFieldName],
            validatorRuleParameters,
            validatedValues,
            validatorsRules,
          );
          if (isUndefined(validationResult.value)) unset(validatedValues, dataFieldName);
          else validatedValues[dataFieldName] = validationResult.value;
        } else {
          validationResult = { valid: false };
        }

        if (!validationResult.valid) {
          if (!hasIn(errors, dataFieldName)) {
            errors[dataFieldName] = {};
          }
          errors[dataFieldName][validatorRule] = validatorRuleParameters;
          if (stopOnFirstError) {
            return false;
          }
        }
      });
    });
    if (isEmpty(errors)) {
      errors = null;
    }
    return { validatedValues, errors };
  }

  /**
   * Diagnostic, ALWAYS returns error
   */
  static forceError(value) {
    return {
      value,
      valid: false,
    };
  }

  /**
   * Trimming bypass
   */
  static trim(value = '') {
    if (!isString(value)) {
      value = toString(value);
    }
    value = value.trim();
    return {
      value,
      valid: true,
    };
  }

  /**
   * Non-false value is required
   */
  static required(value = '') {
    const delimiter = '\u200E';
    // eslint-disable-next-line no-shadow
    const convertValueToString = (input, delimiter) => {
      if (!input && input !== 0) {
        return '';
      }
      let output = input;
      if (isObjectLike(input)) {
        output = [];
        forEach(input, (val) => {
          output.push(convertValueToString(val, delimiter));
        });
        output = output.join(delimiter);
      } else if (!isString(input)) {
        output = toString(input);
      }
      return output.trim();
    };

    if (isString(value)) {
      value = value.trim();
    }

    const convertedValue = convertValueToString(value, delimiter);
    return {
      value,
      valid: convertedValue
        && !startsWith(convertedValue, delimiter)
        && !endsWith(convertedValue, delimiter)
        && !includes(convertedValue, delimiter + delimiter),
    };
  }


  /**
   * Required length of a string / an array.
   */
  static length(value, validatorRuleParameters) {
    if (!isString(value) && !isArray(value)) {
      value = toString(value);
    }
    if (isString(value)) {
      value = value.trim();
    }
    return {
      value,
      valid: value.length === +validatorRuleParameters[0],
    };
  }

  /**
   * Max length of a string / an array.
   */
  static limit(value, validatorRuleParameters) {
    if (!isString(value) && !isArray(value)) {
      value = toString(value);
    }
    if (isString(value)) {
      value = value.trim();
    }
    return {
      value,
      valid: value.length <= validatorRuleParameters[0],
    };
  }

  /**
   * Max length of a string / an array. Same as limit but with different errorMessage
   */
  static limitDigits(value, validatorRuleParameters) {
    if (!isString(value) && !isArray(value)) {
      value = toString(value);
    }
    if (isString(value)) {
      value = value.trim();
    }
    return {
      value,
      valid: value.length <= validatorRuleParameters[0],
    };
  }

  /**
   * Min length of a string / an array. Empty is also valid (use together with 'required' to prevent this).
   */
  static minimum(value, validatorRuleParameters) {
    if (!isString(value) && !isArray(value)) {
      value = toString(value);
    }
    if (isString(value)) {
      value = value.trim();
    }
    return {
      value,
      valid: !value || value.length >= validatorRuleParameters[0],
    };
  }

  /**
   * Range of valid length described by two parameters.
   */
  static range(value, validatorRuleParameters) {
    if (!isString(value) && !isArray(value)) {
      value = toString(value);
    }
    if (isString(value)) {
      value = value.trim();
    }
    const valid = !value || (value.length >= validatorRuleParameters[0] && value.length <= validatorRuleParameters[1]);
    return {
      value,
      valid,
    };
  }

  /**
   * Allows for letters, digits, spaces and dashes only
   */
  static alphaNumeric(value = '') {
    if (!isString(value)) {
      value = toString(value);
    }
    value = value.trim();
    return {
      value,
      valid: value === '' || !value.match(/[^\w -]/),
    };
  }


  /**
   * Allows for capital Letters only
   */
  static capitalLetters(value = '') {
    if (!isString(value)) {
      value = toString(value);
    }
    value = value.trim();
    return {
      value,
      valid: value === '' || value.match(/^[A-Z]+$/),
    };
  }


  /**
   * Allows for lowerLetters only
   */
  static lowerLetters(value = '') {
    if (!isString(value)) {
      value = toString(value);
    }
    return {
      value,
      valid: value === '' || value.match(/^[a-z]+$/),
    };
  }


  /**
   * Allows for letters dashes and underscore only
   */
  static capitalLettersDashesAndUnderscore(value = '') {
    if (!isString(value)) {
      value = toString(value);
    }
    return {
      value,
      valid: value === '' || value.match(/^[A-Z_-]+$/),
    };
  }

  /**
   * allows only 0/1 values (as strings)
   */
  static bit(value = '0') {
    if (!isString(value)) {
      value = toString(value);
    }
    value = value.trim();
    if (value === '') {
      value = '0';
    }
    return {
      value,
      valid: value === (value & 1).toString(),
    };
  }

  /**
   * allows only false/true values
   */
  static bool(value = false) {
    let isTrue;

    if (isBoolean(value)) {
      isTrue = value;
    } else if (isString(value)) {
      value = value.trim();
      if (!value || value === '0' || value === 'false') {
        isTrue = false;
      } else {
        isTrue = true;
      }
    } else if (isNumber(value)) {
      isTrue = value !== 0;
    } else {
      // Arrays, objects
      isTrue = !isEmpty(value);
    }

    return {
      value: isTrue,
      valid: true,
    };
  }

  // NUMBER VALUES ---------------------------------------------------

  /**
   * Allows for integer value only
   */
  static integer(value = '') {
    if (value === '') {
      return {
        value: null,
        valid: true,
      };
    }
    if (!isNumber(value)) {
      value = toNumber(value);
    }
    return {
      value,
      valid: isInteger(value),
    };
  }

  /**
   * Allows for decimal number value only
   */
  static decimal(value = '') {
    if (value === '') {
      return {
        value: null,
        valid: true,
      };
    }
    if (!isNumber(value)) {
      value = convertToNumber(value);
    }
    return {
      value,
      valid: isFinite(value),
    };
  }

  /**
   * Allows for double precision decimal number value only
   */
  static doublePrecision(value = '') {
    if (value === '') {
      return {
        value: null,
        valid: true,
      };
    }
    if (!isNumber(value)) {
      value = convertToNumber(value);
    }
    return {
      value,
      valid: isFinite(value) && precision(value) <= 2,
    };
  }

  /**
   * Allows for value greater then 0 only
   */
  static positive(value = '') {
    if (value === '' || value === null) {
      return {
        value: null,
        valid: true,
      };
    }
    if (!isNumber(value)) {
      value = convertToNumber(value);
    }
    return {
      value,
      valid: isFinite(value) && value > 0,
    };
  }

  /**
   * Allows for value greater or equal then 0 only
   */
  static nonNegative(value = '') {
    if (value === '') {
      return {
        value: null,
        valid: true,
      };
    }
    if (!isNumber(value)) {
      value = convertToNumber(value);
    }
    return {
      value,
      valid: isFinite(value) && value >= 0,
    };
  }


  /**
   * Allows for value lower or equal then specified value
   */
  static maxValue(value = '', validatorRuleParameters) {
    if (value === '') {
      return {
        value: null,
        valid: true,
      };
    }
    if (!isNumber(value)) {
      value = convertToNumber(value);
    }
    return {
      value,
      valid: isFinite(value) && value <= validatorRuleParameters[0],
    };
  }


  /**
   * Allows for value greater or equal then specified value
   */
  static minValue(value = '', validatorRuleParameters) {
    if (value === '') {
      return {
        value: null,
        valid: true,
      };
    }
    if (!isNumber(value)) {
      value = convertToNumber(value);
    }
    return {
      value,
      valid: isFinite(value) && value >= validatorRuleParameters[0],
    };
  }


  /**
   * Allows for value greater or equal then value from specified formValue
   */
  static greaterThen(value = '', validatorRuleParameters, values) {
    if (value === '' || value === null) {
      return {
        value: null,
        valid: true,
      };
    }
    if (!isNumber(value)) {
      value = convertToNumber(value);
    }
    let greaterThenValue = values[validatorRuleParameters[0]];

    if (greaterThenValue === '') {
      return {
        value: null,
        valid: true,
      };
    }

    if (!isNumber(greaterThenValue)) {
      greaterThenValue = convertToNumber(greaterThenValue);
    }
    if (validatorRuleParameters.length === 1) {
      validatorRuleParameters[1] = greaterThenValue;
    }
    return {
      value,
      valid: isFinite(value) && isFinite(greaterThenValue) && value > greaterThenValue,
    };
  }

  /**
   * Allows for value lower or equal then value from specified formValue
   */
  static lowerThen(value = '', validatorRuleParameters, values) {
    if (value === '' || value === null) {
      return {
        value: null,
        valid: true,
      };
    }
    if (!isNumber(value)) {
      value = convertToNumber(value);
    }
    let lowerThenValue = values[validatorRuleParameters[0]];

    if (lowerThenValue === '') {
      return {
        value: null,
        valid: true,
      };
    }

    if (!isNumber(lowerThenValue)) {
      lowerThenValue = convertToNumber(lowerThenValue);
    }
    if (validatorRuleParameters.length === 1) {
      validatorRuleParameters[1] = lowerThenValue;
    }
    return {
      value,
      valid: isFinite(value) && isFinite(lowerThenValue) && value < lowerThenValue,
    };
  }


  // REG EXP --------------------------------------------------------

  static regExp(value, validatorRuleParameters) {
    if (!isString(value)) {
      value = toString(value);
    }
    value = value.trim();
    const regExp = new RegExp(validatorRuleParameters[0]);
    return {
      value,
      valid: !value || regExp.test(value),
    };
  }

  // COMPARATORS ----------------------------------------------------

  static equalTo(value, validatorRuleParameters, values) {
    return {
      value,
      valid: value === values[validatorRuleParameters[0]],
    };
  }


  static masterFor(value, validatorRuleParameters, values) {
    let valid = true;
    const slaveName = validatorRuleParameters[0];
    if (!value) {
      if (values[slaveName]) {
        valid = false;
      }
    }
    return {
      value: value && values[slaveName] ? value : undefined,
      valid,
    };
  }


  static slaveFor(value, validatorRuleParameters, values) {
    const masterName = validatorRuleParameters[0];
    return {
      value: value && values[masterName] ? value : undefined,
      valid: true,
    };
  }

  // DATA VALUES ----------------------------------------------------

  /**
   * Non-false value of one of two fields is required
   */
  static alternative(value, validatorRuleParameters, data) {
    if (!isString(value)) {
      value = toString(value);
    }
    return {
      value,
      valid: value || get(data, validatorRuleParameters[0], false),
    };
  }

  /**
   * Allows for proper email address only
   */
  static email(value = '') {
    if (!isString(value)) {
      value = toString(value);
    }
    value = value.trim().toLowerCase();
    return {
      value,
      valid: !value || value.match(/^([_a-z0-9-]+)([.+][_a-z0-9-]+)*@([a-z0-9-]+)(\.[a-z0-9-]+)*(\.[a-z]{2,})$/),
    };
  }

  /**
   * Allows for YYYY-MM-DD date format only
   */
  static date(value = '') {
    if (!isString(value)) {
      value = toString(value);
    }
    value = value.trim();
    return {
      value,
      valid: !value || moment(value, 'YYYY-MM-DD', true).isValid(),
    };
  }

  /**
   * Allows for H:mm time format only
   */
  static time(value = '') {
    if (!value) {
      return {
        value: '',
        valid: true,
      };
    }
    if (!isString(value)) {
      value = toString(value);
    }
    value = value.trim();
    const hour = split(value, ':');
    if (hour.length === 1) {
      hour.push(0);
    }
    const time = [];

    forEach(hour, (t, k) => {
      const v = toNumber(t);
      if (
        !isNan(v)
        && v >= 0
        && (
          (!k && v < 24) || (k && v < 60)
        )
      ) {
        time.push(padStart(v, 2, 0));
      }
    });

    if (time.length !== 2) {
      return {
        value,
        valid: false,
      };
    }

    return {
      value: join(time, ':'),
      valid: true,
    };
  }

  /**
   * Allows for timestamp format
   */
  static timestamp(value = '') {
    if (value === '') {
      return {
        value: null,
        valid: true,
      };
    }
    if (!isNumber(value)) {
      value = toNumber(value);
    }
    return {
      value,
      valid: isInteger(value) && value >= 0,
    };
  }


  /**
   * Allows for proper uuid v4 only
   */
  static uuid(uuid) {
    const r = new RegExp(/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/);
    return isString(uuid) && r.test(uuid);
  }

  /**
   * Allows for proper phone number only
   */
  static phone(value = '') {
    if (!isString(value)) {
      value = toString(value);
    }
    value = value.trim();
    return {
      value,
      valid: !value || value.match(/^\+\d{2}\s\d{3}\s\d{3}\s\d{3}$/),
    };
  }

  /**
   * Allows for proper phone with prefix
   */
  static phoneWithPrefix(value) {
    const prefix = get(value, 'prefix', '').replace(/s/g, '');
    const number = get(value, 'number', '').replace(/s/g, '');

    return {
      value: {
        prefix,
        number,
      },
      valid: (!prefix || prefix.match(/^\+\d{1,3}-?\d{0,4}$/)) && (!number || number.match(/^[\d\s-().]{5,}$/)),
    };
  }

  /**
   * Allows for proper zipcode only
   */
  static zipcode(value = '') {
    if (!isString(value)) {
      value = toString(value);
    }
    value = value.trim();
    return {
      value,
      valid: !value || (value.length >= 4 && value.length <= 15),
    };
  }

  /**
   * The password must contain at least eight characters, one number and one letter.
   */
  static password(value) {
    if (!isString(value)) {
      value = toString(value);
    }
    const regExp = new XRegExp('^((?=.*\\pN)(?=.*\\pL)(.*))$');
    return {
      value,
      valid: !value || (value.length >= 8 && regExp.test(value)),
    };
  }

  /**
   * Allows for url format   */
  static url(value) {
    if (value === '') {
      return {
        value,
        valid: true,
      };
    }
    let valid;
    try { valid = Boolean(new URL(value)); } catch (e) { valid = false; }
    return {
      value,
      valid,
    };
  }

}
