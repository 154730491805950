import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import ChevronDownIcon from 'svg/chevron-down.svg';
import ChevronUpIcon from 'svg/chevron-up.svg';
import ChevronsHorizontalIcon from 'svg/chevrons-horizontal.svg';
import styles from './SortingIcon.pcss';


class SortingIcon extends React.PureComponent {

    static propTypes = {
      type     : PropTypes.oneOf(['default', 'asc', 'desc']),
      className: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
    };

    get icon() {
      const classNameValue = this.props.className || '';
      switch (this.props.type) {
        case 'default': {
          return <ChevronsHorizontalIcon className={cn(styles.box__icon, classNameValue)} />;
        }
        case 'asc': {
          return <ChevronUpIcon className={cn(styles.box__icon, classNameValue)} />;
        }
        case 'desc': {
          return <ChevronDownIcon className={cn(styles.box__icon, classNameValue)} />;
        }
        default: return null;
      }
    }


    render() {
      const { icon } = this;
      return (
        <>
          { icon }
        </>
      );
    }

}

export default withStyles(styles)(SortingIcon);
