exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3sEj7{line-height:24px}", ""]);

// exports
exports.locals = {
	"enableCountryErrorInfo": "_3sEj7"
};