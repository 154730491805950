exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._3WsPV{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}._3M8wZ{fill:#078285;height:20px;width:20px}", ""]);

// exports
exports.locals = {
	"icon": "_3WsPV",
	"icon__icon": "_3M8wZ"
};