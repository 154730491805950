import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import isObjectLike from 'lodash/isObjectLike';
import styles from './Chip.pcss';


class Chip extends React.PureComponent {

  static propTypes = {
    // Explicit props
    labelMessage: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
      PropTypes.element,
    ]).isRequired,
    className: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    styleModifier: PropTypes.oneOf(['inactive', 'error', 'success', 'pending']),
  };


  static defaultProps = {
    className: '',
  };


  renderLabel() {
    const { labelMessage } = this.props;
    if (!labelMessage) {
      return null;
    }
    if (isObjectLike(labelMessage) && !React.isValidElement(labelMessage)) {
      return <FormattedMessage {...labelMessage} />;
    }
    return labelMessage;
  }


  render() {
    return (
      <div
        className={cn(styles.chip, {
          [styles[`chip--${this.props.styleModifier}`]]: this.props.styleModifier,
        }, this.props.className)}
      >
        { this.renderLabel() }
      </div>
    );
  }

}

export default withStyles(styles)(Chip);
