import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import isObject from 'lodash/isObject';
import cn from 'classnames';
import intlShape from 'shapes/intlShape';


class Tag extends React.PureComponent {

  static propTypes = {
    titleMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onClick     : PropTypes.func,
    intl        : intlShape.isRequired,
    className   : PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    isDisabled  : PropTypes.bool,
    isActive    : PropTypes.bool,
    id          : PropTypes.string,
  };

  static defaultProps = {
    isDisabled: false,
    isActive  : false,
    onClick   : () => {},
  };


  onClick(evt, isEnabled) {
    if (!isEnabled) {
      evt.preventDefault();
      return;
    }
    this.props.onClick(evt);
  }

  get title() {
    const { titleMessage } = this.props;
    if (!titleMessage) {
      return null;
    }
    if (isObject(titleMessage)) {
      return this.props.intl.formatMessage(titleMessage);
    }
    return titleMessage;
  }


  render() {
    return (
      <button
        type="button"
        id={this.props.id}
        className={cn(
          'tag',
          {
            'tag--disabled': this.props.isDisabled,
            'tag--active'  : this.props.isActive,
          },
          this.props.className
        )}
        disabled={this.props.isDisabled}
        onClick={(evt) => this.onClick(evt, !this.props.isDisabled)}
        title={this.title}
      >
        {this.title}
      </button>
    );
  }

}


export default injectIntl(Tag);
