import React from 'react';
import PropTypes from 'prop-types';
import Table from 'components/Table';
import messages from '../../../../../../../messages';


class Regexps extends React.Component {

  static propTypes = {
    missingRegexps: PropTypes.array,
    // Explicit props
    className     : PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }


  static defaultProps = {
    className: '',
  };


  get schema() {
    return [
      {
        key         : 'termKey',
        labelMessage: messages.labels.term,
        renderer    : (regexp) => (
          <p>
            {regexp.termKey}
          </p>
        ),
      },
      {
        key         : 'countryCode',
        labelMessage: messages.labels.countryCode,
        renderer    : (regexp) => (
          <p>
            {regexp.countryCode}
          </p>
        ),
      },
    ];
  }


  render() {
    return (
      <Table
        idKey="idKey"
        className={this.props.className}
        schema={this.schema}
        entities={this.props.missingRegexps.map((regexp) => (
          {
            idKey: `${regexp.termKey}-${regexp.countryCode}}`,
            ...regexp,
          }
        ))}
        perPage={-1}
        isPerPageOff
      />
    );
  }

}

export default Regexps;
