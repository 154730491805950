exports = module.exports = require("../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".aK1rQ{margin-left:auto;margin-right:0}.aK1rQ .wrapper{border:1px solid #e0e8f2;border-radius:12px;background:#fff}.aK1rQ .movePage,.aK1rQ .wrapper{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center}.aK1rQ .movePage{width:40px;height:40px;cursor:pointer;background:#f6f6f6;border:none}.aK1rQ .movePage:disabled{cursor:not-allowed}.aK1rQ .movePage svg{height:16px;fill:#078285}.aK1rQ .movePage:disabled svg{fill:#6b778d}.aK1rQ .movePage--previous{border-radius:12px 0 0 12px}html[dir=ltr] .aK1rQ .movePage--previous{border-right:1px solid hsla(0,0%,100%,.1);margin-right:5px}html[dir=rtl] .aK1rQ .movePage--previous{border-left:1px solid hsla(0,0%,100%,.1);margin-left:5px}html[dir=ltr] .aK1rQ .movePage--previous>svg{-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);transform:rotate(180deg)}.aK1rQ .movePage--next{border-radius:0 12px 12px 0}html[dir=ltr] .aK1rQ .movePage--next{border-left:1px solid hsla(0,0%,100%,.1);margin-left:5px}html[dir=rtl] .aK1rQ .movePage--next{border-right:1px solid hsla(0,0%,100%,.1);margin-right:5px}html[dir=rtl] .aK1rQ .movePage--next>svg{-webkit-transform:rotate(180deg);-ms-transform:rotate(180deg);transform:rotate(180deg)}.aK1rQ .page{display:-ms-flexbox;display:flex;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;margin:8px;min-width:24px;min-height:24px;cursor:pointer;background:none;border:none;color:#20353e;border-radius:4px;font-weight:500}.aK1rQ .page--active{color:#fff;background:#078285}._1Ro9g{display:-ms-flexbox;display:flex;-ms-flex-positive:1;flex-grow:1;-ms-flex-pack:center;justify-content:center}", ""]);

// exports
exports.locals = {
	"pages": "aK1rQ",
	"inner": "_1Ro9g"
};