exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._15vX_{display:-ms-flexbox;display:flex;-ms-flex-direction:column;flex-direction:column;gap:16px;gap:1.6rem;-ms-flex-align:center;align-items:center;-ms-flex-pack:center;justify-content:center;padding:3.2rem 1.6rem;background:#fdf9e6;color:#806b20;border-radius:8px}", ""]);

// exports
exports.locals = {
	"loadingBanner": "_15vX_"
};