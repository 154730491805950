exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "._1YfZa{display:block;margin-top:2.4rem}", ""]);

// exports
exports.locals = {
	"info2": "_1YfZa"
};