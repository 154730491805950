import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import map from 'lodash/map';
import isObjectLike from 'lodash/isObjectLike';
import Check from 'svg/check.svg';
import styles from './Steps.pcss';


class Steps extends React.PureComponent {

  static propTypes = {
    template: PropTypes.arrayOf(PropTypes.shape({
      id          : PropTypes.string.isRequired,
      labelMessage: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
    })),
    activeStepIdx: PropTypes.number,
    className    : PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  };


  static defaultProps = {
    className: '',
  };


  renderMessage(stepTemplate) {
    const { labelMessage } = stepTemplate;
    if (!labelMessage) {
      return null;
    }
    return isObjectLike(labelMessage) ? <FormattedMessage {...labelMessage} /> : labelMessage;
  }


  renderStep(stepTemplate, stepIdx, stepsNumber) {
    const { activeStepIdx } = this.props;
    const stepNumber = stepIdx + 1;
    const currentStep = stepIdx === activeStepIdx;
    const doneStep = stepIdx < activeStepIdx;
    const isLast = stepIdx + 1 === stepsNumber;
    return (
      <li key={stepTemplate.id} className="col">
        <div
          className={cn(styles.step,
            { [styles['step--active']]: currentStep },
            { [styles['step--done']]: stepIdx < activeStepIdx }
          )}
        >
          {doneStep ? <Check /> : `${stepNumber}. `}
          <p>{ this.renderMessage(stepTemplate) }</p>
          {!isLast
            ? (
              <div className={cn(styles.step__lineBetween,
                { [styles['step__lineBetween--done']]: stepIdx < activeStepIdx }
              )}
              />
            )
            : null}
        </div>
      </li>
    );
  }


  render() {
    const { template } = this.props;
    const stepsNumber = template.length;
    return (
      <ul className={cn('row', styles.steps, this.props.className)}>
        { map(template, (stepTemplate, idx) => this.renderStep(stepTemplate, idx, stepsNumber)) }
      </ul>
    );
  }

}


export default withStyles(styles)(Steps);
