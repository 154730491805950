import React from 'react';
import compact from 'lodash/compact';
import forIn from 'lodash/forIn';
import forOwn from 'lodash/forOwn';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import isObject from 'lodash/isObject';
import map from 'lodash/map';
import set from 'lodash/set';
import unset from 'lodash/unset';


export const compactObject = (object) => {
  const compactedObject = { ...object };
  forOwn(compactedObject, (value, key) => {
    let isRedundant = false;
    switch (typeof value) {
      case 'undefined': {
        isRedundant = true;
        break;
      }
      case 'boolean':
      case 'number':
      case 'string':
      {
        isRedundant = !value;
        break;
      }
      case 'object': {
        isRedundant = isEmpty(isArray(value) ? compact(value) : compactObject(value));
        break;
      }
      default: {
        isRedundant = false;
      }
    }

    if (isRedundant) {
      unset(compactedObject, key);
    }
  });
  return compactedObject;
};


export const foldObject = (object, parentKey = null) => {
  let foldedObject = {};
  forIn(object, (value, key) => {
    if (isObject(value) && !isArray(value)) {
      foldedObject = { ...foldedObject, ...foldObject(value, key) };
    } else {
      const fullKey = parentKey ? `${parentKey}.${key}` : key;
      foldedObject[fullKey] = value;
    }
  });
  return { ...foldedObject };
};


export const unfoldObject = (object) => {
  const unfoldedObject = {};
  forIn(object, (value, key) => set(unfoldedObject, key, value));
  return { ...unfoldedObject };
};

// $roots keeps previous parent properties as they will be added as a prefix for each prop.
// $sep is just a preference if you want to separate nested paths other than dot.
export const flatten = (obj, roots = [], sep = '.') => Object
  // find props of given object
  .keys(obj)
  // return an object by iterating props
  .reduce((memo, prop) => Object.assign(
    // create a new object
    {},
    // include previously returned object
    memo,
    Object.prototype.toString.call(obj[prop]) === '[object Object]'
      // keep working if value is an object
      ? flatten(obj[prop], roots.concat([prop]), sep)
      // include current prop and value and prefix prop with the roots
      : { [roots.concat([prop]).join(sep)]: obj[prop] }
  ), {});


/**
 * Helper to transform plain text with new line characters into paragraph-like markup
 *
 * @param {string} str - text to split into paragraphs
 * @param {string} [className=''] - css class mainly specifying spaces between paragraphs
 * @returns {Array<Element>}
 */
export const nl2br = (str, className = '') => map(str.split('\n'), (item, key) => (
  <span key={key} className={`d-block ${className}`}>
    {item}
    <br />
  </span>
));
