exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".FNO5j{display:-ms-flexbox;display:flex;border-bottom:1px solid #e0e8f2;padding-bottom:3.2rem}.FNO5j .HgbHW{-ms-flex-preferred-size:calc(50% - 3.2rem);flex-basis:calc(50% - 3.2rem)}.FNO5j .HgbHW ._1Xi39{display:-ms-flexbox;display:flex;-ms-flex-pack:justify;justify-content:space-between;margin-bottom:1.6rem;padding:0;border:none}.FNO5j .HgbHW ._1Xi39>div{-ms-flex:1 1;flex:1 1;max-width:none}.FNO5j .HgbHW ._1Xi39:last-child{margin-bottom:0}.FNO5j .HgbHW:first-child{padding-right:3.2rem;border-right:1px solid #e0e8f2}.FNO5j .HgbHW:last-child{padding-left:3.2rem}", ""]);

// exports
exports.locals = {
	"selectStatuses": "FNO5j",
	"selectStatuses__optionSection": "HgbHW",
	"selectStatuses__optionSection__formGroup": "_1Xi39"
};