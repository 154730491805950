import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import Modal from 'components/Modal';
import App from 'modules/App';
import * as constants from '../../../../../../constants';
import * as actions from '../../../../../../actions';
import messages from '../../../../../../messages';
import * as selectors from '../../../../../../selectors';
import styles from '../IdentifierTermTable.pcss';
import UpdateOrAddIdentifierOptionForm from './UpdateOrAddIdentifierOptionForm';


class UpdateOrAddIdentifierOptionModal extends React.PureComponent {

  static propTypes = {
    // Explicit props
    isInProgress    : PropTypes.bool,
    hasErrors       : PropTypes.bool,
    onSubmit        : PropTypes.func,
    existingTermKeys: PropTypes.arrayOf(PropTypes.string),
    // Implicit props
    openModalId     : PropTypes.string,
    initialFormValue: PropTypes.object,
    // Implicit actions
    onClose         : PropTypes.func,
  };


  componentDidUpdate(prevProps) {
    if (prevProps.isInProgress && !this.props.isInProgress && !this.props.hasErrors) {
      this.props.onClose();
    }
  }


  get isNew() {
    return !this.props.initialFormValue;
  }


  renderContent() {
    return (
      <UpdateOrAddIdentifierOptionForm
        isInProgress={this.props.isInProgress}
        hasErrors={this.props.hasErrors}
        onClose={this.props.onClose}
        onSubmit={this.props.onSubmit}
        initialFormValue={this.props.initialFormValue}
        existingTermKeys={this.props.existingTermKeys}
        isNew={this.isNew}
      />
    );
  }


  render() {
    const header = this.isNew ? messages.headers.addNewIdentifierOption : messages.headers.updateIdentifierOption;
    return (
      <Modal
        modalId={constants.ADD_IDENTIFIER_OPTION_MODAL}
        openModalId={this.props.openModalId}
        headerMessage={header}
        styleModifier="lg"
        isInProgress={this.props.isInProgress}
        onClose={this.props.onClose}
      >
        { this.renderContent() }
      </Modal>
    );
  }

}


const mapStateToProps = (state) => ({
  openModalId     : App.selectors.modal(state),
  initialFormValue: selectors.identifierOptionFormValueInitialValue(state),
});


const mapDispatchToProps = (dispatch) => ({
  onClose : () => dispatch(App.actions.closeModal()),
  onSubmit: (value) => {
    dispatch(actions.setUpdateOrAddIdentifierTerm(value));
  },
});


const ConnectedUpdateOrAddIdentifierOptionModal = connect(
  mapStateToProps,
  mapDispatchToProps,
)(UpdateOrAddIdentifierOptionModal);


export default withStyles(styles)(ConnectedUpdateOrAddIdentifierOptionModal);
