
import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import Select from 'components/Form/Select';
import FormGroup from 'components/Form/FormGroup';
import messages from '../../../../messages';
import styles from './Identifiers.pcss';


class SelectStatusesOptionSection extends React.PureComponent {

    static propTypes = {
      // Explicit props
      formValues   : PropTypes.object.isRequired,
      sectionName  : PropTypes.string.isRequired,
      statuses     : PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      headerMessage: PropTypes.object.isRequired,
      className    : PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.object,
      ]),
      onSetFormValue: PropTypes.func.isRequired,
    };

    static defaultProps = {
      className: '',
    }

    get selectOptions() {
      return ['Mandatory', 'Optional', 'Hidden'];
    }


    render() {
      const { statuses, sectionName, formValues, onSetFormValue, headerMessage } = this.props;
      return (
        <div className={this.props.className}>
          <h5 className="text--h5 mt-0 mb-6"><FormattedMessage {...headerMessage} /></h5>
          {statuses.map((select) => {
            const selectEntriesArray = get(formValues.values, sectionName);
            const index = findIndex(selectEntriesArray, (selectEntry) => selectEntry.name === select);
            return (
              <FormGroup
                id={`${sectionName}.[${index}].status`}
                className={styles.selectStatuses__optionSection__formGroup}
                isOneLiner
                labelMessage={messages.labels[select]}
                formValues={formValues}
                key={`${sectionName}.[${select}].status`}
              >
                <Select
                  optionsFrom={this.selectOptions.map((option) => (
                    {
                      status: option,
                      label : option,
                    }
                  ))}
                  valueKey="status"
                  labelKey="label"
                  onChange={onSetFormValue}
                />
              </FormGroup>
            );
          }
          )}
        </div>
      );
    }

}


export default withStyles(styles)(SelectStatusesOptionSection);
