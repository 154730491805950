exports = module.exports = require("../../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".Cvca_{display:-ms-flexbox;display:flex;-ms-flex-flow:row wrap;flex-flow:row wrap;-ms-flex-align:baseline;align-items:baseline}.Cvca_ .row{-ms-flex-preferred-size:100%;flex-basis:100%;-ms-flex-align:baseline;align-items:baseline;margin-right:0;margin-left:0;-webkit-column-gap:30px;-moz-column-gap:30px;column-gap:30px}.Cvca_ .col,.Cvca_ .col-6{padding-right:0;padding-left:0}.Cvca_ .col-6{-ms-flex-preferred-size:calc(50% - 15px);flex-basis:calc(50% - 15px)}", ""]);

// exports
exports.locals = {
	"addPayerForm": "Cvca_"
};