exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".VIvtG{position:relative}._1ow3E{position:absolute;width:-webkit-max-content;width:-moz-max-content;width:max-content;top:100%;right:-72px;padding:8px 16px;background:#282b34;color:#fff;border-radius:8px;display:none}._29mMt{display:block}._1D84h{position:absolute;top:-9px;right:calc(50% - 8px);border:5px solid rgba(0,0,0,0);border-bottom-color:#282b34}", ""]);

// exports
exports.locals = {
	"tooltip_container": "VIvtG",
	"tooltip_box": "_1ow3E",
	"tooltip_box--visible": "_29mMt",
	"tooltip_arrow": "_1D84h"
};