import { PublicClientApplication, EventType, CacheLookupPolicy, InteractionRequiredAuthError } from '@azure/msal-browser';


const msalInstance = process.env.BROWSER ? new PublicClientApplication(window.App.authConfig.msalConfig) : null;


async function setActiveAccountOnLoginSuccessEventCallback() {
  return msalInstance.addEventCallback((event) => {
    // set active account after redirect
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
      const { account } = event.payload;
      msalInstance.setActiveAccount(account);
    }
  });
}


async function removeEventCallback(callbackId) {
  msalInstance.removeEventCallback(callbackId);
}


async function signIn(signInCallback) {
  if (process.env.BROWSER) {
  // handle auth redired/do all initial setup for msal
    msalInstance.handleRedirectPromise().then((authResult) => {
    // Check if user signed in
      const account = msalInstance.getActiveAccount();
      if (!account) {
      // redirect anonymous user to login page
        msalInstance.loginRedirect(window.App.authConfig.loginRequest);
      }
      if (authResult) {
        signInCallback(authResult);
      }
    });
  }
}


function getAccount() {
  return msalInstance && msalInstance.getActiveAccount();
}

async function acquireTokenSilent() {
  const currentAccount = getAccount();
  if (!currentAccount) return null;
  const silentRequest = {
    scopes           : window.App.authConfig.loginRequest.scopes,
    account          : currentAccount,
    forceRefresh     : false,
    cacheLookupPolicy: CacheLookupPolicy.Default,
  };
  const token = await msalInstance.acquireTokenSilent(silentRequest).catch((error) => {
    if (error instanceof InteractionRequiredAuthError) {
      const request = {
        scopes   : window.App.authConfig.loginRequest.scopes,
        loginHint: currentAccount.username, // For v1 endpoints, use upn from idToken claims
      };

      return msalInstance.acquireTokenRedirect(request);
    }
    throw error;
  });
  return token;
}

async function getAccessToken() {
  return acquireTokenSilent().then((token) => token && token.accessToken);
}


function signOut() {
  msalInstance.logoutRedirect({
    onRedirectNavigate: () =>
      false
    ,
  });
}


const exporter = (func) => (
  process.env.BROWSER
    ? func
    : Promise.resolve(true)
);


export default {
  msalInstance,
  signOut,
  signIn                                     : exporter(signIn),
  setActiveAccountOnLoginSuccessEventCallback: exporter(setActiveAccountOnLoginSuccessEventCallback),
  removeEventCallback                        : exporter(removeEventCallback),
  getAccount,
  getAccessToken,
};
