import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import cn from 'classnames';
import styles from './InfoMessage.pcss';


class InfoMessage extends React.Component {

  static propTypes = {
    className: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.element]),
    type     : PropTypes.oneOf(['pending', 'success', 'error']).isRequired,
    children : PropTypes.element.isRequired,

  };


  render() {
    return (
      <div
        className={cn(styles.infoMessage, this.props.className, {
          [styles[`infoMessage--${this.props.type}`]]: this.props.type,
        })}
      >
        {this.props.children}
      </div>
    );
  }

}


export default withStyles(styles)(InfoMessage);
