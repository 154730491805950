import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import camelCase from 'lodash/camelCase';
import Tabs from 'components/Tabs';
import InfoMessage from 'components/InfoMessage';
import messages from '../../../../../messages';
import Terms from './Tabs/Terms';
import Translations from './Tabs/Translations';
import Regexps from './Tabs/Regexps';


class ValidationFailed extends React.Component {

  static propTypes = {
    // Explicit props
    validatePublishResult: PropTypes.object,
    className            : PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
  }

  static defaultProps = {
    className: '',
  };

  constructor(props) {
    super(props);
    this.state = {
      activeViewKey: this.viewKeysOrder[0],
    };
  }


  get views() {
    const views = {};
    const { missingTerms, missingTranslations, missingRegexps } = this.props.validatePublishResult;
    if (missingTerms && missingTerms.length) {
      views.Terms = Terms;
    }
    if (missingTranslations && missingTranslations.length) {
      views.Translations = Translations;
    }
    if (missingRegexps && missingRegexps.length) {
      views.Regexps = Regexps;
    }
    return views;
  }


  get viewKeysOrder() {
    const viewKeysOrder = [];
    const { missingTerms, missingTranslations, missingRegexps } = this.props.validatePublishResult;

    if (missingTerms && missingTerms.length) {
      viewKeysOrder.push('Terms');
    }
    if (missingTranslations && missingTranslations.length) {
      viewKeysOrder.push('Translations');
    }
    if (missingRegexps && missingRegexps.length) {
      viewKeysOrder.push('Regexps');
    }
    return viewKeysOrder;
  }


  get tabsLabels() {
    return this.viewKeysOrder.map((id) => ({ id, message: messages.tabs.validationFailed[camelCase(id)] }))
      .reduce((prev, current) => ({ ...prev, [current.id]: current.message }), {});
  }


  renderInfo() {
    return (
      <InfoMessage type="error">
        <p className="text--paragraph text--bold"><FormattedMessage {...messages.infos.validationFailed} /> </p>
      </InfoMessage>
    );
  }


  renderTabs() {
    return (
      <Tabs
        activeItem={this.state.activeViewKey}
        items={this.viewKeysOrder}
        messages={this.tabsLabels}
        action={(activeViewKey) => this.setState({ activeViewKey })}
      />
    );
  }


  renderContent() {
    const ActiveView = this.views[this.state.activeViewKey];
    return (
      <div>
        {this.renderTabs()}
        <ActiveView
          className={this.props.className}
          {...this.props.validatePublishResult}
        />
      </div>
    );
  }

  render() {
    return (
      <div>
        {this.renderInfo()}
        {this.renderContent()}
      </div>
    );
  }

}

export default ValidationFailed;
