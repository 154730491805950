import PropTypes from 'prop-types';
import { scopeName } from 'modules/Account/shapes';


export const direction = PropTypes.oneOf(['ltr', 'rtl']);

export const floatingModal = PropTypes.shape({
  floatingModalId: PropTypes.string.isRequired,
  relatedTo      : PropTypes.string.isRequired,
  place          : PropTypes.oneOf(['left', 'right', 'leftTop', 'rightTop']),
});

export const route = PropTypes.shape({
  action        : PropTypes.oneOf(['PUSH', 'POP', 'REPLACE']),
  name          : PropTypes.string,
  params        : PropTypes.object,
  previousName  : PropTypes.string,
  previousParams: PropTypes.object,
});


export const region = PropTypes.shape({
  regionId : PropTypes.number.isRequired,
  name     : PropTypes.string.isRequired,
  countries: PropTypes.arrayOf(PropTypes.string).isRequired,
});


export const country = PropTypes.shape({
  alpha2Code         : PropTypes.string.isRequired,
  defaultLanguageCode: PropTypes.string.isRequired,
  englishName        : PropTypes.string.isRequired,
  nameKey            : PropTypes.string.isRequired,
  availableScopes    : PropTypes.arrayOf(scopeName).isRequired,
});


export const language = PropTypes.shape({
  code       : PropTypes.string.isRequired,
  isRtl      : PropTypes.bool.isRequired,
  englishName: PropTypes.string.isRequired,
  key        : PropTypes.string,
});


export const message = PropTypes.shape({
  id: PropTypes.string.isRequired,
});


export const consent = PropTypes.shape({
  key : PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
});

export const consents = PropTypes.arrayOf(consent);
