exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".ZLYsG{width:13px;width:1.3rem;height:13px;height:1.3rem;cursor:pointer;line-height:1}.XW28a{fill:#fff}", ""]);

// exports
exports.locals = {
	"copyToClipboard__icon": "ZLYsG",
	"consents__consent__icon--check": "XW28a"
};