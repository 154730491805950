import App from 'modules/App';
import Account from 'modules/Account';
import Country from 'modules/Country';
import BusinessLogicSystem from 'modules/BusinessLogicSystem';
import PublishSnapshot from 'modules/PublishSnapshot';
import Organization from '../modules/Organization';


export function getCoreSagas() {
  return {
    [App.constants.MODULE_ID]                : App.sagas,
    [Account.constants.MODULE_ID]            : Account.sagas,
    [Country.constants.MODULE_ID]            : Country.sagas,
    [BusinessLogicSystem.constants.MODULE_ID]: BusinessLogicSystem.sagas,
    [Organization.constants.MODULE_ID]       : Organization.sagas,
    [PublishSnapshot.constants.MODULE_ID]    : PublishSnapshot.sagas,
  };
}
