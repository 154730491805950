export const MODULE_ID = 'app';

export const SIGNALR_MODULE_ID = 'app-signalR';
export const SIGNALR_HUB_NAME = 'publishLogHub';
export const SIGNALR_NOTIFICATION_RECEIVE_MSG = 'Notification';

export const DEFAULT_LOCALE = 'en';

// If you're adding locale see webpack.config.js MomentLocalesPlugin
export const APP_LOCALES = [
  'en-gb',
  'en',
  'en-au',
  'en-ie',
  'en-hk',
  'en-sg',
  'ar',
  'bg',
  'cs',
  'de',
  'de-ch',
  'es',
  'fi',
  'fr',
  'fr-ca',
  'fr-ch',
  'el',
  'he',
  'hr',
  'it',
  'it-ch',
  'nl',
  'pl',
  'pt',
  'pt-br',
  'ro',
  'sl',
  'sr',
  'sv',
  'th',
  'tr',
  'zh-hk',
];

export const APP_LOCALE_LANGUAGES_MAP = {
  'zh-hk': 'zh-Hant',
};

export const TRANSLATIONS_LOCATIONS = [
  'components/Form',
  'modules/App',
  'modules/Country',
  'modules/Account',
  'modules/Layout',
  'modules/BusinessLogicSystem',
  'modules/Organization',
  'modules/PublishSnapshot',
];

export const CONTACT_EMAIL = 'support@glucocontro.online';

export const LANGUAGE_SELECTOR_DROPDOWN = `${MODULE_ID}/languageSelectorDropdown`;

export const FEATURE_TOGGLES = {
  featureCgm: 'featureCgm',
};

export const LOGIN_REDIRECT_TO_KEY = 'loginRedirectToPath';

export const WEEK_DAYS = {
  Monday   : 'Monday',
  Tuesday  : 'Tuesday',
  Wednesday: 'Wednesday',
  Thursday : 'Thursday',
  Friday   : 'Friday',
  Saturday : 'Saturday',
  Sunday   : 'Sunday',
};

export const UNSAVED_CHANGES_MODAL = `${MODULE_ID}/unsavedChangesModal`;
