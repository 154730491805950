import { MODULE_ID } from './constants';


export const FETCH_ORGANIZATION_APPROVERS = `${MODULE_ID}/FETCH_ORGANIZATION_APPROVERS`;
export const FETCH_ORGANIZATION_APPROVERS_SUCCESS = `${MODULE_ID}/FETCH_ORGANIZATION_APPROVERS_SUCCESS`;
export const FETCH_ORGANIZATION_APPROVERS_ERROR = `${MODULE_ID}/FETCH_ORGANIZATION_APPROVERS_ERROR`;

//------------------------------------------------------------------------------------------------------------------

export const ADD_APPROVER = `${MODULE_ID}/ADD_APPROVER`;
export const ADD_APPROVER_SUCCESS = `${MODULE_ID}/ADD_APPROVER_SUCCESS`;
export const ADD_APPROVER_ERROR = `${MODULE_ID}/ADD_APPROVER_ERROR`;

//------------------------------------------------------------------------------------------------------------------

export const UPDATE_APPROVER = `${MODULE_ID}/UPDATE_APPROVER`;
export const UPDATE_APPROVER_SUCCESS = `${MODULE_ID}/UPDATE_APPROVER_SUCCESS`;
export const UPDATE_APPROVER_ERROR = `${MODULE_ID}/UPDATE_APPROVER_ERROR`;

//------------------------------------------------------------------------------------------------------------------

export const DELETE_APPROVER = `${MODULE_ID}/DELETE_APPROVER`;
export const DELETE_APPROVER_SUCCESS = `${MODULE_ID}/DELETE_APPROVER_SUCCESS`;
export const DELETE_APPROVER_ERROR = `${MODULE_ID}/DELETE_APPROVER_ERROR`;

