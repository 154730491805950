import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import CodeBlockDisplay from 'components/CodeBlockDisplay';
import messages from '../../messages';


class DiffJson extends React.Component {

  static propTypes = {
    // Explicit props
    publishVersion                    : PropTypes.string.isRequired,
    onCodeSizeToLargeToDisplay        : PropTypes.func,
    // Implicit props
    publishVersionDiffs               : PropTypes.any,
    isFetchPublishVersionDiffsProgress: PropTypes.bool,
    hasFetchPublishVersionDiffsErrors : PropTypes.bool,
  };


  constructor() {
    super();
    this.state = {
      isCodeSizeOutOfRange: false,
    };
  }


  onCodeSizeToLargeToDisplay() {
    this.setState({ isCodeSizeOutOfRange: true });
    if (this.props.onCodeSizeToLargeToDisplay) {
      this.props.onCodeSizeToLargeToDisplay();
    }
  }


  renderContent() {
    if (this.props.hasFetchPublishVersionDiffsErrors) {
      return (
        <p className="form-error pt-7 pb-7 justify-content-center mb-6">
          <FormattedMessage {...messages.infos.diffJsonFailed} />
        </p>
      );
    }
    if (this.state.isCodeSizeOutOfRange) {
      return (
        <p className="form-error pt-7 pb-7 justify-content-center mb-6">
          <FormattedMessage {...messages.infos.diffJsonToLarge} />
        </p>
      );
    }
    if (this.props.publishVersionDiffs) {
      return (
        <div className="mb-7">
          <CodeBlockDisplay
            language="json"
            showLineNumbers
            wrapLines
            code={this.props.publishVersionDiffs}
            onCodeSizeToLargeToDisplay={() => this.onCodeSizeToLargeToDisplay()}
          />
        </div>
      );
    }
    return null;
  }


  render() {
    if (this.props.isFetchPublishVersionDiffsProgress) {
      return null;
    }
    return (
      <>
        {this.renderContent()}
      </>
    );
  }

}

export default (DiffJson);
