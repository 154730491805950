exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".table__wrapper{max-width:620px;max-width:62rem;padding:0}.table__cell{padding:1.6rem 0;margin-right:3rem;width:365px;width:36.5rem}._1-uRr{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-align:center;align-items:center;gap:24px;width:228px;width:22.8rem}._1-uRr>.form-group{margin:0;width:102px;width:10.2rem}", ""]);

// exports
exports.locals = {
	"measurement__options": "_1-uRr"
};