import { defineMessages } from 'react-intl';


export default defineMessages({

  headers: {
    organizationApprovers: { id: 'organization.headers.organizationApprovers' },
    addNewApprover       : { id: 'organization.headers.addNewApprover' },
    editApprover         : { id: 'organization.headers.editApprover' },
    deleteApprover       : { id: 'organization.headers.deleteApprover' },
    filterApprover       : { id: 'organization.headers.filterApprover' },

  },
  labels: {
    email            : { id: 'organization.labels.email' },
    language         : { id: 'organization.labels.language' },
    assignedCountries: { id: 'organization.labels.assignedCountries' },
    managedCountries : { id: 'organization.labels.managedCountries' },
    firstName        : { id: 'organization.labels.firstName' },
    lastName         : { id: 'organization.labels.lastName' },
  },
  buttons: {
    addApprover: { id: 'organization.buttons.addApprover' },
    saveChanges: { id: 'organization.buttons.saveChanges' },
    filter     : { id: 'organization.buttons.filter' },
    inviteUser : { id: 'organization.buttons.inviteUser' },
    edit       : { id: 'organization.buttons.edit' },
  },
  placeholders: {
    searchApprover  : { id: 'organization.placeholders.searchApprover' },
    managedCountries: { id: 'organization.placeholders.managedCountries' },
    firstName       : { id: 'organization.placeholders.firstName' },
    lastName        : { id: 'organization.placeholders.lastName' },
  },
  infos: {
    addNewApprover       : { id: 'organization.infos.addNewApprover' },
    editApprover         : { id: 'organization.infos.editApprover' },
    confirmDeleteApprover: { id: 'organization.infos.confirmDeleteApprover' },
    managedCountries     : { id: 'organization.infos.managedCountries' },
  },

  errors: {
    businessErrors: {
      OrganizationApproverExist: { id: 'organization.errors.businessErrors.OrganizationApproverExist' },
    },
  },
});
