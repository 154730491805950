import { MODULE_ID } from './constants';


export const FETCH_PUBLISH_VERSIONS_PAGE = `${MODULE_ID}/FETCH_PUBLISH_VERSIONS_PAGE`;
export const FETCH_PUBLISH_VERSIONS_PAGE_SUCCESS = `${MODULE_ID}/FETCH_PUBLISH_VERSIONS_PAGE_SUCCESS`;
export const FETCH_PUBLISH_VERSIONS_PAGE_ERROR = `${MODULE_ID}/FETCH_PUBLISH_VERSIONS_PAGE_ERROR`;

export const FETCH_PUBLISH_VERSION_DIFFS = `${MODULE_ID}/FETCH_PUBLISH_VERSION_DIFFS`;
export const FETCH_PUBLISH_VERSION_DIFFS_SUCCESS = `${MODULE_ID}/FETCH_PUBLISH_VERSION_DIFFS_SUCCESS`;
export const FETCH_PUBLISH_VERSION_DIFFS_ERROR = `${MODULE_ID}/FETCH_PUBLISH_VERSION_DIFFS_ERROR`;

export const DOWNLOAD_PUBLISH_VERSION_DIFFS = `${MODULE_ID}/DOWNLOAD_PUBLISH_VERSION_DIFFS_COMMITS`;
export const DOWNLOAD_PUBLISH_VERSION_DIFFS_SUCCESS = `${MODULE_ID}/DOWNLOAD_PUBLISH_VERSION_DIFFS_SUCCESS`;
export const DOWNLOAD_PUBLISH_VERSION_DIFFS_ERROR = `${MODULE_ID}/DOWNLOAD_PUBLISH_VERSION_DIFFS_ERROR`;

export const FETCH_PUBLISH_VERSION_COMMITS = `${MODULE_ID}/FETCH_PUBLISH_VERSION_COMMITS`;
export const FETCH_PUBLISH_VERSION_COMMITS_SUCCESS = `${MODULE_ID}/FETCH_PUBLISH_VERSION_COMMITS_SUCCESS`;
export const FETCH_PUBLISH_VERSION_COMMITS_ERROR = `${MODULE_ID}/FETCH_PUBLISH_VERSION_COMMITS_ERROR`;

export const SNAPSHOT_DETAILS_FINISH = `${MODULE_ID}/SNAPSHOT_DETAILS_FINISH`;

export const VALIDATE_PUBLISH_VERSION = `${MODULE_ID}/VALIDATE_PUBLISH_VERSION`;
export const VALIDATE_PUBLISH_VERSION_SUCCESS = `${MODULE_ID}/VALIDATE_PUBLISH_VERSION_SUCCESS`;
export const VALIDATE_PUBLISH_VERSION_ERROR = `${MODULE_ID}/VALIDATE_PUBLISH_VERSION_ERROR`;

export const VALIDATE_PUBLISH_VERSION_FINISH = `${MODULE_ID}/VALIDATE_PUBLISH_VERSION_FINISH`;

export const SET_PUBLISHING_PROGRESS = `${MODULE_ID}/SET_PUBLISHING_PROGRESS`;
export const SET_PUBLISHING_PROGRESS_SUCCESS = `${MODULE_ID}/SET_PUBLISHING_PROGRESS_SUCCESS`;
export const SET_PUBLISHING_PROGRESS_ERROR = `${MODULE_ID}/SET_PUBLISHING_PROGRESS_ERROR`;

export const ADD_NEW_PUBLISH_VERSION = `${MODULE_ID}/ADD_NEW_PUBLISH_VERSION`;
export const ADD_NEW_PUBLISH_VERSION_SUCCESS = `${MODULE_ID}/ADD_NEW_PUBLISH_VERSION_SUCCESS`;
export const ADD_NEW_PUBLISH_VERSION_ERROR = `${MODULE_ID}/ADD_NEW_PUBLISH_VERSION_ERROR`;

export const INITIALIZE_PUBLISH_VERSION = `${MODULE_ID}/INITIALIZE_PUBLISH_VERSION`;
export const INITIALIZE_PUBLISH_VERSION_SUCCESS = `${MODULE_ID}/INITIALIZE_PUBLISH_VERSION_SUCCESS`;
export const INITIALIZE_PUBLISH_VERSION_ERROR = `${MODULE_ID}/INITIALIZE_PUBLISH_VERSION_ERROR`;

export const FETCH_LAST_PUBLISH = `${MODULE_ID}/FETCH_LAST_PUBLISH`;
export const FETCH_LAST_PUBLISH_SUCCESS = `${MODULE_ID}/FETCH_LAST_PUBLISH_SUCCESS`;
export const FETCH_LAST_PUBLISH_ERROR = `${MODULE_ID}/FETCH_LAST_PUBLISH_ERROR`;
