import { defineMessages } from 'react-intl';


export default defineMessages({
  headers: {
    publishSnapshot     : { id: 'publishSnapshot.headers.publishSnapshot' },
    publishNewVersion   : { id: 'publishSnapshot.headers.publishNewVersion' },
    publishRecentChanges: { id: 'publishSnapshot.headers.publishRecentChanges' },
    publishPending      : { id: 'publishSnapshot.headers.publishPending' },
    publishingStatus    : { id: 'publishSnapshot.headers.publishingStatus' },
  },
  labels: {
    publishSnapshotStatus: { id: 'publishSnapshot.labels.publishSnapshotStatus' },
    version              : { id: 'publishSnapshot.labels.version' },
    commitsCount         : { id: 'publishSnapshot.labels.commitsCount' },
    userName             : { id: 'publishSnapshot.labels.userName' },
    dateTime             : { id: 'publishSnapshot.labels.dateTime' },
    id                   : { id: 'publishSnapshot.labels.id' },
    title                : { id: 'publishSnapshot.labels.title' },
    component            : { id: 'publishSnapshot.labels.component' },
    message              : { id: 'publishSnapshot.labels.message' },
    status               : { id: 'publishSnapshot.labels.status' },
    duration             : { id: 'publishSnapshot.labels.duration' },
    term                 : { id: 'publishSnapshot.labels.term' },
    countryCode          : { id: 'publishSnapshot.labels.countryCode' },
    languageNames        : { id: 'publishSnapshot.labels.languageNames' },
  },
  buttons: {
    publish     : { id: 'publishSnapshot.buttons.publish' },
    details     : { id: 'publishSnapshot.buttons.details' },
    backToReview: { id: 'publishSnapshot.buttons.backToReview' },
  },
  placeholders: {
  },
  infos: {
    diffJsonToLarge            : { id: 'publishSnapshot.infos.diffJsonToLarge' },
    diffJsonFailed             : { id: 'publishSnapshot.infos.diffJsonFailed' },
    validationSucceeded        : { id: 'publishSnapshot.infos.validationSucceeded' },
    validationFailed           : { id: 'publishSnapshot.infos.validationFailed' },
    validationError            : { id: 'publishSnapshot.infos.validationError' },
    publishPending             : { id: 'publishSnapshot.infos.publishPending' },
    loadingNewVersion          : { id: 'publishSnapshot.infos.loadingNewVersion' },
    noneChangesToPublish       : { id: 'publishSnapshot.infos.noneChangesToPublish' },
    validating                 : { id: 'publishSnapshot.infos.validating' },
    initializePublishNewVersion: { id: 'publishSnapshot.infos.initializePublishNewVersion' },
  },
  publishStatus: {
    publishing: { id: 'publishSnapshot.publishStatus.publishing' },
    published : { id: 'publishSnapshot.publishStatus.published' },
    failed    : { id: 'publishSnapshot.publishStatus.failed' },
    created   : { id: 'publishSnapshot.publishStatus.created' },
    success   : { id: 'publishSnapshot.publishStatus.success' },
  },
  tabs: {
    snapshotDetails: {
      commitsTable: { id: 'publishSnapshot.tabs.snapshotDetails.commitsTable' },
      diffJson    : { id: 'publishSnapshot.tabs.snapshotDetails.diffJson' },
    },
    validationFailed: {
      terms       : { id: 'publishSnapshot.tabs.validationFailed.terms' },
      translations: { id: 'publishSnapshot.tabs.validationFailed.translations' },
      regexps     : { id: 'publishSnapshot.tabs.validationFailed.regexps' },
    },
  },
  steps: {
    publish: {
      review  : { id: 'publishSnapshot.steps.publish.review' },
      validate: { id: 'publishSnapshot.steps.publish.validate' },
      publish : { id: 'publishSnapshot.steps.publish.publish' },
    },
  },
  cancelReasons: {
    InProgress           : { id: 'publishSnapshot.cancelReasons.InProgress' },
    AlreadyPublished     : { id: 'publishSnapshot.cancelReasons.AlreadyPublished' },
    SBSubscriptionMissing: { id: 'publishSnapshot.cancelReasons.SBSubscriptionMissing' },
    details              : {
      SBSubscriptionMissing: { id: 'publishSnapshot.cancelReasons.details.SBSubscriptionMissing' },
    },
  },
  errors: {
    businessErrors: {
      PublishIsProcessing  : { id: 'publishSnapshot.errors.businessErrors.publishIsProcessing' },
      PublishValidationFail: { id: 'publishSnapshot.errors.businessErrors.publishValidationFail' },
    },
  },
});
