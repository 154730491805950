import { call, put, takeLatest, all, getContext, delay } from 'redux-saga/effects';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import App from 'modules/App';
import ApiService from 'services/ApiService';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as constants from './constants';
import messages from './messages';


function* fetchBLSList() {
  try {
    const requestUrl = '/api/Component';
    const response = yield call(ApiService.originalRequest, requestUrl);
    yield put(actions.fetchBLSListSuccess(response));
  } catch (err) {
    yield put(actions.fetchBLSListError(err));
    yield call(App.dispatchError, err, messages);
  }
}


function* addBLS({ payload }) {
  try {
    const { name } = payload;
    const requestUrl = '/api/Component';
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'POST',
      body  : {
        component: name,
      },
    });
    yield put(actions.addBLSSuccess());
  } catch (err) {
    yield put(actions.addBLSError(err));
    yield call(App.dispatchError, err, messages);
  }
}

//------------------------------------------------------------------------------------------------------------------

function mapCountriesAssignment(countriesDefinitions, blsConfiguration) {
  const countriesAssignment = [];
  forEach(blsConfiguration.regions, (region) => {
    forEach(region.countries, (countryCode) => {
      const index = findIndex(countriesDefinitions,
        (countryDefinition) => countryDefinition.code === countryCode);
      const { code, englishName } = countriesDefinitions[index];
      countriesAssignment.push(
        {
          code,
          englishName,
          regionName: region.name,
        }
      );
      countriesDefinitions.splice(index, 1);
    });
  }
  );
  countriesAssignment.push(...countriesDefinitions.map(
    ({ code, englishName }) => (
      {
        code,
        englishName,
        regionName: constants.UNSPECIFIED_REGION_NAME,
      }
    )));
  return countriesAssignment;
}


function* fetchBLSConfigurationRegions({ payload }) {
  try {
    const { blsName } = payload;
    const fetch = yield getContext('fetch');
    const [countries, blsConfiguration] = yield all([
      call(ApiService.originalRequest, '/api/Country', null, fetch),
      call(ApiService.originalRequest, `/api/Component/${blsName}/Configuration`, null, fetch),
    ]);
    const countriesAssignments = mapCountriesAssignment(countries, blsConfiguration);
    const regions = blsConfiguration.regions.map((region) => ({ name: region.name }));
    yield put(actions.fetchBLSConfigurationRegionsSuccess(countriesAssignments, regions));
  } catch (err) {
    yield put(actions.fetchBLSConfigurationRegionsError(err));
    yield call(App.dispatchError, err, messages);
  }
}


//------------------------------------------------------------------------------------------------------------------

function mapRegions(regions, countriesAssignments) {
  const result = {
    regions: regions.map((region) => ({
      name     : region.name,
      countries: [],
    })),
  };
  forEach(countriesAssignments, (countriesAssignment) => {
    if (countriesAssignment.regionName !== constants.UNSPECIFIED_REGION_NAME) {
      const regionToAssign = find(result.regions,
        (region) => countriesAssignment.regionName === region.name);
      regionToAssign.countries.push(countriesAssignment.code);
    }
  });
  return result;
}

function* updateBLSConfigurationRegions({ payload }) {
  try {
    const { blsName, regions, countriesAssignments } = payload;
    const requestUrl = `/api/Component/${blsName}/Configuration/Regions`;
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'PUT',
      body  : { ...mapRegions(regions, countriesAssignments) },
    });
    yield put(actions.updateBLSConfigurationRegionsSuccess());
  } catch (err) {
    const businessError = get(err, 'businessError', null);
    if (businessError) {
      yield put(actions.updateBLSConfigurationRegionsError(businessError));
    } else {
      yield put(actions.updateBLSConfigurationRegionsError(null));
      yield call(App.dispatchError, err, messages);
    }

  }
}

//------------------------------------------------------------------------------------------------------------------

function* deleteBLS({ payload }) {
  try {
    const { blsName } = payload;
    const requestUrl = `/api/Component/${blsName}`;
    yield call(ApiService.regionalRequest, requestUrl, {
      method: 'DELETE',
    });
    yield delay(2000);
    yield put(actions.deleteBLSSuccess());
  } catch (err) {
    const businessError = get(err, 'businessError', null);
    if (businessError) {
      yield put(actions.deleteBLSError(businessError));
    } else {
      yield put(actions.deleteBLSError(null));
      yield call(App.dispatchError, err, messages);
    }
  }
}


//----------------------------------------------------------------------------------------------------------------------
/* eslint-disable func-names */
function* sagas() {
  yield takeLatest(actionTypes.FETCH_BLS_LIST, fetchBLSList);
  yield takeLatest(actionTypes.ADD_BLS, addBLS);
  yield takeLatest(actionTypes.FETCH_BLS_CONFIGURATION_REGIONS, fetchBLSConfigurationRegions);
  yield takeLatest(actionTypes.UPDATE_BLS_CONFIGURATION_REGIONS, updateBLSConfigurationRegions);
  yield takeLatest(actionTypes.DELETE_BLS, deleteBLS);
}

export default [
  sagas,
];
