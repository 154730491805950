exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".jnkUX,._6xMtx{display:-ms-flexbox;display:flex;gap:24px;gap:2.4rem}", ""]);

// exports
exports.locals = {
	"review__CommitsTableBtnContainer": "jnkUX",
	"review__DiffJsonBtnContainer": "_6xMtx"
};