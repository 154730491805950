exports = module.exports = require("../../../../../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".YRM9s{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;gap:32px;margin-bottom:32px}.YRM9s ._2GFSp{display:block;width:24px;height:24px;padding-right:8px}.YRM9s ._2DUgn{margin-right:-20px;margin-left:auto;display:-ms-flexbox;display:flex}.YRM9s ._2DUgn input{width:330px}.YRM9s ._2DUgn svg{display:block;width:20px;width:2rem;height:20px;height:2rem;position:relative;left:-30px;left:-3rem;top:8px;top:.8rem}._3a3Ox{margin-bottom:8px}._3a3Ox,._3a3Ox div{display:-ms-flexbox;display:flex;-ms-flex-direction:row;flex-direction:row;-ms-flex-pack:justify;justify-content:space-between}._3a3Ox div{width:calc(50% - 32px)}._16w0S{cursor:pointer;display:block;width:20px;width:2rem;height:20px;height:2rem}._16w0S>g{fill:#da1818}.table__wrapper{padding:0}.table__cell{padding:1.6rem 0;margin-right:3rem;width:365px;width:36.5rem}tbody{-webkit-column-count:2;-moz-column-count:2;column-count:2;-webkit-column-gap:64px;-moz-column-gap:64px;column-gap:64px;display:block}tbody tr{display:-ms-flexbox;display:flex}tbody .table__row:last-child{border-bottom:1px solid #e0e8f2}.table__cell--name{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;display:-ms-flexbox;display:flex;max-width:420px;max-width:42rem}.table__cell--name b{-o-text-overflow:ellipsis;text-overflow:ellipsis;overflow:hidden}.table__cell--action{width:20px;margin-right:0;margin-left:auto}", ""]);

// exports
exports.locals = {
	"header": "YRM9s",
	"header__addButton__icon": "_2GFSp",
	"header__search": "_2DUgn",
	"table__headers": "_3a3Ox",
	"deleteIcon": "_16w0S"
};