import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import Button from 'components/Form/Button';
import App from 'modules/App';
import messages from '../messages';
import * as actions from '../actions';
import * as selectors from '../selectors';
import * as constants from '../constants';
import StatusChip from '../partials/StatusChip';
import SnapshotsTable from './SnapshotsTable';
import SnapshotDetailsModal from './SnapshotsTable/SnapshotDetailsModal';
import styles from './PublishSnapshot.pcss';
import PublishModal from './PublishModal';
import PublishPendingModal from './PublishPendingModal';


class PublishSnapshot extends React.Component {

  static propTypes = {
    // Explicit props

    // Implicit props
    lastPublishStatus             : PropTypes.string,
    publishingStatus              : PropTypes.object,
    openModalId                   : PropTypes.string,
    isSignalRConnected            : PropTypes.bool,
    isSetPublishingProgressSending: PropTypes.bool,
    onOpenDetailsModal            : PropTypes.func,
    onOpenPublishModal            : PropTypes.func,
    onOpenPublishPendingModal     : PropTypes.func,
    onCloseModal                  : PropTypes.func,
    onCreateSignalRHub            : PropTypes.func,
    onFetchPublishingStatus       : PropTypes.func,
    onSendJoinPublishSnapshotPage : PropTypes.func,
    onSendLeavePublishSnapshotPage: PropTypes.func,
    onFetchLastPublish            : PropTypes.func,
  }


  constructor(props) {
    super(props);
    this.state = {
      activeSnapshotDetails: null,
    };
    this.props.onFetchLastPublish();
    if (this.props.isSignalRConnected) {
      this.initSignalR();
    } else {
      this.props.onCreateSignalRHub();
    }
  }


  componentDidUpdate(prevProps) {
    if (!prevProps.isSignalRConnected && this.props.isSignalRConnected) {
      this.initSignalR();
    }
    if (prevProps.isSetPublishingProgressSending && !this.props.isSetPublishingProgressSending) {
      if (this.props.publishingStatus.isInProgress) {
        this.props.onOpenPublishPendingModal();
      } else {
        this.props.onOpenPublishModal();
      }
    }
  }


  componentWillUnmount() {
    this.props.onSendLeavePublishSnapshotPage();
  }


  onClickSnapshotDetails(snapShot) {
    this.setState({
      activeSnapshotDetails: snapShot,
    });
    this.props.onOpenDetailsModal();
  }

  onClickPublish() {
    this.props.onFetchPublishingStatus();
  }

  initSignalR() {
    this.props.onSendJoinPublishSnapshotPage();
  }


  renderAlerts() {
    if (this.props.openModalId) return <div className="mb-5" />;
    return <App.components.AlertsBus className="mb-5" />;
  }


  renderLastPublishStatus() {
    if (!this.props.lastPublishStatus) return null;
    return (
      <div className={styles.header__publishStatus}>
        <p className={styles.header__publishSnapshotStatus}>
          <FormattedMessage {...messages.labels.publishSnapshotStatus} />
        </p>
        <StatusChip
          status={this.props.lastPublishStatus}
        />
      </div>
    );
  }


  renderHeader() {
    return (
      <div className={styles.header}>
        <header className="text--h1">
          <FormattedMessage {...messages.headers.publishSnapshot} />
        </header>
        {this.renderLastPublishStatus()}
        <Button
          type="button"
          styleModifier="primary"
          labelMessage={messages.buttons.publish}
          className={cn(styles.header__publishButton, 'btn--block btn--filled')}
          isDisabled={!this.props.isSignalRConnected}
          onClick={() => this.onClickPublish()}
          titleMessage={this.props.isSignalRConnected ? null
            : App.messages.errors.businessErrors.SignalRError}
        />
      </div>
    );
  }


  renderContent() {
    return (
      <SnapshotsTable
        onDetailsClick={(snapshot) => { this.onClickSnapshotDetails(snapshot); }}
      />
    );
  }


  renderModals() {
    if (this.props.openModalId === constants.SNAPSHOT_DETAILS_MODAL) {
      return (
        <SnapshotDetailsModal
          openModalId={this.props.openModalId}
          snapShot={this.state.activeSnapshotDetails}
          onClose={this.props.onCloseModal}
        />
      );
    }
    if (this.props.openModalId === constants.PUBLISH_MODAL) {
      return (
        <PublishModal
          openModalId={this.props.openModalId}
        />
      );
    }
    if (this.props.openModalId === constants.PUBLISH_PENDING_MODAL) {
      return (
        <PublishPendingModal
          openModalId={this.props.openModalId}
          onClose={this.props.onCloseModal}
          account={this.props.publishingStatus.account}
        />
      );
    }
    return null;
  }


  render() {
    return (
      <div className={styles.publishSnapshot}>
        {this.renderAlerts() }
        {this.renderHeader()}
        {this.renderContent()}
        {this.renderModals()}
      </div>
    );
  }

}


const mapStateToProps = (state) => (
  {
    isSignalRConnected            : App.selectors.isSignalRConnected(state),
    openModalId                   : App.selectors.modal(state),
    publishingStatus              : selectors.publishingStatus(state),
    isSetPublishingProgressSending: selectors.isSetPublishingProgressSending(state),
    lastPublishStatus             : selectors.lastPublishStatus(state),
  });

const mapDispatchToProps = (dispatch) => {
  const { PUBLISH_SNAPSHOT_SEND, PUBLISH_SNAPSHOT_INVOKE } = constants;
  return (
    {
      onFetchPublishingStatus: () => dispatch(App.actions.signalRInvoke(
        PUBLISH_SNAPSHOT_INVOKE.IsPublishInProgress, null,
        {
          init   : actions.setPublishingProgress,
          success: actions.setPublishingProgressSuccess,
          error  : actions.setPublishingProgressError,
        }
      )),
      onFetchLastPublish           : () => dispatch(actions.fetchLastPublish()),
      onSendJoinPublishSnapshotPage: () => dispatch(App.actions.signalRSend(
        PUBLISH_SNAPSHOT_SEND.JoinPublishSnapshotPage, null
      )),
      onSendLeavePublishSnapshotPage: () => dispatch(App.actions.signalRSend(
        PUBLISH_SNAPSHOT_SEND.LeavePublishSnapshotPage, null
      )),
      onCreateSignalRHub       : () => dispatch(App.actions.createSignalRHub()),
      onCloseModal             : () => dispatch(App.actions.closeModal()),
      onOpenDetailsModal       : () => dispatch(App.actions.openModal(constants.SNAPSHOT_DETAILS_MODAL)),
      onOpenPublishModal       : () => dispatch(App.actions.openModal(constants.PUBLISH_MODAL)),
      onOpenPublishPendingModal: () => dispatch(App.actions.openModal(constants.PUBLISH_PENDING_MODAL)),
    }
  );
};


const ConnectedPublishSnapshot = connect(
  mapStateToProps,
  mapDispatchToProps,
)(PublishSnapshot);


export default withStyles(styles)(ConnectedPublishSnapshot);
