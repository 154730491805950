import React from 'react';
import PropTypes from 'prop-types';
import SyntaxHighlighter from 'react-syntax-highlighter';
import isObject from 'lodash/isObject';
import isString from 'lodash/isString';
import toString from 'lodash/toString';
import withStyles from 'isomorphic-style-loader/withStyles';
import { docco } from 'react-syntax-highlighter/dist/cjs/styles/hljs';
import styles from './CodeBlockDisplay.pcss';


class CodeBlockDisplay extends React.Component {

  static propTypes = {
    code                      : PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    language                  : PropTypes.string,
    showLineNumbers           : PropTypes.bool,
    wrapLines                 : PropTypes.bool,
    onCodeSizeToLargeToDisplay: PropTypes.func,
  }


  static defaultProps = {
    language: 'text',
  };


  get maxSizeOfCodekB() {
    return 256; // 0,25 MB
  }


  get codeString() {
    let codeString = null;
    if (this.props.language === 'json' && isObject(this.props.code)) {
      codeString = JSON.stringify(this.props.code, null, 2);
    } else if (isString(this.props.code)) {
      codeString = this.props.code;
    } else {
      codeString = toString(this.props.code);
    }
    return codeString;
  }


  render() {
    const { codeString } = this;
    const sizeOfCode = codeString.length / 1024;
    if (sizeOfCode > this.maxSizeOfCodekB) {
      this.props.onCodeSizeToLargeToDisplay();
      return null;
    }
    return (
      <SyntaxHighlighter
        className={styles.codeBlockDisplay}
        style={docco}
        language={this.props.language}
        showLineNumbers={this.props.showLineNumbers}
        wrapLines={this.props.wrapLines}
      >
        {codeString}
      </SyntaxHighlighter>
    );
  }

}


export default withStyles(styles)(CodeBlockDisplay);

