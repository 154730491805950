import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';
import { FormattedMessage } from 'react-intl';
import findIndex from 'lodash/findIndex';
import find from 'lodash/find';
import includes from 'lodash/includes';
import filter from 'lodash/filter';
import lowerCase from 'lodash/lowerCase';
import isEqual from 'lodash/isEqual';
import forEach from 'lodash/forEach';
import { AppContext } from 'context';
import ChevronRight from 'svg/chevron-right.svg';
import DeleteIcon from 'svg/delete.svg';
import AddIcon from 'svg/add.svg';
import Button from 'components/Form/Button';
import Input from 'components/Form/Input';
import CheckboxRadio from 'components/Form/CheckboxRadio';
import SubmitOrDiscardButtonsContainer from 'components/Form/SubmitOrDiscardButtonsContainer';
import Select from 'components/Form/Select';
import Form from 'components/Form';
import UnsavedChangesModal from 'components/UnsavedChangesModal';
import Tag from 'components/Tag';
import Table from 'components/Table';
import App from 'modules/App';
import * as actions from '../../actions';
import messages from '../../messages';
import * as constants from '../../constants';
import * as selectors from '../../selectors';
import AddBLSRegionModal from './AddBLSRegionModal';
import UpdateBLSConfigurationFailedModal from './UpdateBLSConfigurationFailedModal';
import styles from './ConfigureBLSRegions.pcss';


class ConfigureBLSRegions extends React.Component {

    static contextType = AppContext;

    static propTypes = {
      // Explicit props
      blsName                                : PropTypes.string.isRequired,
      // Implicit props
      isFetchInProgress                      : PropTypes.bool,
      isUpdateInProgress                     : PropTypes.bool,
      openModalId                            : PropTypes.string,
      blsConfiguration                       : PropTypes.object,
      updateBlsConfigurationError            : PropTypes.object,
      // Implicit actions
      onFetchBLSConfigurationRegions         : PropTypes.func,
      onUpdateBLSConfigurationRegions        : PropTypes.func,
      onOpenModal                            : PropTypes.func,
      onCloseModal                           : PropTypes.func,
      onOpenAddRegionModal                   : PropTypes.func,
      onOpenUpdateBLSConfigurationFailedModal: PropTypes.func,
    };


    constructor(props) {
      super(props);
      this.state = {
        hasUnsavedChanges   : false,
        formInitialState    : null,
        countriesAssignments: [],
        regions             : null,
        regionFilterTags    : null,
        checkedCountries    : [],
        isInitialized       : false,
      };
    }


    componentDidMount() {
      this.props.onFetchBLSConfigurationRegions(this.props.blsName);
    }


    componentDidUpdate(prevProps) {
      if (!prevProps.updateBlsConfigurationError && this.props.updateBlsConfigurationError) {
        this.props.onOpenUpdateBLSConfigurationFailedModal();
        return;
      }
      if (prevProps.isFetchInProgress && !this.props.isFetchInProgress) {
        this.onInitialize();
      }
      if (prevProps.isUpdateInProgress && !this.props.isUpdateInProgress) {
        this.props.onFetchBLSConfigurationRegions(this.props.blsName);
      }
      if (!this.props.isFetchInProgress && this.state.formInitialState) {
        this.onSetFormStatus();
      }
    }


    onInitialize() {
      const { regions, countriesAssignments } = JSON.parse(JSON.stringify(this.props.blsConfiguration));
      const regionFilterTags = [...regions, { name: constants.UNSPECIFIED_REGION_NAME }]
        .map(({ name }) =>
          (
            {
              name,
              isActive: false,
            }
          )
        );
      this.setState(
        {
          formInitialState    : JSON.parse(JSON.stringify(this.props.blsConfiguration)),
          countriesAssignments: [...countriesAssignments],
          regions,
          regionFilterTags,
          checkedCountries    : [],
          isInitialized       : true,
        }
      );
    }


    onSubmit() {
      this.props.onUpdateBLSConfigurationRegions(
        this.props.blsName, this.state.regions, this.state.countriesAssignments);
    }


    onReset() {
      this.setState((state) => {
        const { countriesAssignments, regions } = JSON.parse(JSON.stringify(state.formInitialState));
        const regionFilterTags = [...regions, { name: constants.UNSPECIFIED_REGION_NAME }]
          .map(({ name }) =>
            (
              {
                name,
                isActive: false,
              }
            )
          );
        return ({
          countriesAssignments,
          regions,
          regionFilterTags,
        });
      });
    }


    onBackButtonClick() {
      if (this.state.hasUnsavedChanges) {
        this.props.onOpenModal();
      } else {
        this.context.redirect(this.context.getUrl('businessLogicSystems'));
      }
    }


    onDeleteRegion(name) {
      this.setState((state) => {
        const { countriesAssignments } = state;
        forEach(countriesAssignments, (countriesAssignment) => {
          if (countriesAssignment.regionName === name) {
            countriesAssignment.regionName = constants.UNSPECIFIED_REGION_NAME;
          }
        });
        return {
          regions         : filter(state.regions, (region) => region.name !== name),
          regionFilterTags: filter(state.regionFilterTags, (region) => region.name !== name),
          countriesAssignments,
        };
      });
    }


    onRegionTagClick(tag) {
      this.setState((state) => {
        const { regionFilterTags } = state;
        const index = findIndex(regionFilterTags, (regionFilterTag) => regionFilterTag.name === tag.name);
        regionFilterTags[index].isActive = !tag.isActive;
        return {
          regionFilterTags,
        };
      });
    }


    onSelectCountryChange(input) {
      this.setState((state) => {
        const country = find(state.countriesAssignments,
          (countriesAssignment) => countriesAssignment.code === input.id);
        const checkedCountries = input.value === 'true'
          ? [...state.checkedCountries, country]
          : filter(state.checkedCountries, (checkedCountry) => checkedCountry.code !== country.code);
        return ({
          checkedCountries,
        });
      });
    }


    onAssignCheckedCountriesTo(input) {
      this.setState((state) => {
        const { countriesAssignments, checkedCountries } = state;
        forEach(checkedCountries, (checkedCountry) => {
          const country = find(countriesAssignments,
            (countriesAssignment) => countriesAssignment.code === checkedCountry.code);
          country.regionName = input.value;
        });
        return (
          {
            countriesAssignments,
            checkedCountries: [],
          }
        );
      }
      );
    }


    onSetFormStatus() {
      const hasUnsavedChanges = !isEqual(this.state.formInitialState.regions, this.state.regions)
      || !isEqual(this.state.formInitialState.countriesAssignments, this.state.countriesAssignments);

      if (this.state.hasUnsavedChanges !== hasUnsavedChanges) {
        this.setState({ hasUnsavedChanges });
      }
    }

    onAddBLSRegion({ name }) {
      this.setState((state) => (
        {
          regions         : [...state.regions, { name }],
          regionFilterTags: [...state.regionFilterTags, { name, isActive: false }],
        }
      ));
      this.props.onCloseModal();
    }


    get schema() {
      return [
        {
          key     : 'englishName',
          renderer: (country) => (
            <CheckboxRadio
              id={country.code}
              inputValue="true"
              value={!find(this.state.checkedCountries,
                (checkedCountry) => checkedCountry.code === country.code) ? 'false' : 'true'}
              labelMessage={country.englishName}
              onChange={(input) => this.onSelectCountryChange(input)}
            />
          ),
        },
        {
          key     : 'region',
          renderer: (country) => (
            <p>
              {country.regionName}
            </p>
          ),
        },
      ];
    }


    get entities() {
      let entities = [...this.state.countriesAssignments];
      const activeTags = filter(this.state.regionFilterTags,
        (regionFilterTags) => regionFilterTags.isActive) || [];
      if (activeTags.length) {
        const activeTagsRegions = activeTags.map((activeTag) => activeTag.name);
        entities = filter(entities,
          (countriesAssignment) => includes(activeTagsRegions, countriesAssignment.regionName));
      }
      return this.sortEntities(entities);
    }

    sortEntities(entities) {
      return entities.sort((prev, next) => {
        if (lowerCase(prev.regionName) > lowerCase(next.regionName)) { return 1; }
        if (lowerCase(prev.regionName) < lowerCase(next.regionName)) { return -1; }
        if (lowerCase(prev.englishName) > lowerCase(next.englishName)) { return 1; }
        if (lowerCase(prev.englishName) < lowerCase(next.englishName)) { return -1; }
        return -1;
      });
    }

    renderHeader() {
      return (
        <div className={styles.ConfigureBLSRegions__header}>
          <Button
            type="button"
            onClick={() => this.onBackButtonClick()}
          >
            <ChevronRight className="backArrow" />
            <FormattedMessage
              {...messages.buttons.backToListBLS}
            />
          </Button>
          <div>
            <header className="text--h1"> {this.props.blsName}</header>
          </div>
        </div>
      );
    }


    renderRegion({ name }) {
      return (
        <div key={name} className="d-flex align-items-center col-3 pl-0 mb-5">
          <Input
            className="mr-5"
            value={name}
            isDisabled
            onChange={() => {}}
          />
          <DeleteIcon
            className={styles.deleteIcon}
            onClick={() => this.onDeleteRegion(name)}
          />
        </div>
      );
    }


    renderRegions() {
      return (
        <div className={styles.regions}>
          <div className="d-flex justify-content-between">
            <h5 className="text--h5 mt-0">
              <FormattedMessage {...messages.headers.regions} />
            </h5>
            {this.renderAddNewRegionButton()}
          </div>

          {this.state.regions.map((region) => this.renderRegion(region))}
        </div>
      );
    }


    renderAddNewRegionButton() {
      return (
        <Button
          type="button"
          styleModifier="primary"
          className="btn--filled"
          onClick={() => this.props.onOpenAddRegionModal()}
        >
          <AddIcon className={styles.addButton__icon} />
          <p><FormattedMessage {...App.messages.buttons.addNew} /></p>
        </Button>
      );
    }


    renderTags() {
      return (
        <div className={styles.tags}>
          { this.state.regionFilterTags.map((regionFilterTag) => (
            <Tag
              key={regionFilterTag.name}
              titleMessage={regionFilterTag.name}
              isActive={regionFilterTag.isActive}
              onClick={() => this.onRegionTagClick(regionFilterTag)}
            />
          ))}
        </div>
      );
    }


    renderAssignCheckedCountriesSelect() {
      return (
        <Select
          optionsFrom={this.state.regionFilterTags}
          valueKey="name"
          labelKey="name"
          className={styles.assignCheckedCountriesSelect}
          value={this.state.regionToAssignCheckedCountries}
          noValueMessage={!this.state.checkedCountries.length
            ? messages.placeholders.selectCountriesToAssignRegion
            : messages.placeholders.assignRegion
          }
          isDisabled={!this.state.checkedCountries.length}
          onChange={(input) => this.onAssignCheckedCountriesTo(input)}
        />
      );
    }


    renderTableHeaders() {
      return (
        <div className={styles.table__headers}>
          <div>
            <h2 className="text--paragraph"><FormattedMessage {...messages.headers.country} /></h2>
            <h2 className="text--paragraph"><FormattedMessage {...messages.headers.region} /></h2>
          </div>
          <div>
            <h2 className="text--paragraph"><FormattedMessage {...messages.headers.country} /></h2>
            <h2 className="text--paragraph"><FormattedMessage {...messages.headers.region} /></h2>
          </div>
        </div>
      );
    }


    renderTable() {
      return (
        <>
          {this.renderTableHeaders()}
          <Table
            idKey="code"
            schema={this.schema}
            entities={this.entities}
            perPage={20}
            isPerPageOff
            isHeaderOff
          />
        </>
      );
    }


    renderCountriesAssignment() {
      return (
        <div className="mb-7">
          <h5 className="text--h5 mb-0">
            <FormattedMessage {...messages.headers.listOfCountries} />
          </h5>
          <p className="mb-7 mt-1"><FormattedMessage {...messages.infos.listOfCountries} /></p>
          <div className="d-flex align-items-start justify-content-between mb-7">
            {this.renderTags()}
            {this.renderAssignCheckedCountriesSelect()}
          </div>
          {this.renderTable()}
        </div>
      );
    }


    renderContent() {
      return (
        <div className="card card--mainContent justify-content-between">
          <div>
            {this.renderRegions()}
            {this.renderCountriesAssignment()}
          </div>
          <Form
            onSubmit={() => this.onSubmit()}
            onReset={() => this.onReset()}
          >
            <SubmitOrDiscardButtonsContainer
              isSaveEnable={this.state.hasUnsavedChanges}
              isDiscardEnable={this.state.hasUnsavedChanges}
              isInProgress={this.props.isUpdateInProgress}
            />
          </Form>

        </div>
      );
    }


    renderModals() {
      if (this.props.openModalId === App.constants.UNSAVED_CHANGES_MODAL) {
        return (
          <UnsavedChangesModal
            headerMessage={App.messages.headers.unsavedConfiguration}
            labelMessage={messages.infos.unsavedConfigurationOnClickBack}
            onClose={this.props.onCloseModal}
            openModalId={this.props.openModalId}
          />
        );
      }
      if (this.props.openModalId === constants.ADD_BLS_REGION_MODAL) {
        return (
          <AddBLSRegionModal
            openModalId={this.props.openModalId}
            existingRegions={this.state.regions ? this.state.regions.map((x) => x.name) : []}
            onSubmit={(input) => this.onAddBLSRegion(input)}
          />
        );
      }
      if (this.props.openModalId === constants.UPDATE_BLS_CONFIGURATION_FAILED_MODAL) {
        return (
          <UpdateBLSConfigurationFailedModal
            openModalId={this.props.openModalId}
            businessError={this.props.updateBlsConfigurationError}
          />
        );
      }
      return null;
    }


    renderAlerts() {
      if (this.props.openModalId) return <div className="mb-5" />;
      return <App.components.AlertsBus className="mb-5" />;
    }


    render() {
      if (!this.state.isInitialized) {
        return null;
      }
      return (
        <div className={styles.ConfigureBLSRegions}>
          {this.renderAlerts() }
          {this.renderHeader()}
          {this.renderContent()}
          {this.renderModals()}
        </div>
      );
    }

}


const mapStateToProps = (state) => (
  {
    blsConfiguration           : selectors.blsConfiguration(state),
    openModalId                : App.selectors.modal(state),
    isFetchInProgress          : selectors.isFetchBLSConfigurationRegionsProgress(state),
    isUpdateInProgress         : selectors.isUpdateBLSConfigurationRegionsProgress(state),
    updateBlsConfigurationError: selectors.updateBlsConfigurationError(state),
  });

const mapDispatchToProps = (dispatch) => ({
  onFetchBLSConfigurationRegions : (BLSRegionsCode) => dispatch(actions.fetchBLSConfigurationRegions(BLSRegionsCode)),
  onUpdateBLSConfigurationRegions: (blsName, regions, countriesAssignments) => dispatch(
    actions.updateBLSConfigurationRegions(blsName, regions, countriesAssignments)),
  onOpenModal                            : () => dispatch(App.actions.openModal(App.constants.UNSAVED_CHANGES_MODAL)),
  onCloseModal                           : () => dispatch(App.actions.closeModal()),
  onOpenAddRegionModal                   : () => dispatch(App.actions.openModal(constants.ADD_BLS_REGION_MODAL)),
  onOpenUpdateBLSConfigurationFailedModal: () => dispatch(App.actions.openModal(
    constants.UPDATE_BLS_CONFIGURATION_FAILED_MODAL)),
});


const ConnectedConfigureBLSRegions = connect(
  mapStateToProps,
  mapDispatchToProps,
)(ConfigureBLSRegions);


export default withStyles(styles)(ConnectedConfigureBLSRegions);
