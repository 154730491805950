import queryString from 'query-string';
import find from 'lodash/find';
import forEach from 'lodash/forEach';
import get from 'lodash/get';
import pick from 'lodash/pick';
import { eventChannel } from 'redux-saga';
import { all, call, delay, put, race, select, take, takeEvery, takeLatest, getContext } from 'redux-saga/effects';
import moment from 'moment';
import appInsights from 'helpers/appInsights';
import { ApiError, LocalError } from 'helpers/errorTypes';
import gtm from 'helpers/gtm';
import hotjar from 'helpers/hotjar';
import SessionStorage from 'libs/SessionStorage';
import AlertService from 'services/AlertService';
import SignalRService from 'services/SignalRService';
import ApiService from 'services/ApiService';
import { signOut } from '../Account/actions';
import * as actionTypes from './actionTypes';
import * as actions from './actions';
import * as constants from './constants';
import * as selectors from './selectors';
import messages from './messages';


const signalRError = new LocalError({ code: 'SignalRError' });

export function* dispatchError(error, moduleMessages = {}, alertDispatcher = null, isSilent = false) {
  const { status } = error;

  if (status === 401) {
    if (!isSilent) {
      yield put(signOut());
    }
    return;
  }

  const isApiError = error instanceof ApiError;
  const businessError = error.getBusinessError && error.getBusinessError();
  const errorAppInsightsProperties = {
    businessError,
    ...pick(error, ['status', 'options', 'url', 'validationErrors'],
    ),
  };

  let errorAppInsightsType = isApiError ? 'ApiError' : 'LocalError';
  if (businessError) {
    errorAppInsightsType = businessError.code;
  } else if (error.validationErrors) {
    errorAppInsightsType = 'ValidationErrors';
  }

  appInsights.trackException(new Error(errorAppInsightsType), errorAppInsightsProperties);

  if (isSilent) {
    return;
  }

  const alert = yield (alertDispatcher || call(AlertService.createAlertDispatcher));
  let message = messages.alerts.genericError;
  let messageValues = { email: constants.CONTACT_EMAIL };

  if (status === 500 || status === 503 || (isApiError && !businessError && !error.validationErrors)) {
    if (error.requestId) {
      message = messages.alerts.error5xxWithRequestId;
      messageValues.requestId = error.requestId;
    } else {
      message = messages.alerts.error5xx;
    }
    yield alert.error(message, messageValues);
    return;
  }

  if (businessError) {
    const { code, params, actions: errorActions } = businessError;
    // Error intl message path convention must be implement in every module
    const messagePath = ['errors', 'businessErrors', code];
    const moduleMessage = get(moduleMessages, messagePath) || get(messages, messagePath);
    if (moduleMessage) {
      message = moduleMessage;
      messageValues = {
        ...messageValues,
        ...params,
      };
    }
    yield alert.error(message, messageValues, errorActions);
  }
}


//----------------------------------------------------------------------------------------------------------------------

function* setCountryByCode({ payload }) {
  try {
    const { alpha2Code } = payload;
    const countries = yield select(selectors.countries);
    const country = find(countries, { alpha2Code });
    yield put(actions.setCountry(country));
  } catch (err) {
    // background operation
  }
}


function* setLocale({ payload }) {
  try {
    const { locale } = payload;
    if (locale === 'en-hk' && moment.locales().findIndex((l) => l === 'en-hk') < 0) {
      moment.defineLocale(locale, {
        parentLocale: 'en-gb',
      });
    }
    if (moment.locale() !== locale) moment.locale(locale);
    if (!process.env.BROWSER) {
      return;
    }
    yield getContext('cookies');
  } catch (err) {
    // background operation
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* fetchCountries() {
  try {
    const requestUrl = '/api/Localization/countries';
    const fetch = yield getContext('metaFetch');
    const countries = yield call(ApiService.originalRequest, requestUrl, null, fetch);
    yield put(actions.fetchCountriesSuccess(countries));
  } catch (err) {
    yield put(actions.fetchCountriesError(err));
    yield call(dispatchError, err, messages);
  }
}


function* fetchCountrySettings({ payload }) {
  try {
    const { countryId } = payload;
    const fetch = yield getContext('metaFetch');
    const requestUrl = `/api/Localization/country/${countryId}/defaultSettings`;
    const countrySettings = yield call(ApiService.originalRequest, requestUrl, null, fetch);
    yield put(actions.fetchCountrySettingsSuccess(countrySettings));
  } catch (err) {
    yield put(actions.fetchCountrySettingsError(err));
    yield call(dispatchError, err, messages);
  }
}


function* fetchLanguages() {
  try {
    const fetch = yield getContext('metaFetch');
    const requestUrl = '/api/Localization/languages';
    const languages = yield call(ApiService.originalRequest, requestUrl, null, fetch);
    yield put(actions.fetchLanguagesSuccess(languages));
  } catch (err) {
    yield put(actions.fetchLanguagesError(err));
    yield call(dispatchError, err, messages);
  }
}

// TRANSLATIONS --------------------------------------------------------------------------------------------------------

function importTranslationsFile(dirname, langCode = constants.DEFAULT_LOCALE) {
  return import(`../../${dirname}/translations/${langCode}.json`);
}


function* importTranslations(dirname, langCode) {
  const defaultTranslations = yield call(importTranslationsFile, dirname);
  if (langCode === constants.DEFAULT_LOCALE) {
    return defaultTranslations.default;
  }
  let translations = null;
  try {
    translations = yield call(importTranslationsFile, dirname, langCode);
  } catch (err) {
    // Background fallback
  }
  return Object.assign({}, defaultTranslations.default, translations && translations.default);
}


function* loadTranslations(langCode) {
  if (
    !constants.APP_LOCALES.includes(langCode)
    && !Object.values(constants.APP_LOCALE_LANGUAGES_MAP).includes(langCode)
  ) {
    throw new LocalError({ code: 'InvalidLanguage' });
  }
  const translationsArray = yield all(constants.TRANSLATIONS_LOCATIONS.map(
    (dirname) => call(importTranslations, dirname, langCode)),
  );
  const translations = translationsArray.reduce((result, current) => Object.assign(result, current), {});
  yield put(actions.setTranslations(translations));
}


function* fetchLocalizationResources() {
  try {
    const langCode = yield select(selectors.langCode);
    const fetch = yield getContext('metaFetch');
    const requestUrl = `/api/Localization/language/${langCode}/resources`;
    const [localizationResourcesArray] = yield all([
      call(ApiService.originalRequest, requestUrl, null, fetch),
      call(loadTranslations, langCode),
    ]);
    const localizationResources = {};
    forEach(localizationResourcesArray, (lr) => {
      localizationResources[lr.resourceKey] = lr;
    });
    yield put(actions.fetchLocalizationResourcesSuccess(localizationResources));
  } catch (err) {
    yield put(actions.fetchLocalizationResourcesError(err));
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* fetchSystemAlertsSettings() {
  try {
    const fetch = yield getContext('metaFetch');
    const requestUrl = '/api/Alerts/Settings';
    const response = yield call(ApiService.originalRequest, requestUrl, null, fetch);
    yield put(actions.fetchSystemAlertsSettingsSuccess(response));
  } catch (err) {
    yield put(actions.fetchSystemAlertsSettingsError(err));
  }
}


function* fetchSystemAlerts() {
  try {
    const fetch = yield getContext('metaFetch');
    const requestUrl = '/api/Alerts';
    const response = yield call(ApiService.originalRequest, requestUrl, null, fetch);
    yield put(actions.fetchSystemAlertsSuccess(response));
  } catch (err) {
    yield put(actions.fetchSystemAlertsError(err));
  }
}


// SIGNALR -------------------------------------------------------------------------------------------------------------


function* signalRExternalListener(hubChannel) {
  while (true) {
    const action = yield take(hubChannel);
    // console.info('signalRExternalListener', action);
    yield put(action);
  }
}


function signalRCreateHubChannel(connection) {
  return eventChannel((emitter) => {
    const startConnection = () => {
      connection.start()
        .then(() => {
          emitter(actions.setSignalRConnected());
        })
        .catch(() => {
          setTimeout(() => startConnection(), 5000); // try to reconnect after 5 seconds
        });
    };
    connection.on(constants.SIGNALR_NOTIFICATION_RECEIVE_MSG, (data) => {
      emitter({
        type   : `${constants.SIGNALR_MODULE_ID}/${data.type}`,
        payload: data.payload, // eventually payload will be in event data
      });
    });

    connection.onclose((error) => {
      if (error) {
        console.error(error);
        emitter(actions.setSignalRError());
      } else {
        emitter(actions.setSignalRDisconnected());
      }
    });
    // eslint-disable-next-line no-unused-vars
    connection.onreconnected((connectionId) => {
      emitter(actions.signalRReconnected());
    });

    startConnection();
    return () => {
      connection.stop();
      // console.info('Connection stopped');
      emitter(actions.setSignalRDisconnected());
    };
  });
}


function* signalRCreateHub() {
  try {
    const connection = yield call(SignalRService.getHubConnection, constants.SIGNALR_HUB_NAME);
    const hubChannel = yield call(signalRCreateHubChannel, connection);
    while (true) {
      const { cancel, error } = yield race({
        task  : call(signalRExternalListener, hubChannel),
        cancel: take(actionTypes.SIGNALR_SET_DISCONNECTED),
        error : take(actionTypes.SIGNALR_ERROR),
      });
      if (cancel) {
        SignalRService.removeHubConnection();
        hubChannel.close();
      }
      if (error) {
        if (connection.connectionState === 'Disconnected') {
          yield put(actions.setSignalRDisconnected());
          SignalRService.removeHubConnection();
        }
        yield call(dispatchError, signalRError, messages);
      }
    }

  } catch (err) {
    console.error(err);
    yield call(dispatchError, signalRError, messages);
  }
}


function* signalRSend({ payload }) {
  try {
    const { sendMessageName } = payload;
    let { data } = payload;
    if (data === null || data === undefined) {
      data = [];
    }
    const connection = yield call(SignalRService.getHubConnection, constants.SIGNALR_HUB_NAME);
    yield connection.send(sendMessageName, ...data);
  } catch (err) {
    console.error(err);
    yield call(dispatchError, signalRError, messages);
  }
}


function* signalRInvoke({ payload }) {
  const { invokeMessageName, sagaActions } = payload;
  try {
    let { data } = payload;
    if (data === null || data === undefined) {
      data = [];
    }
    yield put(sagaActions.init(data));
    const connection = yield call(SignalRService.getHubConnection, constants.SIGNALR_HUB_NAME);
    const response = yield connection.invoke(invokeMessageName, ...data);
    yield put(sagaActions.success(response));
  } catch (err) {
    yield put(sagaActions.error(err));
    console.error(err);
    yield call(dispatchError, signalRError, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------


function connectWebsocket(url) {
  const websocket = new WebSocket(url);
  return new Promise((resolve) => {
    websocket.onopen = () => {
      resolve(websocket);
    };
  });
}


function createWebsocketChannel(websocket) {

  return eventChannel((emitter) => {

    websocket.onmessage = (evt) => {
      // const msg = JSON.parse(evt.data);
      const { data } = evt;
      // console.log('received:', evt);
      const actionType = 'TEST_ACTION'; // eventually actionType will be in event data
      emitter({
        type   : `ws/${actionType}`,
        payload: data, // eventually payload will be in event data
      });
    };

    websocket.onerror = () => {
      // console.error('WebSocket error:', evt);
      emitter(actions.websocketStoreState(websocket.readyState));
    };

    return () => {
      // console.log('socket close');
      websocket.close();
      emitter(actions.websocketStoreState(websocket.readyState));
    };
  });

}


function* internalListener(socket) {
  while (true) {
    const action = yield take(actionTypes.WEBSOCKET_SEND);
    socket.send(action.payload);
  }
}


function* externalListener(socketChannel) {
  while (true) {
    const action = yield take(socketChannel);
    yield put(action);
  }
}


function* createWebsocket() {
  try {
    const websocketState = yield select(selectors.websocketStateSelector());
    if (websocketState <= 1) {
      return;
    }
    const wsApiUrl = yield getContext('wsApiUrl');
    const { timeout, websocket } = yield race({
      websocket: call(connectWebsocket, wsApiUrl),
      timeout  : delay(2000),
    });
    if (timeout) {
      throw new LocalError({ code: 'WebsocketError' });
    }
    const websocketChannel = yield call(createWebsocketChannel, websocket);
    yield put(actions.websocketStoreState(websocket.readyState));
    while (true) {
      const { cancel } = yield race({
        task  : all([call(externalListener, websocketChannel), call(internalListener, websocket)]),
        cancel: take(actionTypes.WEBSOCKET_STOP),
      });
      if (cancel) {
        websocketChannel.close();
      }
      yield put(actions.websocketStoreState(websocket.readyState));
    }
  } catch (err) {
    yield call(dispatchError, err, messages);
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* loadGTM() {
  try {
    const apps = yield getContext('apps');
    yield call(gtm.loadGTM, apps.gtm);
    yield put(actions.loadGTMSuccess());
  } catch (err) {
    yield put(actions.loadGTMFailed(err));
  }
}


function* loadHotjar() {
  try {
    const apps = yield getContext('apps');
    yield call(hotjar.load, apps.hotjar, 6);
    yield put(actions.loadHotjarSuccess());
  } catch (err) {
    yield put(actions.loadHotjarFailed(err));
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* pushVirtualPageview({ payload }) {
  if (!process.env.BROWSER || __DEV__) {
    return;
  }
  try {
    const { pathname, search, hash } = payload;
    yield call(gtm.vpPush, pathname, search, hash);
  } catch (err) {
    // We don't need any action because we're doing it in background
  }
}

//----------------------------------------------------------------------------------------------------------------------

function* cacheFeatureToggles() {
  if (!process.env.BROWSER) {
    return;
  }
  const featureToggles = yield select(selectors.featureToggles);
  SessionStorage.setItem('featureToggles', JSON.stringify(featureToggles));
}


function* restoreFeatureToggles() {
  const featureTogglesJson = SessionStorage.getItem('featureToggles');
  try {
    let featureToggles = JSON.parse(featureTogglesJson) || [];
    featureToggles = featureToggles.filter((name) => constants.FEATURE_TOGGLES[name]);
    yield all(featureToggles.map((name) => put(actions.setFeatureToggle({ name, value: true }))));
  } catch (err) {
    yield call(dispatchError, err, messages);
  }

}


function* updateFeatureToggles() {
  const search = get(window, 'location.search');
  const params = queryString.parse(search);
  const paramsEntries = Object.entries(params);
  const paramEntry = paramsEntries.find(([name]) => constants.FEATURE_TOGGLES[name]);
  if (!paramEntry) {
    return;
  }
  const [name, strValue] = paramEntry;
  if (strValue === 'on') {
    yield put(actions.setFeatureToggle({ name, value: true }));
  } else if (strValue === 'off') {
    yield put(actions.setFeatureToggle({ name, value: false }));
  }
}


function* handleFeatureToggles() {
  if (!process.env.BROWSER) {
    return;
  }
  yield call(restoreFeatureToggles);
  yield call(updateFeatureToggles);
}


function* fetchDevices() {
  try {
    const requestUrl = '/api/Device';
    const devices = yield call(ApiService.originalRequest, requestUrl);
    yield put(actions.fetchDevicesSuccess(devices));
  } catch (err) {
    yield put(actions.fetchDevicesError(err));
    yield call(dispatchError, err, messages);
  }
}


//----------------------------------------------------------------------------------------------------------------------
/* eslint-disable func-names */
function* sagas() {
  yield takeLatest(actionTypes.SET_COUNTRY_BY_CODE, setCountryByCode);
  yield takeLatest(actionTypes.SET_LOCALE, setLocale);
  yield takeLatest(actionTypes.FETCH_COUNTRIES, fetchCountries);
  yield takeLatest(actionTypes.FETCH_COUNTRY_SETTINGS, fetchCountrySettings);
  yield takeLatest(actionTypes.FETCH_LANGUAGES, fetchLanguages);
  yield takeLatest(actionTypes.FETCH_LOCALIZATION_RESOURCES, fetchLocalizationResources);
  yield takeEvery(actionTypes.SIGNALR_CREATE_HUB, signalRCreateHub);
  yield takeEvery(actionTypes.SIGNALR_SEND, signalRSend);
  yield takeLatest(actionTypes.SIGNALR_INVOKE, signalRInvoke);
  yield takeEvery(actionTypes.WEBSOCKET_START, createWebsocket);
  yield takeLatest(actionTypes.LOAD_GTM, loadGTM);
  yield takeLatest(actionTypes.LOAD_HOTJAR, loadHotjar);
  yield takeLatest(actionTypes.PUSH_VIRTUAL_PAGEVIEW, pushVirtualPageview);
  yield takeLatest(actionTypes.SET_CLIENT_IS_INITIALIZED, handleFeatureToggles);
  yield takeEvery(actionTypes.SET_FEATURE_TOGGLE, cacheFeatureToggles);
  yield takeLatest(actionTypes.FETCH_SYSTEM_ALERTS_SETTINGS, fetchSystemAlertsSettings);
  yield takeLatest(actionTypes.FETCH_SYSTEM_ALERTS, fetchSystemAlerts);
  yield takeLatest(actionTypes.FETCH_DEVICES, fetchDevices);
}

export default [
  sagas,
];
