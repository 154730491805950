import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import isEqual from 'lodash/isEqual';
import isNumber from 'lodash/isNumber';
import toNumber from 'lodash/toNumber';
import get from 'lodash/get';
import map from 'lodash/map';
import { FormattedMessage } from 'react-intl';
import { filter, endsWith, pick, omit } from 'lodash';
import { flatten } from 'helpers/transformers';
import Validator from 'libs/Validator';
import Form from 'components/Form';
import SubmitOrDiscardButtonsContainer from 'components/Form/SubmitOrDiscardButtonsContainer';
import Select from 'components/Form/Select';
import FormContainerAbstract from 'components/FormContainerAbstract';
import App from 'modules/App';
import * as actions from '../../../../actions';
import * as selectors from '../../../../selectors';
import * as constants from '../../../../constants';
import messages from '../../../../messages';
import GlucoseLevel from './GlucoseLevel';
import styles from './GlucoseRanges.pcss';
import validatorRules from './validatorRules.json';
import { toConverted } from './glucoseRangesConverter';
import ValidatorRulesExtension from './ValidatorRulesExtension';


class GlucoseRangesForm extends FormContainerAbstract {

  static propTypes = {
    ...FormContainerAbstract.propTypes,
    // Explicit props
    isFetchInProgress         : PropTypes.bool.isRequired,
    countryCode               : PropTypes.string.isRequired,
    isConfiguredCountryActive : PropTypes.bool.isRequired,
    // Explicit actions
    onHasUnsavedChangesChanged: PropTypes.func,
    // Implicit props
    isUpdateInProgress        : PropTypes.bool.isRequired,
    // Implicit actions
    onClose                   : PropTypes.func,
  };


  constructor(props) {
    super(props);
    this.state = {
      formInitialState     : JSON.parse(JSON.stringify(this.props.glucoseRanges)),
      hasUnsavedChanges    : false,
      isResetFormInProgress: false,
      shouldReset          : false,
      metricUnit           : this.defaultUnitOption,
    };

    this.validatorRules = this.props.isConfiguredCountryActive
      ? { ...validatorRules.baseValidationRules, ...validatorRules.defaultRangeForActiveCountry }
      : { ...validatorRules.baseValidationRules, ...validatorRules.defaultRangeForInactiveCountry };
    this.props.onSetFormValues(JSON.parse(JSON.stringify(this.props.glucoseRanges)));
  }


  componentDidUpdate(prevProps, prevState) {
    if (this.state.formInitialState) {
      this.setFormStatus();
    }
    if (prevState.metricUnit && this.state.metricUnit !== prevState.metricUnit && !this.state.isResetFormInProgress) {
      this.props.onSetFormValues(toConverted(this.props.formValues.values, this.state.metricUnit));
    }
    if (this.state.isResetFormInProgress) {
      this.onResetFinished();
    }
    if (prevProps.isUpdateInProgress && !this.props.isUpdateInProgress) {
      this.onSetShouldReset();
    }
    if (this.state.shouldReset && !this.props.isFetchInProgress) {
      this.onAfterUpdate();
    }
  }


  onSetShouldReset() {
    this.setState({ shouldReset: true });
  }

  onAfterUpdate() {
    this.setState({
      formInitialState     : JSON.parse(JSON.stringify(this.props.glucoseRanges)),
      hasUnsavedChanges    : false,
      isResetFormInProgress: true,
      shouldReset          : false,
      metricUnit           : this.defaultUnitOption,
    });
    this.props.onSetFormValues(JSON.parse(JSON.stringify(this.props.glucoseRanges)));
  }

  onSubmit() {
    this.props.onFormProcessing();
    const { errors } = this.onValidate(this.validatorRules);
    this.props.onFormErrors(errors);
    if (!errors) {
      if (this.props.onSubmit) {
        const values = get(this.props.formValues, 'values', {});
        const flattenValues = flatten(values);
        const propsToDisplay = filter(Object.keys(flattenValues), (value) => endsWith(value, '-toDisplay'));
        let valuesToSend = omit(values, propsToDisplay);
        if (this.state.metricUnit !== this.defaultUnitOption) {
          valuesToSend = toConverted(valuesToSend, this.defaultUnitOption);
        }
        this.props.onSubmit(valuesToSend);
      }
    }
  }


  onValidate(rules) {
    const values = get(this.props.formValues, 'values', {});
    const flattenValues = flatten(values);
    const propsToDisplay = filter(Object.keys(flattenValues), (value) => endsWith(value, '-toDisplay'));
    const valuesToValidate = pick(values, propsToDisplay);
    const rulesExtension = ValidatorRulesExtension.addDefaults(rules, valuesToValidate);
    const { errors } = Validator.run(flatten(valuesToValidate), rulesExtension);
    return {
      validatedValues: valuesToValidate,
      errors,
    };
  }


  onInitialize() {
    this.setState({
      formInitialState     : JSON.parse(JSON.stringify(this.props.glucoseRanges)),
      hasUnsavedChanges    : false,
      isResetFormInProgress: false,
      shouldReset          : false,
      metricUnit           : this.defaultUnitOption,
    });
    this.props.onSetFormValues(JSON.parse(JSON.stringify(this.props.glucoseRanges)));
  }


  onReset(event) {
    event.preventDefault();
    this.props.onSetFormValues(JSON.parse(JSON.stringify(this.state.formInitialState)));
    this.setState({ metricUnit: this.defaultUnitOption, isResetFormInProgress: true });
    this.props.onFormErrors(null);
  }


  onResetFinished() {
    this.setState({ isResetFormInProgress: false });
  }


  setFormStatus() {
    const hasUnsavedChanges = !isEqual(this.state.formInitialState,
      this.state.metricUnit === this.defaultUnitOption
        ? this.props.formValues.values
        : toConverted(this.props.formValues.values, this.defaultUnitOption));
    if (this.state.hasUnsavedChanges !== hasUnsavedChanges) {
      this.setState({ hasUnsavedChanges });
      this.props.onHasUnsavedChangesChanged(hasUnsavedChanges);
    }
  }


  onSetValue(input) {
    const { id, value } = input;
    this.props.onSetFormValue({ id, value: isNumber(value) ? toNumber(value) : null });
  }


  onSelectUnit(metricUnit) {
    this.setState({ metricUnit });
    this.props.onFormErrors(null);
  }


  get unitOptions() {
    return ['MG_DL', 'MMOL_L'];
  }

  get unitSymbol() {
    return constants.UNITS_SYMBOLS[this.state.metricUnit];
  }

  get defaultUnitOption() {
    return this.unitOptions[0];
  }

  renderSelectUnit() {
    return (
      <>
        <div
          className="row no-gutters align-items-center"
        >
          <h5 className="text--h5 col-auto mb-0 mt-0 mr-4">
            <FormattedMessage {...messages.headers.selectUnit} />
          </h5>
          <div className="col-3 pr-5">
            <Select
              optionsFrom={map(this.unitOptions, (type) => ({
                value: type,
                label: constants.UNITS_SYMBOLS[type],
              }))}
              valueKey="value"
              labelKey="label"
              value={this.state.metricUnit}
              onChange={(input) => this.onSelectUnit(input.value)}
            />
          </div>
        </div>
        {this.state.metricUnit === 'MMOL_L'
          ? <p className="mt-3"><FormattedMessage {...messages.infos.moleUnitSelected} /></p>
          : null}
      </>
    );
  }


  renderTargetRanges() {
    const { formValues } = this.props;
    const { unitSymbol } = this;
    return (
      <div>
        <GlucoseLevel
          type="default"
          unitSymbol={unitSymbol}
          formValues={formValues}
          onSetValue={(evt) => this.onSetValue(evt)}
        />
        <GlucoseLevel
          type="type1"
          unitSymbol={unitSymbol}
          formValues={formValues}
          onSetValue={(evt) => this.onSetValue(evt)}
        />
        <GlucoseLevel
          type="type2"
          unitSymbol={unitSymbol}
          formValues={formValues}
          onSetValue={(evt) => this.onSetValue(evt)}
        />
        <GlucoseLevel
          type="prediabetes"
          unitSymbol={unitSymbol}
          formValues={formValues}
          onSetValue={(evt) => this.onSetValue(evt)}
        />
        <GlucoseLevel
          type="gestational"
          unitSymbol={unitSymbol}
          formValues={formValues}
          onSetValue={(evt) => this.onSetValue(evt)}
        />
      </div>
    );
  }

  render() {
    return (
      <Form
        onSubmit={() => this.onSubmit()}
        onReset={(event) => this.onReset(event)}
        className={styles.glucoseRanges}
      >
        {this.renderSelectUnit()}
        {this.renderTargetRanges()}
        <SubmitOrDiscardButtonsContainer
          isSaveEnable={this.state.hasUnsavedChanges}
          isDiscardEnable={this.state.hasUnsavedChanges}
          isInProgress={this.props.isUpdateInProgress || this.props.isFetchInProgress}
          additionalInfoMessage={
            this.props.isConfiguredCountryActive
              ? <p><FormattedMessage {...messages.infos.saveOnActiveCountry} /></p>
              : null}
        />
      </Form>
    );
  }

}


const mapStateToProps = (state) => ({
  formValues               : App.selectors.formSelector(constants.CONFIGURE_COUNTRY__GLUCOSE_RANGES_TAB_FORM)(state),
  glucoseRanges            : selectors.glucoseRanges(state),
  isConfiguredCountryActive: selectors.isConfiguredCountryActive(state),
  isUpdateInProgress       : selectors.isUpdateCountryConfigurationGlucoseRangesInProgress(state),

});


const mapDispatchToProps = (dispatch) => {
  const formName = constants.CONFIGURE_COUNTRY__GLUCOSE_RANGES_TAB_FORM;
  return {
    onSubmit        : (values) => dispatch(actions.updateCountryConfigurationGlucoseRanges(values)),
    onSetFormValue  : (input) => dispatch(App.actions.setFormValue({ formName, input })),
    onSetFormValues : (values) => dispatch(App.actions.setFormValues({ formName, values })),
    onFormErrors    : (errors) => dispatch(App.actions.setFormErrors({ formName, errors })),
    onFormProcessing: () => dispatch(App.actions.startFormProcessing(formName)),
    onClearForm     : () => dispatch(App.actions.clearForm(formName)),
  };
};


const ConnectedGlucoseRangesForm = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GlucoseRangesForm);


export default ConnectedGlucoseRangesForm;
