import React from 'react';
import { FormattedMessage } from 'react-intl';
import withStyles from 'isomorphic-style-loader/withStyles';
import Statement from 'components/Statement';
import messages from '../../messages';
import styles from './WelcomeStatement.pcss';


class WelcomeStatement extends React.PureComponent {


  render() {
    return (
      <Statement
        headerMessage={messages.headers.welcomeToBackOffice}
        imgSrc="/bo/assets/svg/illustration/welcome.svg"
        isOnTop
        boxClassName={styles.wrapper__box}
      >
        <p>
          <FormattedMessage
            {...messages.infos.welcomeToBackOffice}
            values={{
              b: (chunk) => <b>{chunk}</b>,
            }}
          />
        </p>
      </Statement>
    );
  }

}

export default withStyles(styles)(WelcomeStatement);
